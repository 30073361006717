<template>
  <GeneralWidgetLayoutSlot
    class="!tw-min-h-[340px]"
    :title="`Trade Network (${
      allUserConnection?.length ? allUserConnection?.length : '0'
    })`"
    buttonLabel="Manage Preferred Trades"
    :hideFooter="!allUserConnection.length"
    @on-action-click="onOpenTradeNetwork"
  >
    <template #header-rigth-content>
      <ToolTip
        description="The Trade Network connects property owners with qualified trade professionals for seamless project management and high-quality service."
      />
    </template>
    <template #content>
      <main class="tw-w-full tw-h-full tw-flex tw-justify-between tw-flex-col">
        <template v-if="allUserConnection.length">
          <div
            class="tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 tw-box-border"
            :class="{
              'tw-border-b-solid-custom': index === 0,
            }"
            v-for="(item, index) in allUserConnection.slice(0, 2)"
            :key="index"
          >
            <div class="tw-flex tw-items-center tw-gap-4">
              <ProfileThumbnail
                v-if="item?.receiverWorkstation?.publicURL"
                :src="item?.receiverWorkstation?.publicURL"
                :alt="item.receiverWorkstation?.name"
                :showVerificationIcon="true"
                :isVerified="true"
              />
              <WorkStationProfileLogo
                v-else
                :WSName="item.receiverWorkstation?.name"
              />
              <div class="tw-flex tw-flex-col tw-items-start">
                <Text variant="p">{{ item.receiverWorkstation?.name }}</Text>
                <div class="tw-flex tw-gap-2 md:tw-flex-col">
                  <div
                    class="tw-flex tw-items-center tw-gap-2"
                    v-for="skill in item.receiverWorkstation?.userSkill?.slice(
                      0,
                      2
                    )"
                    :key="skill?.id"
                  >
                    <SpecialismChip
                      :label="skill?.subCategory?.category?.tpLabel"
                      :iconName="skill?.subCategory?.category?.iconName"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
              <!-- <ChipState
                :hasCustomLabel="true"
                bgColor="#FFF"
                :hasShadow="true"
                :isDense="true"
              >
                <template #prefix>
                  <CheckGreen
                    class="tw-max-h-4 tw-max-w-4 tw-min-h-4 tw-min-w-4"
                  />
                </template>
                 <template #custom-label>
                  <Text variant="span" textColor="#1FB27A">
                    2
                    <Text variant="span" textWeight="400"
                      >Collaborative Projects</Text
                    >
                  </Text>
                </template> 
              </ChipState>-->
              <TradePassportActionChip @click="onClickManageTradePassport" />
            </div>
          </div>
        </template>
        <section
          v-else
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
        >
          <img
            :src="require(`@/assets/images/trade-network-empty-state.png`)"
          />
          <Text variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
            >Your Trade Network will appear here</Text
          >
          <Button
            v-if="getUserWorkStationFieldAccess"
            variant="accent"
            label="Add Trade"
            class="!tw-w-auto"
            @click="onOpenTradeNetwork"
          />
        </section>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
</template>
<script setup>
import { reactive, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";

import Button from "@/core/components/ui/general/Button.vue";
import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import SpecialismChip from "./../Chips/SpecialismChip.vue";
import TradePassportActionChip from "./../Chips/TradePassportActionChip.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { TRADE_NETWORK_ROUTE } from "@/modules/trade-network/routes";
import $axios from "@/core/utils/axios-api-config";
import { useStore } from "vuex";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { getImageApiUrl } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import ToolTip from "@/core/components/common/ToolTip.vue";
const router = useRouter();
const store = useStore();

const props = defineProps({
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});
const onOpenTradeNetwork = () => {
  router.push({ name: TRADE_NETWORK_ROUTE });
};
const allUserConnection = ref([]);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const contractorBasedOnCategories = computed(() => {
  const contractorList =
    store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];
  allUserConnection.value = JSON.parse(JSON.stringify(contractorList))
    .map((contractor) => contractor?.userNetworkRequest)
    .flat();
  return contractorList;
});

const onClickManageTradePassport = () => {
  router.push({ name: TRADE_PASSPOSRT_ROUTE });
};

onMounted(async () => {
  await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
    activeUserWorkstationId: activeUserWorkstation.value?.id,
  });

  allUserConnection.value = [];

  allUserConnection.value = JSON.parse(
    JSON.stringify(contractorBasedOnCategories.value)
  );
  for (const contractor of allUserConnection.value) {
    if (contractor?.receiverWorkstation?.profileImage !== null) {
      const imageUrl = getImageApiUrl(
        contractor?.receiverWorkstation?.profileImage,
        true
      );
      const encoded = await $axios.get(imageUrl);
      contractor.receiverWorkstation.publicURL = encoded?.publicUrl;
    }
  }
});
</script>
<style lang="scss" scoped></style>
