<template>
  <div
    class="messagechat__wrapper"
    :class="{ messagechat__active: isActiveKrystalChat }"
  >
    <v-card class="messagechat__inner" variant="flat">
      <template v-slot:text>
        <div class="messagechat__top" @click="toggleKrystalActiveChat">
          <div class="messagechat__left">
            <div class="messagechat__img">
              <!-- <UserProfileLogo /> -->
              <img :src="require('@/assets/icons/krystal.svg')" alt="" />
            </div>

            <div class="messagechat__heading">
              <div class="messagechat__heading__title">
                <h5>Krystal</h5>
              </div>
            </div>
          </div>
          <div class="messagechat__right">
            <v-icon></v-icon>

            <v-icon class="close_icon" @click="onClose">
              <img :src="require('@/assets/icons/cancel.svg')" alt="" />
            </v-icon>
          </div>
        </div>
        <div class="messagechat__section">
          <div class="messagechat__bottom" ref="chatContainerRef">
            <div class="messagechat__bottom__chats">
              <div v-if="!currentConversationData.length">
                <div class="messagechat__bottom__chats__list">
                  <div class="krystal__msg">
                    <div class="krystal__msg__header">
                      <div class="messagechat__img">
                        <!-- <img
                          :src="require('../../assets/icons/kry')"
                          alt=""
                        /> -->
                        <img src="../../assets/icons/krystal.svg" alt="" />
                      </div>

                      <div class="messagechat__heading">
                        <h6>Krystal</h6>
                      </div>
                    </div>
                    <div class="messagecaht__text">
                      <p>Hi there! How can I assist you today?</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- // chat  -->
              <div
                v-for="(item, index) in currentConversationData"
                :key="item?.id"
                class="question_answer__chat"
              >
                <div
                  class="message__date"
                  v-if="shouldDisplayDate(item?.dateCreated, index)"
                >
                  <p>{{ getTheDay(item?.dateCreated) }}</p>
                </div>

                <div class="messagechat__bottom__chats__list">
                  <div class="user__msg">
                    <div class="user__msg__header">
                      <div class="messagechat__img">
                        <img
                          :src="
                            profileUrlForWorkStation === ''
                              ? require('@/assets/images/user-placeholder.png')
                              : profileUrlForWorkStation
                          "
                          alt=""
                        />
                      </div>
                      <div class="online"></div>

                      <div class="messagechat__heading">
                        <h6>{{ activeUserWorkstation?.name }}</h6>
                      </div>
                    </div>
                    <div class="messagecaht__text">
                      <p>{{ item?.text }}</p>
                    </div>
                  </div>
                  <div class="krystal__msg" v-if="item?.answers?.length">
                    <div class="krystal__msg__header">
                      <div class="messagechat__img">
                        <!-- <img
                          :src="require('../../assets/icons/kry')"
                          alt=""
                        /> -->
                        <img src="../../assets/icons/krystal.svg" alt="" />
                      </div>

                      <div class="messagechat__heading">
                        <h6>Krystal</h6>
                      </div>
                    </div>
                    <div class="messagecaht__text">
                      <p
                        v-html="item?.answers[item?.answers?.length - 1]?.text"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
              <p class="krystal_typing" v-if="loadingKrystalResponce">
                Krystal is Typing <span style="--delay: 0.1s"> </span>
                <span style="--delay: 0.2s"> </span>
                <span style="--delay: 0.3s"> </span>
                <span style="--delay: 0.4s"> </span>
              </p>
            </div>
          </div>

          <div class="messagechat__input">
            <div class="messagechat__edit">
              <v-textarea
                @keyup.enter.exact.stop="generateResponse"
                bg-color="white"
                base-color="white"
                density="compact"
                variant="solo"
                flat
                placeholder="Type a message"
                no-resize
                rows="1"
                auto-grow
                :autofocus="true"
                v-model="userPrompt"
              >
                <template v-slot:append-inner>
                  <v-icon
                    class="tw-cursor-pointer"
                    icon="mdi-chevron-right-circle"
                    @click.self="generateResponse"
                  />
                </template>
              </v-textarea>
            </div>
          </div>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import { CHATMESSAGE } from "@/store/modules/chat-message";
import { WORKSTATION } from "@/store/modules/workstation";
import { onBeforeMount, onMounted, ref, nextTick, computed, watch } from "vue";
import { useStore } from "vuex";
import { getImageStringToImageURL } from "@/core/utils/common";

const store = useStore();
const userPrompt = ref("");
const isActiveKrystalChat = ref(true);
const currentConversationData: any = computed(
  () => store.getters[`${CHATMESSAGE}/getKrystalConversation`]
);
const profileUrlForWorkStation = ref("");
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const loadingKrystalResponce = ref(false);
const chatContainerRef = ref();
const emit = defineEmits(["closeMessageChatBox"]);
const generateResponse = async () => {
  if (userPrompt.value) {
    const payload = {
      prompt: userPrompt.value.replaceAll("\n", " "),
    };
    store.commit(`${CHATMESSAGE}/pushKrystalConversation`, {
      text: payload?.prompt,
      id: "gfg",
      dateCreated: new Date(),
      answers: [],
    });
    userPrompt.value = "";
    loadingKrystalResponce.value = true;
    await nextTick(() => {
      chatContainerRef.value.scrollTo({
        top: chatContainerRef.value.scrollHeight,
        behavior: "smooth",
      });
    });

    const response = await store.dispatch(
      `${CHATMESSAGE}/getKrystalChatResponse`,
      payload
    );
    loadingKrystalResponce.value = false;
    await nextTick(() => {
      chatContainerRef.value.scrollTop = chatContainerRef.value.scrollHeight;
    });
  }
};

const getConversation = async () => {
  await store.dispatch(`${CHATMESSAGE}/getKrystalConversation`);
  await nextTick(() => {
    chatContainerRef.value.scrollTo({
      top: chatContainerRef.value.scrollHeight,
      behavior: "smooth",
    });
  });
};

const onClose = () => {
  emit("closeMessageChatBox");
};

const toggleKrystalActiveChat = () => {
  isActiveKrystalChat.value = !isActiveKrystalChat.value;
};

onBeforeMount(async () => {
  // getConversation();

  profileUrlForWorkStation.value = await getImageStringToImageURL(
    activeUserWorkstation.value?.profileImage
  );
});

onMounted(() => {
  chatContainerRef.value.scrollTo({
    top: chatContainerRef.value.scrollHeight,
    behavior: "smooth",
  });
});

const shouldDisplayDate = (dateCreated: any, index: any) => {
  if (index === 0) {
    return true;
  } else {
    const previousMessage = currentConversationData.value[index - 1];
    if (previousMessage) {
      const previousDay = new Date(previousMessage?.dateCreated).getDate();
      const currentDay = new Date(dateCreated).getDate();
      if (currentDay !== previousDay) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const getTheDay = (timestamp: any) => {
  const currentDate = new Date() as any;
  const messageDate = new Date(timestamp) as any;

  currentDate.setUTCHours(0, 0, 0, 0);
  messageDate.setUTCHours(0, 0, 0, 0);

  const differenceInTime = currentDate.getTime() - messageDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 0) {
    return "Today";
  } else if (differenceInDays === 1) {
    return "Yesterday";
  } else {
    const options = { month: "long", day: "numeric" };
    return messageDate.toLocaleDateString("en-US", options);
  }
};

watch(activeUserWorkstation, (newVal) => {
  onClose();
});
</script>

<style lang="scss" scoped>
.messagechat__wrapper {
  width: 400px;
  height: 56.5px;
  max-height: 100%;
  transition: 0.5s all ease;
  @include respond(s720) {
    width: 100%;
    max-width: 100%;
  }
  .messagechat__inner {
    border-radius: 8px;
    padding: 10px 16px 0 12px;
    position: relative;
    height: 100%;
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.4);
    transition: 0.5s all ease;
    @include respond(xlg) {
      padding: 12px 16px 0;
    }
    :deep(.v-card-text) {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .header {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($blueDark, 0.18);
        .left__block {
          @include fluidFont(14, 14, 1.3);
          font-weight: 700;
          color: rgba($blueDark, 1);
          letter-spacing: 0.25px;
          display: flex;
          gap: 4px;
          align-items: center;
        }
        .close_icon {
          width: 20px;
          height: 20px;
          border: 1px solid #0c0f4a;
          border-radius: 100%;
        }
      }

      .messagechat__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        border-bottom: 1px solid rgba($blueDark, 0.18);
        cursor: pointer;
        height: 47px;
        flex: 0 0 47px;
        gap: 20px;
        position: relative;
        padding-top: 6px;

        &::before {
          content: "";
          position: absolute;
          left: -12px;
          top: -10px;
          width: calc(100% + 16px + 12px);
          height: calc(100% + 10px);
          background-color: #f8f8f8;
          pointer-events: none;

          @include respond(xlg) {
            left: -16px;
            top: -12px;
            width: calc(100% + 32px);
            height: calc(100% + 12px);
          }
        }

        // @media screen and (max-width: 1279px) {
        //   // padding: 0 0 10px;
        //   padding: 0;
        // }

        .messagechat__left {
          display: flex;
          gap: 8px;
          align-items: center;
          position: relative;

          .messagechat__img {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            img {
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;
              filter: invert(43%) sepia(33%) saturate(1138%) hue-rotate(187deg)
                brightness(89%) contrast(89%);
            }
            .online {
              width: 10px;
              height: 10px;
              position: absolute;
              right: 0;
              top: -3px;
              border-radius: 100%;
              background-color: #3bc740;
              border: 1px solid rgba($white, 1);
            }
          }

          .msg__notification {
            width: 8px;
            height: 8px;
            background-color: rgba($success, 1);
            border: 1px solid rgba($white, 1);
            border-radius: 100%;
            position: absolute;
            left: 18px;
            top: 0;
          }

          .messagechat__heading {
            text-align: left;
            .messagechat__heading__title {
              display: inline-flex;
              gap: 10px;
              align-items: center;
              h5 {
                @include fluidFont(16, 16, 1);
                font-weight: 700;
                letter-spacing: 0.25px;
                color: rgba($blueDark, 1);
              }
              .v-icon {
                @include fluidFont(16, 16, 1.2);
                color: rgba($blueDark, 1);
              }
            }

            p {
              @include fluidFont(14, 14, 1);
              font-weight: 400;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 0.6);
              margin-top: 4px;
            }
            .group_names {
              margin-top: 3px;
              display: flex;
              gap: 4px;
              align-items: center;
              span {
                @include fluidFont(12, 12, 1.3);
                font-weight: 500;
                letter-spacing: 0.25px;
                color: rgba($PrimaryPurple, 1);
                white-space: nowrap;
                display: inline-block;
              }
            }
          }
        }

        .messagechat__right {
          .chat_image_display {
            padding: 0;
            margin-right: 20px;
            opacity: 1;
            .v-ripple__container {
              display: none;
            }
          }
          .close_icon {
            width: 14px;
            height: 14px;
            border-radius: 100%;

            img {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }
      }

      .messagechat__section {
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        flex: auto;

        .messagechat__bottom {
          height: auto;
          flex: auto;
          overflow: auto;
          display: flex;
          flex-direction: column-reverse;
          justify-content: end;

          .messagechat__bottom__chats {
            margin: 0 0 10px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .message__date {
              margin: 10px 0;
              position: relative;
              p {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                border-radius: 11px;
                background-color: rgba($blueDark, 0.04);
                display: inline-block;
                padding: 4px 12px;
              }
            }

            .messagechat__bottom__chats__list {
              display: flex;
              gap: 10px;
              align-items: center;
              flex-direction: column;
              .krystal__msg {
                width: 100%;
                max-width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: flex-start;
                .krystal__msg__header {
                  display: flex;
                  align-items: center;
                  gap: 12px;

                  .messagechat__img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;

                    img {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      object-fit: cover;
                      filter: invert(43%) sepia(33%) saturate(1138%)
                        hue-rotate(187deg) brightness(89%) contrast(89%);
                    }
                  }
                  .online {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 0;
                    top: -3px;
                    border-radius: 100%;
                    background-color: #3bc740;
                    border: 1px solid rgba($white, 1);
                  }
                  .messagechat__heading {
                    h6 {
                      @include fluidFont(14, 12, 1.5);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: $blueDark;
                      max-width: 170px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }
                .messagecaht__text {
                  text-align: left;
                  padding: 0 36px;
                  margin-top: 4px;
                  p {
                    @include fluidFont(14, 12, 1.3);
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    color: rgba($blueDark, 1);
                  }
                }
              }
              .user__msg {
                width: 100%;
                max-width: 100%;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
                .user__msg__header {
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  flex-direction: row-reverse;
                  position: relative;
                  .messagechat__img {
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;
                    img {
                      width: 100%;
                      max-width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .online {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 0;
                    top: -3px;
                    border-radius: 100%;
                    background-color: #3bc740;
                    border: 1px solid rgba($white, 1);
                  }
                  .messagechat__heading {
                    h6 {
                      @include fluidFont(14, 12, 1.5);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: $blueDark;
                      max-width: 170px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }
                .messagecaht__text {
                  text-align: left;
                  padding: 0 36px;
                  p {
                    @include fluidFont(14, 12, 1.3);
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.messagechat__input {
  position: relative;
  // min-height: 50px;
  border-top: 1px solid rgba($blueDark, 0.18);

  .messagechat__edit {
    // padding: 12px 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .v-input {
      :deep(.v-input__control) {
        .v-field {
          border-radius: 8px;
          padding: 0;
          align-items: flex-end;
          .v-field__input {
            // min-height: var(--chat-input-height, 28px);
            // padding: 8px;
            mask-image: none;
            padding: 13px 8px;
            @include fluidFont(12, 12, 1);
            font-weight: 400;
            letter-spacing: 0.5px;
            color: rgba($blueDark, 1);
          }
          .v-field__append-inner {
            padding: 0 0 8px 0;
          }

          .v-icon {
            color: rgba($blueDark, 1);
            opacity: 1;
          }
          .v-field__prepend-inner {
            padding: 0 0 8px 0;
          }
        }
      }
    }
  }
}

.messagechat__wrapper.messagechat__active {
  height: 660px;
  @include respond(s720) {
    height: 450px;
  }
}
.message__date::after {
  content: "";
  position: absolute;
  right: 0;
  border: 1px solid #e8ecee;
  width: 30%;
  top: 10px;
}
.message__date::before {
  content: "";
  position: absolute;
  left: 0;
  border: 1px solid #e8ecee;
  width: 30%;
  top: 10px;
}

.krystal_typing {
  @include fluidFont(12, 12, 1.2);
  color: rgba($blueDark, 0.5);
  font-weight: 400;
  text-align: left;
  display: flex;
  gap: 4px;
  align-items: flex-end;

  span {
    animation: animatedots 1.5s var(--delay) ease-in-out infinite;
    width: 2.5px;
    height: 2.5px;
    border-radius: 100%;
    background-color: rgba($blueDark, 0.5);
    display: inline-block;
  }
}

@keyframes animatedots {
  0%,
  44% {
    transform: translateY(0px);
  }
  22% {
    opacity: 0.4;
    transform: translateY(-7px);
  }
  44% {
    opacity: 0.2;
    transform: translateY(0px);
  }
}
</style>
