<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="570px"
    max-width="100%"
    persistent
    :className="['specialisms_multiselect_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <h2>Add Specialisms</h2>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>
      <div
        class="specialisms_multiselect_modal_body"
        v-if="!isLoading && !getCategoryLoader"
      >
        <!-- <v-btn class="button button-purple-border" block>
          <Text variant="span"> add new trade specialism </Text>
        </v-btn> -->

        <!-- <div class="specialisms_multiselect_modal_search">
          <v-text-field
            variant="outlined"
            placeholder="Search Specialisms"
            prepend-inner-icon="mdi-magnify"
          >
          </v-text-field>
        </div> -->

        <div class="specialisms_multiselect_modal_inner no-scrollbar">
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel
              v-for="(specialism, index) in listOfSpecialismsCategory"
              :key="index"
            >
              <v-expansion-panel-title>
                <div class="specialisms_multiselect_modal_item_heading">
                  <v-checkbox
                    color="#4B4BFF"
                    :value="specialism.id"
                    v-model="mainCategory"
                    @click="onClickMainCategory(specialism.id)"
                  />
                  <div class="specialisms_multiselect_modal_item_title">
                    <v-icon
                      v-if="specialism.iconName"
                      class="specialisms_multiselect_modal_item_title_icon"
                    >
                      <img
                        style="width: 14px"
                        v-if="specialism.iconName"
                        :src="
                          require('@/assets/category-icons/' +
                            specialism.iconName +
                            '.svg')
                        "
                        alt="icon"
                      />
                    </v-icon>
                    <div class="specialisms_multiselect_modal_item_title_text">
                      <h2>{{ specialism?.poLabel }}</h2>
                      <p>
                        {{
                          specialism.subCategories
                            .slice(0, 2)
                            .map((sub) => sub.label)
                            .join(", ")
                        }}
                        <span v-if="specialism.subCategories?.length > 2">
                          etc..</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="specialisms_multiselect_modal_item_content">
                  <v-checkbox
                    color="#4B4BFF"
                    :label="subCategories.label"
                    :value="subCategories.id"
                    v-model="selectedCategory"
                    v-for="subCategories in specialism.subCategories"
                    :key="subCategories.id"
                  >
                  </v-checkbox>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="specialisms_multiselect_modal_footer">
          <v-btn
            class="button button-purple-border"
            @click="onCloseModal"
            block
          >
            <Text variant="span"> Cancel </Text>
          </v-btn>
          <v-btn
            class="button button-orange"
            block
            :loading="isLoadingForSave"
            @click="onClickSaveButton"
          >
            <Text variant="span"> Save </Text>
          </v-btn>
        </div>
      </div>

      <CommonLoader
        :loading="isLoading || getCategoryLoader"
        class="loader-container tw-flex"
      />
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import CommonLoader from "@/core/components/CommonLoader.vue";

export default {
  components: {
    CommonDialog,
    CommonLoader,
  },
  props: {
    projectId: { type: Number, default: null },
    selectedSpecialism: { type: Array, default: null },
    isHistorical: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  setup(props: any, ctx: any) {
    const store = useStore();

    const mainCategory = ref([]) as any;

    const selectedCategory = ref([]) as any;

    const panel = ref([]) as any;

    const onCloseModal = () => {
      ctx.emit("onClickCloseTab");
    };

    const listOfSpecialismsCategory = ref([]) as any;

    const onClickSaveButton = async () => {
      ctx.emit("onClickSaveButtonClick", selectedCategory.value);
    };

    const getCategoryLoader = ref(true);
    const isLoadingForSave = computed(() => {
      return props.isLoading;
    });

    const onClickMainCategory = (mainCategoryId: number) => {
      if (mainCategory.value.includes(mainCategoryId)) {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );
        mainCategory.subCategories.forEach((element: any) => {
          const elementIndex = selectedCategory.value.findIndex(
            (subCategory: any) => subCategory === element.id
          );
          if (elementIndex > -1) {
            selectedCategory.value.splice(elementIndex, 1);
          }
        });
      } else {
        const mainCategory = listOfSpecialismsCategory.value.find(
          (category: any) => category?.id === mainCategoryId
        );

        mainCategory.subCategories.forEach((element: any) => {
          if (!selectedCategory.value.includes(element?.id)) {
            selectedCategory.value.push(element?.id);
          }
        });
      }
    };

    onBeforeMount(async () => {
      listOfSpecialismsCategory.value = await store.dispatch(
        `${USER_STORE}/getCategories`
      );
      if (!props.isHistorical && props.selectedSpecialism.length) {
        props.selectedSpecialism.forEach((specialism: any) => {
          selectedCategory.value.push(specialism.id);
        });

        listOfSpecialismsCategory.value.forEach(
          (specialism: any, index: number) => {
            if (specialism.subCategories.length) {
              const isSelectedAllSubCategory = specialism.subCategories.every(
                (subCategory: any) =>
                  selectedCategory.value.includes(subCategory.id)
              );
              if (isSelectedAllSubCategory) {
                mainCategory.value.push(specialism.id);
                panel.value.push(index);
              }
            }
          }
        );
      }
      getCategoryLoader.value = false;
    });

    return {
      onCloseModal,
      listOfSpecialismsCategory,
      selectedCategory,
      isLoadingForSave,
      onClickSaveButton,
      mainCategory,
      onClickMainCategory,
      panel,
      getCategoryLoader,
    };
  },
};
</script>
