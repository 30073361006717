<template>
  <div class="newgroupchatMessages">
    <v-text-field
      class="chatbox__search create_group__search"
      v-model="searchNetworkUsers"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      variant="solo-filled"
      hide-details
      single-line
      flat
      placeholder="Search"
    ></v-text-field>

    <div class="edit__group">
      <div class="newgroupchatMessages__inputs__wrapper">
        <v-form @submit.prevent :rules="validation" ref="groupFormRef">
          <div class="group_input_content">
            <div class="uploade-wrapper">
              <v-file-input
                class="upload_file"
                variant="solo-filled"
                v-model="groupAvatar"
                :rules="validation.groupAvatar"
                accept="image/*"
                label="Group Avatar"
                @change="handleFileChange"
                :on-click:clear="onClearGroupAvatar"
              >
              </v-file-input>
              <v-img
                class="uploaded-img"
                :width="200"
                aspect-ratio="16/9"
                cover
                :src="imageUrl"
              ></v-img>
              <img
                src="@/assets/icons/camera2.svg"
                alt=""
                class="file-placeholder-image"
              />
            </div>

            <v-text-field
              v-model="groupName"
              :rules="validation.groupName"
              class="messagechat__message"
              variant="solo-filled"
              hide-details
              single-line
              flat
              label="Group Name"
            ></v-text-field>
          </div>
        </v-form>
      </div>
      <div class="newgroupchatMessages__userNetworkContacts__wrapper">
        <div class="group_selector">
          <div>
            <v-tabs v-model="tab" stacked @update:modelValue="onTabChange">
              <v-tab :value="tabs[0]">
                <span>members {{ `(${usersList.length})` }} </span>
              </v-tab>
              <v-tab :value="tabs[1]">
                <span>workStation {{ `(${workStationList.length}) ` }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-window v-if="tab === tabs[0]">
              <div class="group_selector_inner">
                <div
                  v-for="item in usersList"
                  :key="item.id"
                  @click="toggleCheckboxOnClick(item.id)"
                  class="group__list"
                >
                  <v-checkbox
                    color="#4B4BFF"
                    width="16px"
                    :ripple="false"
                    :disabled="isLoading"
                    :modelValue="isChecked(item)"
                    @update:modelValue="
                    (value:any) => updateSelectedItems(item, value)
                  "
                  >
                    <template v-slot:label>
                      <div class="group_img">
                        <img
                          :src="
                            getImagePublicUrl(
                              item?.receiverWorkstation?.user.profileImage
                            )
                              ? getImagePublicUrl(
                                  item?.receiverWorkstation?.user.profileImage
                                )
                              : require('@/assets/images/user-placeholder.png')
                          "
                          alt="workStation_img"
                        />
                      </div>
                      <span>{{
                        `${item?.receiverWorkstation?.user?.firstName} ${item?.receiverWorkstation?.user?.lastName}`
                      }}</span>
                    </template>
                  </v-checkbox>
                </div>
                <div v-if="usersList.length === 0">No users found</div>
              </div>
            </v-tabs-window>
            <v-tabs-window v-if="tab === tabs[1]">
              <div class="group_selector_inner">
                <div
                  v-for="item in workStationList"
                  :key="item.id"
                  @click="toggleCheckboxOnClick(item.id)"
                  :disabled="isLoading"
                  class="group__list"
                >
                  <v-checkbox
                    color="#4B4BFF"
                    width="16px"
                    :ripple="false"
                    :disabled="isLoading"
                    :modelValue="isChecked(item)"
                    @update:modelValue="
                    (value:any) => updateSelectedItems(item, value)
                  "
                  >
                    <template v-slot:label>
                      <div class="group_img">
                        <img
                          :src="
                            getImagePublicUrl(
                              item?.receiverWorkstation.profileImage
                            )
                              ? getImagePublicUrl(
                                  item?.receiverWorkstation.profileImage
                                )
                              : require('@/assets/images/user-placeholder.png')
                          "
                          alt="workStation_img"
                        />
                      </div>

                      <span>{{ `(${item?.receiverWorkstation?.name})` }}</span>
                    </template>
                  </v-checkbox>
                </div>
                <div v-if="workStationList.length === 0">
                  No workStation found
                </div>
              </div>
            </v-tabs-window>
          </div>
        </div>
        <div class="submit_button">
          <v-btn
            class="button button-orange"
            :loading="isLoading"
            @click="createNewUserNetWorkGroup"
          >
            Create New Group
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { USER_STORE } from "@/store/modules/user";
import { ElMessage } from "element-plus";
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "NewGroupChatComponent",
  props: {
    groupNetWorkContactList: {
      type: Array,
    },
    projectId: {
      type: Number,
    },
    userImagesMap: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const selectedItems = ref([]) as any;
    const imageUrl = ref("") as any;
    const groupName = ref("");
    const groupAvatar = ref(null) as any;
    const isLoading = ref(false);
    const searchNetworkUsers = ref("");
    const tab = ref("members");
    const tabs = ref(["members", "workStations"]);
    const usersList = computed(() => {
      return getGroupNetWorkContactList.value.filter(
        (member: any) => member.isUserChat
      );
    });
    const workStationList = computed(() => {
      return getGroupNetWorkContactList.value.filter(
        (member: any) => !member.isUserChat
      );
    });
    const handleFileChange = (event: any) => {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        imageUrl.value = URL.createObjectURL(file);
        groupAvatar.value = file;
      } else {
        imageUrl.value = null;
      }
    };
    const createNewUserNetWorkGroup = async () => {
      try {
        if (!groupName.value.trim()) {
          ElMessage.error("GroupName is required.");
          return;
        }
        if (!groupAvatar.value) {
          ElMessage.error("GroupAvatar is required.");
          return;
        }
        if (!selectedItems.value.length) {
          ElMessage.error("GroupMembers is required.");
          return;
        }

        if (selectedItems.value) {
          const users = [] as any;
          isLoading.value = true;
          const groupFormData = new FormData();
          groupFormData.append("file", groupAvatar.value);
          const responseImage = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            groupFormData
          );

          if (responseImage) {
            isLoading.value = false;
            selectedItems.value.map((item: any) => {
              users.push({
                isUserChat: item?.isUserChat,
                userId: item?.receiverWorkstation?.user?.id,
                workstationId: item?.receiverWorkstation?.id,
              });
            });
            const formData = {
              projectId: props.projectId,
              groupName: groupName.value,
              // workStation type 0 and user type 1
              groupType: tab.value === tabs.value[0] ? 1 : 0,
              groupAvatar: responseImage?.attachment,
              users,
            };

            if (formData) {
              ctx.emit("saveNewUserGroup", formData);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };
    //Toggle the checkbox when click on div
    const toggleCheckboxOnClick = (id: any) => {
      const selectedItem = props.groupNetWorkContactList.find(
        (contact: any) => contact.id === id
      );
      const selectedIndex = selectedItems.value.findIndex(
        (item: any) => item.id === selectedItem.id
      );

      if (selectedIndex === -1) {
        selectedItems.value.push(selectedItem);
      } else {
        selectedItems.value.splice(selectedIndex, 1);
      }
    };

    const updateSelectedItems = (item: any, value: any) => {
      if (value) {
        selectedItems.value.push(item);
      } else {
        const index = selectedItems.value.findIndex(
          (selectedItem: any) => selectedItem === item
        );
        if (index !== -1) {
          selectedItems.value.splice(index, 1);
        }
      }
    };
    // Define computed property
    const isChecked = computed(() => {
      return (item: any) => selectedItems.value.includes(item);
    });

    const getGroupNetWorkContactList = computed(() => {
      const searchTerm = searchNetworkUsers?.value?.trim()?.toLowerCase();
      if (searchTerm && searchTerm !== "") {
        return props.groupNetWorkContactList.filter(
          (user: any) =>
            user?.receiverWorkstation?.name
              ?.toLowerCase()
              .includes(searchTerm) ||
            (user?.isUserChat &&
              (user?.receiverWorkstation?.user?.firstName
                ?.toLowerCase()
                .includes(searchTerm) ||
                user?.receiverWorkstation?.user.lastName
                  ?.toLowerCase()
                  .includes(searchTerm)))
        );
      }
      return props.groupNetWorkContactList;
    });
    const onClearGroupAvatar = () => {
      imageUrl.value = null;
    };

    const getImagePublicUrl = (profileImage: string | null) => {
      if (!profileImage) return require("@/assets/images/user-placeholder.png");
      const imgUrl = props.userImagesMap[profileImage];
      if (!imgUrl) return require("@/assets/images/user-placeholder.png");

      return imgUrl;
    };
    const onTabChange = () => {
      selectedItems.value = [];
    };

    const groupFormRef = ref(null) as any;
    const validation = ref({
      groupName: [(v: any) => !!v || "groupName is required"],
      groupAvatar: [(v: any) => !!v || "groupAvatar is required!"],
    });

    return {
      selectedItems,
      groupName,
      groupAvatar,
      createNewUserNetWorkGroup,
      imageUrl,
      handleFileChange,
      toggleCheckboxOnClick,
      isChecked,
      updateSelectedItems,
      isLoading,
      searchNetworkUsers,
      getGroupNetWorkContactList,
      onClearGroupAvatar,
      getImagePublicUrl,
      tab,
      tabs,
      usersList,
      workStationList,
      onTabChange,
      groupFormRef,
      validation,
    };
  },
};
</script>
<style scoped lang="scss">
.newgroupchatMessages__inputs__wrapper {
  margin: 0 0 16px;
  .v-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .group_input_content {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      gap: 8px;
      .uploade-wrapper {
        width: 48px;
        height: 48px;
        margin: auto;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;

        .file-placeholder-image {
          position: absolute;
          top: 50%;
          left: 50%;
          pointer-events: none;
          transform: translate(-50%, -50%);
        }

        .upload_file {
          margin: 0;
          :deep(.v-input__control) {
            .v-field {
              .v-field__field {
                .v-label {
                  display: none;
                }
                .v-field__input {
                  display: none;
                }
              }
              .v-field__clearable {
                width: unset;
                height: unset;
                background-color: transparent;
                .v-icon {
                  @include fluidFont(12, 12, 1);
                  border-radius: 100%;
                  color: rgba($blueDark, 1);
                  left: 30px;
                  top: -5px;
                }
              }
            }
          }
        }
        .uploaded-img {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          width: 100%;
          pointer-events: none;
          z-index: -1;

          &.uploaded-img {
            z-index: 4;
          }
        }
      }
      .messagechat__message {
        width: calc(100% - 40px) !important;
        max-width: 100%;
        :deep(.v-input__control) {
          .v-field__overlay {
            display: none;
          }

          .v-field {
            border-radius: 8px;
            padding: 4px 12px;
            border: 1px solid #80829f66;

            .v-field__field {
              .v-label {
                margin: 0;
              }
              .v-field__input {
                min-height: 38px;
                padding: 0;
                @include fluidFont(16, 12, 1);
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;

                @include respond(s720) {
                  min-height: 30px;
                }
              }
              ::placeholder {
                @include fluidFont(16, 12, 1);
                font-weight: 400;
                color: rgba($blueDark, 0.5);
              }
            }
          }
        }
      }
    }
    .text-button {
      height: auto;
      min-width: auto;
      padding: 0;
      box-shadow: none;
      opacity: 1;

      :deep(.v-btn__prepend) {
        margin-inline: 0;
      }
      :deep(.v-ripple__container) {
        display: none !important;
      }
    }
  }
}
.newgroupchatMessages__userNetworkContacts__wrapper {
  .group_selector {
    // height: 380px;
    // overflow: auto;
    margin-bottom: 20px;
    // @include respond(sm) {
    //   height: 280px;
    // }

    .group_selector_inner {
      margin-top: 20px;
      height: 300px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 22px;

      @include respond(s720) {
        height: 110px;
      }
    }

    .group__list {
      display: grid;
      align-items: center;
      gap: 22px;
      .group_img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      :deep(.v-checkbox-btn) {
        gap: 8px;
        min-height: 1px;
      }

      :deep(.v-selection-control--density-default) {
        --v-selection-control-size: 18px;
      }

      :deep(.v-label) {
        gap: 8px;
        @include fluidFont(16, 14, 20px);
        letter-spacing: 0.25px;
      }
    }
  }

  .submit_button {
    .button {
      margin-left: auto;
    }
  }
}
.newgroupchatMessages {
  height: 100%;
  .edit__group {
    //height: 100%;
    .newgroupchatMessages__userNetworkContacts__wrapper {
      @include respond(sm) {
        height: 420px;
      }
    }
  }
}

.chatbox__search {
  .v-input__control {
    .v-field {
      border-radius: 9px;
      .v-field__input {
        padding: 0;
        min-height: unset;
        @include fluidFont(12, 12, 1);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      .v-field__field {
        label {
          @include fluidFont(12, 12, 1);
          color: rgba(142, 142, 147, 1);
        }
      }
    }
    .v-field--prepended {
      padding: 0;
      border-color: rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      height: 38px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 9px;
    }
  }
}

.create_group__search {
  border-bottom: 1px solid rgba($blueDark, 0.18);
  padding: 0 0 16px;
  margin-bottom: 16px;

  :deep(.v-input__control) {
    .v-field {
      border-radius: 9px;
      .v-field__input {
        padding: 0;
        min-height: unset;
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      ::placeholder {
        @include fluidFont(12, 12, 1);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
      }
      .v-field__field {
        label {
          @include fluidFont(12, 12, 1);
          color: rgba(142, 142, 147, 1);
          margin-left: 0;
        }
      }
    }
    .v-field--prepended {
      padding: 0;
      border-color: rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      height: 38px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 9px;
    }
  }
}
</style>
