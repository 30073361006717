import { ChatMessages } from "@/core/models/chat-messages";

export default {
  setNetworkDirectMessage(state: ChatMessages, payload: object) {
    state.NetworkDirectMessage = payload;
  },
  setCurrentMessageType(state: ChatMessages, payload: string) {
    state.currentMessageType = payload;
  },
  setProjectChatRequests(state: ChatMessages, payload: Array<any>) {
    state.projectChatRequests = payload;
  },
  setKrystalConversation(state: ChatMessages, payload: Array<any>) {
    state.krystalConversation = payload;
  },
  popKrystalConversation(state: ChatMessages) {
    state.krystalConversation.pop();
  },
  pushKrystalConversation(state: ChatMessages, payload: any) {
    state.krystalConversation.push(payload);
  },
  setUserRoomMessages(state: ChatMessages, payload: any) {
    state.chatMessagesRooms = payload;
  },
};
