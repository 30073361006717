<template>
  <div class="main-header">
    <div class="left-block">
      <span
        >Your <strong> {{ headerTitle }} </strong></span
      >
    </div>
    <div class="right-block">
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" class="header-profile-select">
            <div
              v-if="activeUserWorkstation?.name === 'Default'"
              class="tw-rounded-lg tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
            >
              <div class="img-wrap tw-w-full tw-h-full md:tw-p-0">
                <img
                  :src="userImage"
                  v-if="userImage"
                  width="100%"
                  height="100%"
                  alt=""
                />
                <UserProfileLogo
                  class="tw-rounded-lg tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                  v-else
                  :userName="`${user?.firstName} ${user?.lastName}`"
                />
              </div>
            </div>
            <div
              v-else
              class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
            >
              <ProfileThumbnail
                v-if="userWorkStationImage"
                :src="userWorkStationImage"
                size="mmd"
                mobilSize="mmd"
              />
              <div
                v-else
                class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
              >
                <UserProfileLogo
                  :userName="
                    activeUserWorkstation?.name === 'Default'
                      ? 'Personal Account'
                      : activeUserWorkstation?.name
                  "
                />
              </div>
            </div>
            <span class="tw-w-full">
              {{
                activeUserWorkstation?.name === "Default"
                  ? activeUserWorkstation?.user?.firstName +
                    " " +
                    activeUserWorkstation?.user?.lastName
                  : activeUserWorkstation?.name
              }}</span
            >
            <v-icon
              icon="mdi-chevron-down"
              class="md:!tw-ml-0 md:!tw-mr-[5px]"
            />
          </v-btn>
        </template>
        <v-list class="header-menu">
          <template
            v-if="
              isShowTradePersonWorkstationList &&
              modifiedUserAllWorkStation.length
            "
          >
            <div class="header_workstation_list no-scrollbar">
              <v-list-item
                v-for="workstation in modifiedUserAllWorkStation"
                :key="workstation.id"
                ><div class="tw-flex tw-w-full tw-items-center tw-gap-2">
                  <div v-if="workstation?.name === 'Default'">
                    <div
                      v-if="userImage"
                      class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                    >
                      <ProfileThumbnail
                        :src="userImage"
                        size="mmd"
                        mobilSize="mmd"
                      />
                    </div>
                    <UserProfileLogo
                      v-else
                      class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                      :userName="`${user?.firstName} ${user?.lastName}`"
                    />
                  </div>
                  <div
                    v-else
                    class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                  >
                    <ProfileThumbnail
                      v-if="workstation.publicURL"
                      :src="workstation.publicURL"
                      size="mmd"
                      mobilSize="mmd"
                    />
                    <div
                      v-else
                      class="tw-rounded-lg tw-overflow-hidden tw-max-h-[16px] tw-max-w-[16px] tw-min-h-[16px] tw-min-w-[16px] tw-h-[16px] rsm:tw-max-h-[32px] rsm:tw-max-w-[32px] rsm:tw-min-h-[32px] rsm:tw-min-w-[32px] rsm:tw-h-[32px] tw-relative"
                    >
                      <WorkStationProfileLogo
                        class="workstation-profile-logo"
                        :WSName="workstation?.name"
                      />
                    </div>
                  </div>
                  <v-list-item-title
                    :class="
                      activeUserWorkstation?.name === workstation?.name
                        ? 'selected-station'
                        : ''
                    "
                    @click="onWorkStationClick(workstation.id)"
                    ><span>{{
                      workstation
                        ? workstation?.name === "Default"
                          ? workstation?.user?.firstName +
                            " " +
                            workstation?.user?.lastName
                          : workstation?.name
                        : ""
                    }}</span>
                    <span>
                      {{
                        workstation
                          ? workstation?.user?.userBusinessDetails?.type ==
                            BusinessDetailTypeEnum.LIMITED_COMPANY
                            ? "LTD Company Workstation"
                            : workstation?.name === "Default"
                            ? "Personal Account"
                            : "Sole Trader Workstation"
                          : ""
                      }}
                    </span></v-list-item-title
                  >
                </div>
              </v-list-item>
            </div>
          </template>
          <template v-if="isShowProjectOwnerWorkstationList">
            <div class="header_workstation_list no-scrollbar">
              <v-list-item
                v-for="workstation in modifiedUserAllWorkStation"
                :key="workstation.id"
              >
                <v-skeleton-loader
                  type="list-item-avatar"
                  v-if="loadingImages"
                ></v-skeleton-loader>
                <div v-else class="tw-flex tw-w-full tw-items-center tw-gap-2">
                  <div
                    v-if="workstation?.name === 'Default'"
                    class="tw-w-[32px] tw-h-[32px] tw-overflow-hidden"
                  >
                    <div
                      v-if="userImage"
                      class="tw-w-full tw-max-w-full tw-rounded-full tw-overflow-hidden tw-relative"
                    >
                      <ProfileThumbnail
                        :src="userImage"
                        size="mmd"
                        mobilSize="mmd"
                      />
                    </div>
                    <UserProfileLogo
                      v-else
                      class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                      :userName="`${user?.firstName} ${user?.lastName}`"
                    />
                  </div>
                  <div
                    v-else
                    class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                  >
                    <ProfileThumbnail
                      v-if="workstation.attachmentSource"
                      :src="workstation.attachmentSource"
                      size="mmd"
                      mobilSize="mmd"
                    />
                    <div
                      v-else
                      class="tw-rounded-full tw-overflow-hidden tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px] tw-relative"
                    >
                      <WorkStationProfileLogo
                        class="workstation-profile-logo"
                        :WSName="workstation?.name"
                      />
                    </div>
                  </div>
                  <v-list-item-title
                    class="tw-w-[calc(100%-48px)] tw-max-w-full"
                    :class="
                      activeUserWorkstation?.name === workstation?.name
                        ? 'selected-station'
                        : ''
                    "
                    @click="onWorkStationClick(workstation.id)"
                    ><span>{{
                      workstation
                        ? workstation?.name === "Default"
                          ? workstation?.user?.firstName +
                            " " +
                            workstation?.user?.lastName
                          : workstation?.name
                        : ""
                    }}</span>
                    <span>
                      {{
                        workstation
                          ? workstation?.user?.userBusinessDetails?.type ==
                            BusinessDetailTypeEnum.PROPERTY_OWNER
                            ? "Property Owner Workstation"
                            : workstation?.name === "Default"
                            ? "Personal Account"
                            : "Property Owner Workstation"
                          : ""
                      }}
                    </span></v-list-item-title
                  >
                </div>
              </v-list-item>
            </div>
          </template>
          <div class="header_workstation_btn">
            <v-list-item
              @click="redirectToCreateWorkstation"
              class="select__btn"
            >
              <v-btn class="button button-purple-border w-100">
                Create new workstation
              </v-btn>
            </v-list-item>
            <v-list-item @click="onClickSwitchUserType" class="select__btn">
              <!-- :class="{
                'tw-pointer-events-none': isAppIknowa,
                'tw-opacity-50': isAppIknowa,
              }" -->
              <!-- class="select__btn tw-pointer-events-none tw-opacity-50" -->
              <v-btn class="button button-purple w-100">
                <img
                  v-if="user?.role?.name === UserRolesEnum.TRADESPERSON"
                  class="switch-role-icon"
                  src="../../assets/icons/white-filled-home-icon.svg"
                  width="32"
                  height="32"
                /><img
                  v-else
                  class="switch-role-icon"
                  src="../../assets/icons/avatar-tp-icon.svg"
                  width="32"
                  height="32"
                />Switch to
                {{
                  user?.role?.name === UserRolesEnum.TRADESPERSON
                    ? "Property Side"
                    : "Trade side"
                }}
              </v-btn>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="settings__icon"
            size="small"
            color="white"
            icon="mdi-cog"
          />
        </template>
        <v-list class="setting-menu">
          <v-list-item v-for="setting in userAllSetting" :key="setting.id">
            <v-list-item-title
              :class="{
                'selected-setting': activeUserSetting === setting?.id,
                '!tw-hidden':
                  activeUserWorkstation?.name === 'Default' && setting.id === 2,
              }"
              @click="onSettingClick(setting.id)"
              ><div><v-icon :icon="setting.iconName" />{{ setting.name }}</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="hasUnreadNotifications"
        class="notify__icon"
        size="small"
        color="white"
        icon="mdi-bell"
        @click="onClickNotificationButton"
      >
        <v-icon icon="mdi-bell" color="black"></v-icon>
        <span class="red-dot"></span>
      </v-btn>
      <v-btn
        v-else
        class="notify__icon"
        size="small"
        color="white"
        icon="mdi-bell"
        @click="onClickNotificationButton"
      />
    </div>
  </div>
  <v-dialog
    width="500px"
    v-model="loading"
    class="modal_box action-modal"
    persistent
  >
    <div class="modal_wrapper">
      <main class="main">
        <div>
          <div class="loading-dots">
            <span class="dot dot-red"></span>
            <span class="dot dot-blue"></span>
            <span class="dot dot-green"></span>
          </div>
          <p class="switching-text">{{ switchingText }}</p>
          <v-stepper class="onboard-stepper" v-model="currentSelectedTab.name">
            <v-stepper-header class="onboard-stepper__header">
              <v-stepper-item
                class="onboard-stepper__item"
                v-for="(step, index) in tabListItems"
                :key="index"
                :value="step.name"
              ></v-stepper-item>
            </v-stepper-header>
          </v-stepper>
        </div>
      </main>
    </div>
  </v-dialog>
  <AddressDetailsComponentModal
    v-if="isOpenAddressModal"
    @onClickCloseTab="toggleAddressModal"
  />
  <NotificationsComponent
    v-if="isShowNotification"
    @closeNotificationComponent="onCloseNotificationComponent"
  />
  <PropertyOwnerWorkStationModal
    v-if="isShowPropertyOwnerWorkStationModal"
    @onClickCloseTab="onClickClosePOWorkstationModal"
  />
</template>

<script lang="ts">
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import AddressDetailsComponentModal from "@/modules/profile/components/AddressDetailsComponent.vue";
import NotificationsComponent from "@/core/components/NotificationComponent.vue";
import { useRouter, useRoute } from "vue-router";
import {
  UserRolesEnum,
  BusinessDetailTypeEnum,
  RolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { PROFILE_ROUTE } from "@/modules/profile/routes";
import { WORKSTATION_SETTING_ROUTE } from "@/modules/workstation-setting/routes";
import { inject } from "vue";
import $axios from "@/core/utils/axios-api-config";
import NotificationService from "@/core/services/notification.service";
import ProfileThumbnail from "../uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "./UserProfileLogo.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import {
  getImageApiUrl,
  getLocalStorageWithExpiry,
  isAliased,
  setLocalStorageWithExpiry,
} from "../utils/common";
import { AUTH_SESSION_EXPIRY, AUTH_USER } from "../constants";
import { NotificationStateEnum } from "@/core/enums/NotificationEnum";
import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PROPERTY_STORE } from "@/store/modules/property";
import insurancesService from "../services/insurances.service";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import UserService from "../services/user.service";
export default {
  components: {
    AddressDetailsComponentModal,
    NotificationsComponent,
    ProfileThumbnail,
    UserProfileLogo,
    PropertyOwnerWorkStationModal,
    WorkStationProfileLogo,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const loadingImages = ref(false);
    const isOpenAddressModal = ref(false);
    const isShowNotification = ref(false);
    const hasUnreadNotifications = ref(false);
    const isShowPropertyOwnerWorkStationModal = ref(false);

    const pusher = inject("pusher") as any;
    const modifiedUserAllWorkStation = ref([]) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );
    // const isAppIknowa = computed(() => {
    //   return window.location.hostname.startsWith("app.iknowa");
    // });
    watch(
      () => userAllWorkStation.value,
      () => {
        modifiedUserAllWorkStation.value = [];
        modifyAllUserWorkstation();
      }
    );
    const propertyOwnerUserAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getPropertyOwnerUserWorkstations`]
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );

    const isShowTradePersonWorkstationList = computed(
      () => user.value?.role?.name === UserRolesEnum.TRADESPERSON
    );

    const isShowProjectOwnerWorkstationList = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );

    const userWorkStationStatusInsurance = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatusInsurance.value ===
        UserVerificationStatusEnum.VERIFIED
      );
    });
    const activeUserSetting = ref();

    const userAllSetting = ref([
      {
        id: 1,
        name: "Personal Settings",
        iconName: "mdi-account",
      },
      {
        id: 2,
        name: "Workstation Settings",
        iconName: "mdi-home",
      },
    ]);

    const onSettingClick = async (id: number) => {
      activeUserSetting.value = id;
      if (id === 1) {
        await router.push({ name: PROFILE_ROUTE });
      } else {
        await router.push({ name: WORKSTATION_SETTING_ROUTE });
      }
    };

    const getWorkstationProfile = async (profileImage: any) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      } finally {
        loadingImages.value = false;
      }
    };

    const modifyAllUserWorkstation = async () => {
      loadingImages.value = true;
      if (!userAllWorkStation.value && !userAllWorkStation.value.length) {
        loadingImages.value = false;
        return [];
      }
      try {
        const attachmentPromises = userAllWorkStation.value.map(
          (workstation: any) => {
            const { profileImage } = workstation;
            return getWorkstationProfile(profileImage);
          }
        );
        const attachmentSources = await Promise.all(attachmentPromises);

        modifiedUserAllWorkStation.value = [];
        userAllWorkStation.value.forEach((workstation: any, index: number) => {
          modifiedUserAllWorkStation.value.push({
            ...workstation,
            attachmentSource: attachmentSources[index],
          });
        });
        for (const contractor of modifiedUserAllWorkStation.value) {
          if (contractor.profileImage !== null) {
            const imageUrl = getImageApiUrl(contractor.profileImage, true);
            const encoded: any = await $axios.get(imageUrl);
            contractor.publicURL = encoded?.publicUrl;
          }
        }
      } catch (error) {
        loadingImages.value = false;
      } finally {
        loadingImages.value = false;
      }
    };

    const onWorkStationClick = async (
      workstationId: number,
      resetData = true
    ) => {
      try {
        if (!workstationId) return;
        const workstation = modifiedUserAllWorkStation.value.find(
          (workstation: any) => workstation?.id === workstationId
        );
        if (workstation?.isInvited && !workstation?.isSameRole) {
          await UserService.activateWorkstation(
            user.value?.id,
            workstation?.id
          );
          onClickSwitchUserType(false);
          return;
        }
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
        if (resetData) {
          await router.push({ name: DASHBOARD_ROUTE });
          await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: workstationId,
          });
        }
        const workstationVerification = await store.dispatch(
          `${WORKSTATION}/getWorkstationVerificationData`,
          workstationId
        );
        await store.dispatch(`${JOBS_STORE}/fetchHistoricalJobs`);
        if (
          workstationVerification &&
          workstationVerification?.response?.id &&
          ["KYC", "KYB"].includes(workstationVerification?.verificationType)
        ) {
          await store.dispatch(
            `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
            {
              id: workstationVerification.response.id,
              verificationType: workstationVerification?.verificationType,
            }
          );
          await router.push({ name: DASHBOARD_ROUTE });
        } else {
          await store.dispatch(
            `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
            null
          );
        }
      } catch (error) {
        console.log();
      } finally {
        if (resetData) {
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);

          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value?.id
          );
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value?.id
          );

          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
          if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
            await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});
          }
          if (isWorkStationVerified.value) {
            isInsurenceExpired(user.value.id);
          }
        }
        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
      }
    };
    const headerTitle = computed(() => {
      return route?.meta?.title || "";
    });

    const tabListItems = [
      {
        tab: "step1",
        name: "Switching Account...",
      },
      {
        tab: "step2",
        name: "Fetching Account Data...",
      },
      {
        tab: "step3",
        name: "Loading Account...",
      },
    ];

    const switchingText = ref("Switching Account...");
    const currentSelectedTab = ref(tabListItems[0]);
    const onClickSwitchUserType = async (inactivateWorkstation = true) => {
      // if (isAppIknowa.value) return;
      let updatedUser: any;
      // Commented For Now
      try {
        // if (!user.value.postcode) {
        //   return toggleAddressModal();
        // }

        loading.value = true;
        if (inactivateWorkstation) {
          const workstationIdsForInactive =
            modifiedUserAllWorkStation.value.reduce(
              (previous: any, current: any) => {
                if (current?.isInvited) {
                  previous.push(current?.id);
                }
                return previous;
              },
              []
            );
          if (workstationIdsForInactive.length) {
            await UserService.inactivateWorkstations(user.value?.id, {
              workstationIds: workstationIdsForInactive,
            });
          }
        }

        const selectedRole =
          user.value?.role?.name === UserRolesEnum.TRADESPERSON
            ? RolesEnum.PROJECT_OWNER
            : RolesEnum.TRADESPERSON;
        updatedUser = await store.dispatch(`${USER_STORE}/selectAccount`, {
          userId: user.value?.id,
          roleId: selectedRole,
          email: user.value?.email,
        });
        const authUser = getLocalStorageWithExpiry(AUTH_USER);
        const newUserState = await store.dispatch(
          `${USER_STORE}/getUser`,
          updatedUser?.id
        );
        authUser.userId = newUserState.id;
        authUser.roleId = selectedRole;
        authUser.aliased = isAliased(newUserState.email);
        setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
        setTimeout(async () => {
          switchingText.value = "Switching Account...";
          currentSelectedTab.value = tabListItems[0];
          store.commit(`${USER_STORE}/resestStore`);
        }, 1000);
        if (
          activeUserWorkstation.value?.id &&
          isShowTradePersonWorkstationList.value
        ) {
          onWorkStationClick(activeUserWorkstation.value?.id, false);
        }
      } catch (error) {
        console.log();
      } finally {
        setTimeout(() => {
          switchingText.value = "Fetching Account Data...";
          currentSelectedTab.value = tabListItems[1];
        }, 2000);
        setTimeout(async () => {
          switchingText.value = "Loading Account...";
          currentSelectedTab.value = tabListItems[2];
          loading.value = false;
          await store.dispatch(`${USER_STORE}/saveUserState`, updatedUser);
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          modifiedUserAllWorkStation.value = [];
          await router.push({ name: DASHBOARD_ROUTE });
          modifyAllUserWorkstation();
          // Reload the page after navigating to the dashboard
          window.location.reload();
        }, 5000);
      }
    };
    const toggleAddressModal = () => {
      isOpenAddressModal.value = !isOpenAddressModal.value;
    };
    const redirectToCreateWorkstation = async () => {
      await router.push({ name: DASHBOARD_ROUTE });
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        await store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: true,
          createdWorkstationId: null,
        });
      } else if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        isShowPropertyOwnerWorkStationModal.value = true;
      }
    };

    const onClickClosePOWorkstationModal = () => {
      isShowPropertyOwnerWorkStationModal.value = false;
    };

    const channel = pusher?.subscribe(
      `private-notifications-user-${user.value?.id}`
    );

    const channel1 = pusher?.subscribe("presence-channel");
    channel1?.bind("pusher:subscription_succeeded", function (members: any) {
      Object.values(members.members).forEach((value) => {
        store.commit(`${WORKSTATION}/setUserOnlineWorkStations`, value);
      });
    });

    channel1?.bind("pusher:member_added", function (member: any) {
      store.commit(`${WORKSTATION}/setUserOnlineWorkStations`, member.info);
    });

    channel1?.bind("pusher:member_removed", function (member: any) {
      store.commit(`${WORKSTATION}/removeUserOnlineWorkStations`, member.info);
    });

    channel
      ?.unbind("user.notifications")
      .bind("user.notifications", (data: any) => {
        store.commit(`${USER_STORE}/setUserNotifications`, data);
        if (data.state == NotificationStateEnum.UNREAD) {
          hasUnreadNotifications.value = true;
        } else {
          hasUnreadNotifications.value = false;
        }
      });

    const isInsurenceExpired = async (id: number) => {
      const responce: any = await insurancesService.isInsuranceExpired(id);
      if (responce.isFreezed) {
        // userInsurenceExperiedStatus.value = true;
        store.commit(`${INSURANCE_STORE}/setInsuranceExpired`, true);
      }
    };
    onBeforeMount(async () => {
      try {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
        if (!user.value) {
          await store.dispatch(`${USER_STORE}/initializeUserState`);
        }
        if (user.value?.userPublicProfile?.profileImage) {
          store.dispatch(
            `${USER_STORE}/setUserProfileImage`,
            user.value?.userPublicProfile?.profileImage
          );
        }
        if (!activeUserWorkstation.value?.id) {
          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        }
        if (
          activeUserWorkstation.value?.id &&
          isShowTradePersonWorkstationList.value
        ) {
          onWorkStationClick(activeUserWorkstation.value?.id, false);
        }
        modifyAllUserWorkstation();
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
        userNotifications.map((notification: any) => {
          if (notification.state == NotificationStateEnum.UNREAD) {
            hasUnreadNotifications.value = true;
          } else {
            hasUnreadNotifications.value = false;
          }
        });
      } catch (error) {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        console.log("error", error);
      } finally {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
      }
    });

    const onClickNotificationButton = () => {
      isShowNotification.value = !isShowNotification.value;
    };

    const onCloseNotificationComponent = () => {
      isShowNotification.value = false;
    };

    return {
      userAllWorkStation,
      activeUserWorkstation,
      headerTitle,
      onWorkStationClick,
      user,
      onClickSwitchUserType,
      redirectToCreateWorkstation,
      UserRolesEnum,
      isShowTradePersonWorkstationList,
      BusinessDetailTypeEnum,
      switchingText,
      tabListItems,
      currentSelectedTab,
      loading,
      isOpenAddressModal,
      toggleAddressModal,
      isShowNotification,
      onClickNotificationButton,
      hasUnreadNotifications,
      NotificationStateEnum,
      userAllSetting,
      activeUserSetting,
      onSettingClick,
      userWorkStationImage,
      isShowProjectOwnerWorkstationList,
      propertyOwnerUserAllWorkStation,
      onClickClosePOWorkstationModal,
      isShowPropertyOwnerWorkStationModal,
      getWorkstationProfile,
      loadingImages,
      modifiedUserAllWorkStation,
      userImage,
      onCloseNotificationComponent,
      // isAppIknowa,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-menu {
  max-height: calc(100dvh - 190px);
  overflow: auto;
  min-height: 180px;
  .v-list-item {
    .v-list-item-title {
      display: flex;
      flex-direction: column;
      span {
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
        text-overflow: ellipsis;
      }
    }
  }
}

.header_workstation_list {
  max-height: 335px;
  overflow: auto;

  @include respond(s720) {
    max-height: 294px;
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  animation: loading-animation 3.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
}

@keyframes loading-animation {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

.loading-dots {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background-color: $white;
  margin: 0 auto;
  box-shadow: 1px 1px 5px #f5f5f5;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
  position: absolute;
}

.dot-red {
  background-color: $orange;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 80px;
  left: 35px;
}

.dot-blue {
  background-color: $PrimaryBlue;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dot-green {
  background-color: #c6d2f5;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 80px;
  right: 35px;
}

.switching-text {
  text-align: center;
  color: $PrimaryBlue;
  margin-top: 3rem;
}

.progress-bar {
  background-color: #ddd;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
  transition: width 5s linear;
}

.action-modal .modal_wrapper {
  width: 100%;

  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}

.onboard-stepper .v-stepper-header {
  margin-top: 15px;
}
.setting-menu {
  padding: 12px 16px;
  border-radius: 8px !important;
  box-shadow: 0px 0px 8px 0px #0c0f4a14 !important;
  top: 10px;
  .v-list-item {
    padding: 8px 0 !important;
    min-height: auto;
    border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
    &:first-child {
      padding-top: 0;
    }
    .v-list-item__content {
      .v-list-item-title {
        padding: 2px 0;
        cursor: pointer;
        @include fluidFont(14, 14, 1.2);
        font-weight: 600;
        color: rgba($blueDark, 1);
        .v-icon {
          margin-right: 8px;
        }
      }
      .selected-setting {
        color: rgba($orange, 1);
      }
    }
  }
}
:deep(.v-overlay__content) {
  max-width: 350px !important;
}

// :deep(.v-btn__content) {
//   gap: 0;
// }
.v-menu > .v-overlay__content > .v-list {
  overflow: hidden;
}
.workstation-profile-logo {
  width: 100%;
  height: 100%;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
