<template>
  <v-dialog
    width="968px"
    v-model="isShowModel"
    class="custom__modal__box"
    persistent
    max-width="100%"
    height="auto"
    :class="className"
  >
    <slot name="top"></slot>
    <div
      v-if="isShowBackButton"
      class="previous_btn isShowSoleTraderWorkStation"
    >
      <v-btn
        variant="text"
        aria-label="Refresh"
        class="back_btn"
        prepend-icon="mdi-chevron-left"
        @click="onClickBackButton"
        >back</v-btn
      >
    </div>
    <main class="main !tw-overflow-y-auto">
      <header v-if="isShowHeader" class="custom__modal__header">
        <img
          class="open-logo"
          src="../../assets/images/logo/iknowa-logo-v4.svg"
        />
        <h4 v-if="HeaderTittle">{{ HeaderTittle }}</h4>
      </header>

      <div
        class="custom__modal__content md:!tw-h-full md:!tw-flex md:!tw-flex-col md:!tw-justify-between"
        :class="isShowHeader ? '' : 'modal_header_hide'"
      >
        <div class="custom__modal__body__content">
          <div class="custom__modal__heading" v-if="title">
            <h3 class="title md:!tw-text-[18px]">{{ title }}</h3>
            <p
              v-if="description"
              class="description md:!tw-text-[14px] md:!tw-mt-[6px] md:!tw-leading-[18px]"
            >
              {{ description }}
            </p>

            <!-- <h3 class="title">{{ title }}</h3>
            <p v-if="description" class="description">
              {{ description }}
            </p> -->
          </div>

          <slot name="body"></slot>
        </div>
        <slot name="footer"></slot>
      </div>
    </main>
  </v-dialog>
</template>

<script>
export default {
  props: {
    HeaderTittle: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
    },
    onClickBackButton: {
      type: Function,
    },
    isShowBackButton: {
      type: Boolean,
      default: true,
    },
    isShowHeader: {
      type: Boolean,
      default: true,
    },
    className: {
      type: Array,
    },
  },
  setup() {
    const isShowModel = true;
    return {
      isShowModel,
    };
  },
};
</script>
<style lang="scss" scoped>
.previous_btn {
  .back_btn {
    padding: 0;
    text-transform: capitalize;
  }
}
.modal_wrapper {
  background-color: transparent !important;
}
.trade__custom__modal {
  .v-overlay__content {
    padding: 0;

    .main {
      padding: 24px;
      border-radius: 8px !important;
      background-color: rgba($white, 1);
      .custom__modal__content {
        padding: 0;
        .custom__modal__body__content {
          :deep(.text) {
            h3 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              letter-spacing: 0.15px;
              color: rgba($blueDark, 1);
            }
            p {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 0.5);
              margin-top: 8px;
            }
          }
          :deep(.input__field) {
            margin-top: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            label {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.15px;
              color: rgba($blueDark, 1);
            }
            .c-input {
              margin: 0;
              .v-field__input {
                padding: 0 16px;
                min-height: 40px;
                border: 1px solid rgba($buttonText, 0.4);
                background-color: transparent;
                border-radius: 8px;
              }
            }
          }
        }
        :deep(.trade__buttons) {
          margin-top: 48px;
          display: flex;
          gap: 20px;
          align-items: center;
          margin-top: 24px;
        }
        .delete__btns {
          margin-top: 36px;
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

/* Send and receive request modal */
.send__receive__request__modal {
  text-align: center;
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0;
    .previous_btn {
      text-align: left;
    }
    .main {
      border-radius: 8px;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          :deep(.delete_icon) {
            .v-icon {
              @include fluidFont(54, 54, 1);
              color: rgba($errorRed, 1);
            }
          }
          .text {
            h3 {
              @include fluidFont(24, 24, 1);
              font-weight: 700;
              color: rgba($blueDark, 1);
            }
            p {
              margin-top: 20px;
              @include fluidFont(16, 16, 1.1);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
            }
          }
        }
        .requests__buttons {
          margin-top: 36px;
          display: flex;
          gap: 12px;
          align-items: center;
          .button {
            width: 50%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.carousel__dialog {
  .custom__modal__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond(s720) {
      padding: 24px 16px;
    }
  }
}

.occupier__last__step__box {
  .v-overlay__content {
    .main {
      height: auto;
      .custom__modal__content {
        overflow: unset;
      }
    }
  }
}

/* Delete modal*/

.delete__custom__modal {
  text-align: center;
  .v-overlay__content {
    .previous_btn {
      text-align: left;
    }
    .main {
      border-radius: 8px;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          :deep(.delete_icon) {
            .v-icon {
              @include fluidFont(54, 54, 1);
              color: rgba($errorRed, 1);
            }
          }
          :deep(.text) {
            margin-top: 16px;
            h3 {
              @include fluidFont(24, 24, 1);
              font-weight: 700;
              color: rgba($blueDark, 1);
            }
            p {
              margin-top: 20px;
              @include fluidFont(16, 16, 1.1);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
              span {
                margin-top: 20px;
                @include fluidFont(16, 16, 1.1);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
          }
        }
        :deep(.delete__btns) {
          margin-top: 36px;
          display: flex;
          gap: 12px;
          align-items: center;
          .button {
            width: 50%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.custom__modal__text__box {
  .v-overlay__content .main .custom__modal__content {
    text-align: center;
  }
}
.edit__documents_attachment__modal {
  .v-overlay__content .main .custom__modal__content {
    :deep(.custom__modal__body__content .custom__modal__heading) {
      .footer__button .cancel__button .save__button {
        top: 0;
      }
    }
  }
}

/* Welcome Modal Box */

.welcome__modal_box {
  .v-overlay__content {
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        padding: 50px 96px;

        @include respond(s720) {
          padding: 36px 22px;
        }
        .custom__modal__body__content {
          .custom__modal__heading {
            @include respond(s720) {
              text-align: left;
            }
          }
          :deep(.header-title) {
            @include fluidFont(36, 36, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            display: flex;
            justify-content: center;
          }
          :deep(.header-sub-title) {
            @include fluidFont(36, 36, 1.2);
            font-weight: 700;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            display: flex;
            justify-content: center;
          }
          :deep(.welcome__image) {
            margin-top: 56px;
            @include respond(s720) {
              margin-top: 20px;
            }
          }
          :deep(.welcome__text) {
            margin-top: 52px;
            @include fluidFont(16, 16, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 0.5);
            @include respond(s720) {
              margin-top: 20px;
              text-align: left;
            }
          }
        }
        :deep(.button) {
          margin-top: 44px;
          width: 100%;
          max-width: 100%;
          @include respond(s720) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

/* Property List Modal */
.edit__propertyList__modal {
  :deep(.v-overlay__content) {
    padding: 16px !important;
    margin: 0 !important;
    .main {
      border-radius: 13px;
      .custom__modal__content {
        padding: 30px 20px;
        overflow: auto;
        height: 100%;

        .custom__modal__body__content {
          height: 100%;
          position: relative;
          overflow: hidden;
          .close__icon {
            position: absolute;
            right: 0;
            top: 0;
          }
          .custom__modal__heading {
            h3 {
              @include fluidFont(25, 25, 1.2);
              font-weight: 600;
              letter-spacing: 0.64px;
              color: rgba($blueDark, 1);
              display: flex;
              align-items: center;
              gap: 8px;
              span {
                width: 30px;
                height: 30px;
                background-color: rgba($success, 1);
                color: rgba($white, 1);
                font-weight: 600;
                @include fluidFont(14, 14, 1.2);
                display: inline-block;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            p {
              @include fluidFont(14, 14, 1.2);
              font-weight: 400;
              letter-spacing: 0.4px;
              color: rgba($blueDark, 0.8);
              margin-top: 10px;
            }
          }
          .modal___content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .property__cards {
              margin-top: 24px;
              height: calc(100dvh - 430px);
              overflow: auto;

              .courses__card__list {
                display: flex;
                gap: 24px;
                align-items: flex-start;
                @include respond(sm) {
                  flex-direction: column;
                }
                .courses__card {
                  width: calc(33.33% - 16px);
                  max-width: 100%;
                  padding: 12px;
                  text-align: left;
                  box-shadow: 0px 0px 8px 0px #0c0f4a14;
                  border: 2px solid transparent;

                  @include respond(s720) {
                    width: calc(50% - 12px);
                  }
                  @include respond(sm) {
                    width: 100%;
                  }
                  .courses__card_top_title {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    .v-icon {
                      width: 16px;
                      height: 16px;
                      background-color: rgba($PrimaryPurple, 1);
                      border-radius: 100%;
                      img {
                        filter: invert(100%) sepia(100%) saturate(0%)
                          hue-rotate(3deg) brightness(100%) contrast(104%);
                        width: 6px;
                        height: 6px;
                      }
                    }
                    h4 {
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 700;
                      letter-spacing: 0.8px;
                      color: rgba($PrimaryPurple, 1);
                    }
                  }
                  .courses__card__img {
                    margin-top: 8px;
                    width: 100%;
                    height: 80px;
                    border-radius: 8px;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .courses__card__prefix {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                    .left__prefix {
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      justify-content: flex-start;
                      .v-icon {
                        color: rgba($white, 1);
                        background-color: rgba($info, 1);
                        border-radius: 100%;
                        width: 16px;
                        height: 16px;
                        img {
                          filter: invert(100%) sepia(0%) saturate(0%)
                            hue-rotate(24deg) brightness(103%) contrast(102%);
                          width: 8px;
                          height: 8px;
                        }
                      }
                      p {
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 700;
                        letter-spacing: 0.15px;
                        color: rgba($info, 1);
                        margin: 0;
                      }
                    }
                    .right__prefix {
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      justify-content: flex-end;
                      .v-icon {
                        color: rgba($buttonText, 1);
                        @include fluidFont(14, 14, 1.2);
                      }
                      p {
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        letter-spacing: 0.25px;
                        color: rgba($buttonText, 1);
                        margin: 0;
                      }
                    }
                  }
                  .courses__card__text {
                    max-width: 70%;
                    margin-top: 4px;
                    h5 {
                      @include fluidFont(16, 12, 1.2);
                      font-weight: 700;
                      letter-spacing: 0.25px;
                      color: rgba($blueDark, 1);
                    }
                  }
                }
                .selected-card {
                  border: 2px solid rgba($PrimaryBlue, 1);
                }
              }
            }

            .footer__button {
              margin-top: 50px;
              display: flex;
              justify-content: flex-end;
              gap: 12px;
              @include respond(md) {
                margin-top: 30px;
              }
              .button {
                min-width: 140px;
                @include respond(s720) {
                  width: calc(50% - 6px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.v-expansion-panel:not(:first-child)::after {
  display: none;
}

/* Property upload modal */

.property__upload__modal {
  :deep(.v-overlay__content) {
    padding: 16px !important;
    margin: 0 !important;
    .main {
      border-radius: 10px;
      .custom__modal__content {
        height: 100%;
        padding: 0;
        max-height: 100%;
        .custom__modal__body__content {
          position: relative;
          height: calc(100% - 96px);
          .close__icon {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
          }
          .modal__content {
            display: flex;
            padding: 46px 46px 0;
            border-bottom: 1px dashed #8687a5;
            gap: 25px;
            height: 100%;
            @include respond(s720) {
              flex-direction: column;
              padding: 15px;
            }
            .left_upload_img {
              width: 375px;
              max-width: 100%;
              @include respond(md) {
                width: 100%;
                border: none;
              }
              .upload__area {
                border-radius: 6px;
                margin-top: 20px;
                border: 1px dashed rgba($black, 0.4);
                background-color: rgba($cardBorder, 1);
                padding: 0;
                .uploade-wrapper {
                  position: relative;
                  width: 100%;
                  height: 300px;
                  margin: 0;
                  z-index: 1;
                  .uploaded-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100% !important;
                    pointer-events: none;
                    z-index: -1;
                  }
                }
                .upload_file {
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: relative;
                  border-radius: 0;

                  cursor: pointer;
                  margin: 0 auto 24px;

                  .v-field__overlay,
                  .v-field {
                    border-radius: 0;

                    background-color: rgba($blueDark, 0.15) !important;
                    box-shadow: none;
                    .v-field__overlay {
                      display: none;
                    }
                  }
                  &.added-img {
                    .v-field__overlay {
                      background: transparent;
                    }
                    .v-field {
                      background: transparent;
                    }
                    .v-field__input {
                      display: none;
                    }
                  }

                  input {
                    cursor: pointer;
                  }
                  .v-field__clearable {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    opacity: 1;
                    overflow: unset;
                    grid-area: unset;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($black, 0.5);
                    color: rgba($white, 1);
                    border-radius: 0;
                  }
                  .v-input__prepend {
                    display: none;
                  }
                  .v-input__control {
                    position: absolute;
                    inset: 0;
                    border-radius: 0;
                  }
                  .v-field--appended {
                    padding: 0;
                    position: relative;
                  }
                  .v-field__append-inner {
                    all: initial;
                    color: rgba($blueDark, 0.5);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }

                  .v-field-label {
                    left: 50%;
                    transform: translateX(-50%) !important;
                    margin: 28px 0 0 0;
                    font-size: 16px;
                    font-weight: 500;
                    /* max-width: 130px;*/
                    bottom: -30px;
                    top: unset !important;
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 700;
                    color: rgba($blueDark, 0.7);
                    opacity: 1;
                  }
                }
                .upload__text {
                  text-align: center;
                  padding: 30px 50px;
                  p {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 500;
                    color: rgba($blueDark, 0.5);
                    span {
                      text-decoration: underline;
                    }
                  }
                }
                .upload__button {
                  .button {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
              .selected-info {
                max-width: 342px;
                display: flex;
                gap: 12px;
                align-items: center;
                margin: 24px auto;
                .button {
                  min-width: 100%;
                  max-width: 100%;
                }

                &.w-full {
                  max-width: 100%;

                  .button {
                    min-width: 100%;
                  }
                }
              }
            }
            .image__upload__wrapper {
              height: auto;
              padding: 0;
              overflow: unset;
              width: calc(100% - 400px);
              display: flex;
              align-items: center;
              @include respond(md) {
                width: 100%;
                max-width: 100%;
                padding: 0;
              }
              .blank_project {
                text-align: center;
                p {
                  @include fluidFont(16, 16, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                }
              }
              h5 {
                @include fluidFont(14, 14, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                margin-bottom: 16px;
              }
              .uploaded_details_wrapper {
                height: calc(100dvh - 350px);
                overflow: auto;
                .show_uploaded_details {
                  border-top: 1px solid rgba($PrimaryBlueLight, 1);
                  padding: 24px 0;
                  .v-responsive {
                    border-radius: 8px;
                    overflow: hidden;
                  }
                  .prop_img_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px 0;

                    &:hover {
                      background-color: transparent;
                    }
                    h5 {
                      margin: 0;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 700;
                      color: rgba($blueDark, 1);
                    }
                    .v-btn {
                      padding: 0;
                      min-width: auto;
                      min-height: 1px;
                      height: auto;
                      background-color: transparent !important;

                      .v-btn__overlay,
                      .v-btn__underlay {
                        display: none;
                      }
                    }
                  }
                  .upload__list {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    flex-direction: column;
                    .selectprop_dropdown {
                      width: 100%;
                      max-width: 100%;
                      .v-input__control {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              height: 32px;
                              padding: 0 16px;
                              // flex-direction: row-reverse;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                                margin-right: auto;
                              }
                              .v-select__selection {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 0 16px;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .v-text-custom {
                      margin: 0;
                      width: 100%;
                      max-width: 100%;
                      :deep(.v-input__control) {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              // flex-direction: row-reverse;
                              height: 32px;
                              padding: 0;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                              }
                              .mdi-menu-down {
                                margin-top: -5px;
                              }
                            }
                            .v-select__selection {
                              span {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .footer-buttons {
            padding: 23px 26px;
            display: flex;
            justify-content: flex-end;
            gap: 12px;
            @include respond(s720) {
              padding: 15px;
            }
            .button {
              min-width: 140px;
              @include respond(s720) {
                width: calc(50% - 4px);
              }
              .v-btn__content {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

/* certificate modal box */
.certificate_modal_box {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 20px;
      .custom__modal__content {
        // height: calc(100% - 130px);
        height: calc(100% - 80px);
        .custom__modal__body__content {
          .sole-trader-data-wrapper {
            .form__wrapper {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              form.v-form {
                @include respond(sm) {
                  height: auto;
                }
              }
            }
            .insurances-list {
              .insurances-list__innner {
                .insurances-list__item {
                  gap: 8px;
                  .logo_wrapper {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($PrimaryBlueLight, 1);
                    border-radius: 100%;

                    img {
                      filter: invert(26%) sepia(46%) saturate(3627%)
                        hue-rotate(218deg) brightness(87%) contrast(96%);
                      width: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Create Workstation modal */
.workstation_create_modal {
  :deep(.v-overlay__content) {
    padding: 0 15px !important;
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        height: 100%;
        overflow: hidden;
        padding: 36px 26px;
        .custom__modal__body__content {
          .header-title {
            // display: flex;
            justify-content: center;
            @include fluidFont(24, 24, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            span {
              @include fluidFont(24, 24, 1.2);
              font-weight: 700;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 1);
            }
          }
          .header-sub-title {
            justify-content: center;
            @include fluidFont(24, 24, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            span {
              @include fluidFont(24, 24, 1.2);
              font-weight: 700;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 1);
            }
          }
          .custom__modal__heading {
            .custom__modal__img {
              margin-top: 75px;
            }
            .description.explore__desc {
              margin-top: 32px;
            }
          }
          .button {
            margin-top: 46px;
          }
        }
      }
    }
  }
}

/* Edit Workstation */
.edit_workstation_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: hidden;
        .custom__modal__body__content {
          padding: 24px;
          height: 100%;
          overflow: hidden;
          @include respond(sm) {
            padding: 20px;
          }
          .header {
            margin: 0;
            padding: 0 0 21px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              border: none;
            }
            h4 {
              @include fluidFont(20, 18, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              @include respond(sm) {
                font-weight: 700;
              }
            }
            .v-btn {
              height: unset;
              width: unset;
              box-shadow: none;
              :deep(.v-btn__content) {
                .v-icon {
                  @include fluidFont(15, 15, 1);
                  @include respond(sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .modal__content {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            padding-top: 21px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              flex-direction: column;
              align-items: center;
              border: none;
            }
            .uploade-wrapper {
              position: relative;
              width: 100%;
              height: 300px;
              margin: 0;
              z-index: 1;
              .uploaded-img {
                position: absolute;
                top: 5px;
                left: 0;
                border-radius: 100%;
                object-fit: cover;
                height: 100%;
                width: 100% !important;
                pointer-events: none;
                z-index: -1;
              }
            }
            .upload_file {
              width: 100%;
              height: 100%;
              display: block;
              position: relative;
              border-radius: 100%;
              cursor: pointer;
              margin: 0 auto 24px;
              .v-field__overlay,
              .v-field {
                border-radius: 0;
                background-color: rgba($blueDark, 0.15) !important;
                box-shadow: none;
                .v-field__overlay {
                  display: none;
                }
              }
              &.added-img {
                .v-field__overlay {
                  background: transparent;
                }
                .v-field {
                  background: transparent;
                }
                .v-field__input {
                  display: none;
                }
              }

              input {
                cursor: pointer;
              }
              .v-field__clearable {
                position: absolute;
                z-index: 1;
                left: 0;
                height: 100%;
                width: 100%;
                margin: 0;
                opacity: 1;
                overflow: unset;
                grid-area: unset;
                align-items: center;
                justify-content: center;
                background-color: rgba($black, 0.5);
                color: rgba($white, 1);
                border-radius: 0;
              }
              .v-input__prepend {
                display: none;
              }
              .v-input__control {
                position: absolute;
                inset: 0;
                border-radius: 100%;
              }
              .v-field--appended {
                padding: 0;
                position: relative;
              }
              .v-field__append-inner {
                all: initial;
                color: rgba($blueDark, 0.5);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }

              .v-field-label {
                left: 50%;
                transform: translateX(-50%) !important;
                margin: 28px 0 0 0;
                font-size: 16px;
                font-weight: 500;
                /* max-width: 130px;*/
                bottom: -30px;
                top: unset !important;
                @include fluidFont(16, 16, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 0.7);
                opacity: 1;
              }
            }
            .right__wrapper {
              width: calc(100% - 95px);
              max-width: 100%;
              @include respond(sm) {
                width: 100%;
              }
              .input__field__wrapper {
                margin-bottom: 24px;
                label {
                  @include fluidFont(16, 16, 1.2);
                  color: rgba($blueDark, 1);
                  font-weight: 600;
                  letter-spacing: 0.25px;
                  margin-bottom: 8px;
                  display: inline-block;
                }
                .c-input {
                  margin: 0;
                  .v-input__control {
                    .v-field {
                      .v-field__field {
                        input {
                          padding: 9px 22px;
                          border-radius: 30px;
                          min-height: 42px;
                        }
                      }
                    }
                  }
                }
                .address__postcodes {
                  height: calc(100dvh - 660px);
                  overflow: auto;
                  background-color: rgba($blueDark, 0.05);
                  padding: 20px 5px 0;
                  list-style-type: none;
                  border-radius: 12px;
                  margin-top: 10px;
                  .address__postcodes_list {
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 500;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.25px;
                    margin: 0 0 15px;
                    cursor: pointer;
                    .address__postcodes_list__item {
                      padding: 5px 20px;
                    }
                  }
                  .active {
                    background-color: rgba($orange, 1);
                    color: rgba($white, 1);
                    border-radius: 30px;
                  }
                }
              }
            }
          }
          .button {
            margin-top: 24px;
            @include respond(sm) {
              width: 100%;
              max-width: 100%;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

/* limited company insurance modal */

.insurance_details_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      .custom__modal__content {
        .custom__modal__body__content {
          .sole-trader-data-wrapper {
            .insurances-list {
              .insurances-list__innner {
                .insurances-list__item {
                  .insurances-list__item-action {
                    align-items: baseline;
                    gap: 4px;
                    .v-btn {
                      height: unset;
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Limited company tax vat modal */
.tax_vat__modal_box {
  :deep(.v-overlay__content) {
    margin: 0 !important;
    padding: 15px !important;
    .main {
      overflow: hidden;
      border-radius: 20px;
      .custom__modal__content {
        height: 100%;
        .custom__modal__body__content {
          height: 100%;
          .v-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 250px);
            .sole-trader-data-wrapper {
              height: unset;
            }
          }
        }
      }
    }
  }
}

/* Experience Modal */
.experience_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .form_wrapper {
              margin-top: 50px;
              padding-bottom: 50px;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              label {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                margin-bottom: 4px;
                display: inline-block;
              }
              .v-input {
                .v-input__control {
                  .v-field {
                    .v-field__field {
                      background-color: transparent;
                      border-radius: 8px;
                      border: 1px solid rgba($buttonText, 0.4);
                      position: relative;
                      .v-field__input {
                        background-color: transparent;
                        &::placeholder {
                          color: rgba($blueDark, 1);
                        }
                      }
                      .v-text-field__suffix {
                        position: absolute;
                        right: 30px;
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }
            .button {
              width: 100%;
              max-width: 100%;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}

/* Hourly Rate Modal */
.hourly_rate_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 24px;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              .form_wrapper {
                padding-bottom: 24px;
                .form_wrapper__title {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  position: relative;
                  label {
                    @include fluidFont(12, 12, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    margin-bottom: 4px;
                    display: inline-block;
                  }
                  .icon__wrapper {
                    img {
                      width: 16px;
                      height: 16px;
                    }
                    &:hover {
                      cursor: pointer;
                      z-index: 1;
                      .form_wrapper__tooltip,
                      .form_wrapper__tooltip_emergency {
                        display: block;
                      }
                    }
                    .form_wrapper__tooltip {
                      display: none;
                      background-color: rgba($blueDark, 1);
                      color: rgba($white, 1);
                      @include fluidFont(10, 10, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.25px;
                      border-radius: 4px;
                      padding: 8px;
                      position: absolute;
                      left: 180px;
                      top: 30px;
                      &::before {
                        content: "";
                        width: 30px;
                        height: 15px;
                        background-image: url("../../assets/icons/blue-triangle.svg");
                        right: 85px;
                        position: absolute;
                        top: -10px;
                      }
                    }
                    .form_wrapper__tooltip_emergency {
                      display: none;
                      background-color: rgba($blueDark, 1);
                      color: rgba($white, 1);
                      @include fluidFont(10, 10, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.25px;
                      border-radius: 4px;
                      padding: 8px;
                      position: absolute;
                      left: 124px;
                      top: 30px;
                      &::before {
                        content: "";
                        width: 30px;
                        height: 15px;
                        background-image: url("../../assets/icons/blue-triangle.svg");
                        right: 85px;
                        position: absolute;
                        top: -10px;
                      }
                    }
                  }
                }
                .input_field {
                  .v-input__control {
                    .v-field {
                      padding: 0;
                      border-radius: 8px;
                      border: 1px solid rgba($buttonText, 0.4);
                      background-color: transparent;
                      .v-field__field {
                        .v-field__input {
                          column-gap: 7px;
                          padding: 8px 16px;
                          min-height: auto;
                          @include fluidFont(12, 12, 1.3);
                          font-weight: 500;
                          color: rgba($blueDark, 1);
                          &::placeholder {
                            color: rgba($blueDark, 1);
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                          }
                        }
                      }
                      .v-field__outline {
                        display: none;
                      }
                    }
                    .v-selection-control-group {
                      .v-selection-control.v-selection-control--dirty {
                        .v-selection-control__wrapper {
                          .v-selection-control__input {
                            .v-icon {
                              color: rgba($SecondaryBrightBlue, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .button {
              width: 100%;
              max-width: 100%;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}

/* Manage Connections Modal */
.manage_connections_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .connection__details {
              background-color: rgba($cardBorder, 1);
              border-radius: 8px;
              margin-top: 15px;
              .v-list {
                border: 1px solid rgba($PrimaryBlueLight, 0.5);
                background-color: rgba($cardBorder, 1);
                padding: 0 10px;
                border-radius: 8px;
                .v-list-item {
                  padding: 0;
                  min-height: 68px;
                  .v-list-item__prepend {
                    .v-avatar {
                      width: 30px;
                      height: 30px;
                      margin: 0;
                    }
                  }
                  .v-list-item__content {
                    align-items: start;
                    display: flex;
                    flex-direction: column;
                    .v-list-item-title {
                      @include fluidFont(16, 16, 1.2);
                      font-weight: 500;
                      color: rgba($blueDark, 1);
                    }
                    .v-list-item-subtitle {
                      @include fluidFont(14, 14, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 0.5);
                      letter-spacing: 0.25px;
                    }
                  }
                  .v-list-item__append {
                    .manage__box {
                      border: 1px solid $orange;
                      gap: 4px;
                      border-radius: 4px;
                      padding: 2px 8px;
                      display: inline-flex;
                      align-items: center;
                      color: rgba($orange, 1);
                      font-weight: $fontSemiBold;
                      letter-spacing: 0.33px;
                      line-height: $line16;
                      @include fluidFont(10, 10, 1);
                      .v-icon {
                        color: rgba($orange, 1);
                        @include fluidFont(12, 12, 1);
                      }
                    }
                  }
                }
                :deep(&:last-child) {
                  border: none;
                }
              }
            }
            .search_field {
              margin-top: 10px;
              .v-input__control {
                .v-field {
                  border-radius: 8px;
                  padding: 0 16px;
                  gap: 8px;
                  border: 1px solid rgba($buttonText, 0.1);
                  .v-field__field {
                    .v-field__input {
                      padding: 0;
                      min-height: 40px;
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                    ::placeholder {
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .v-field__prepend-inner {
                    padding-top: 0;
                    align-items: center;
                  }
                }
              }
            }
            .modal_form {
              margin-top: 24px;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              .form_wrapper {
                padding-bottom: 24px;
                .list_block__item {
                  .v-list {
                    padding: 0;
                    .v-list-item {
                      background-color: rgba($cardBorder, 1);
                      border-radius: 8px !important;
                      border: 1px solid rgba($PrimaryBlueLight, 0.5);
                      margin: 5px 0;
                      padding: 0 16px;
                      min-height: 60px;
                      .v-list-item__prepend {
                        .v-avatar {
                          width: 30px;
                          height: 30px;
                          margin: 0;
                        }
                      }
                      .v-list-item__content {
                        align-items: start;
                        display: flex;
                        flex-direction: column;
                        .v-list-item-title {
                          @include fluidFont(16, 16, 1.2);
                          font-weight: 600;
                          color: rgba($blueDark, 1);
                        }
                      }
                      .v-list-item__append {
                        .remove_user__box {
                          border: 1px solid $PrimaryRed;
                          gap: 4px;
                          border-radius: 4px;
                          padding: 4px 8px;
                          display: inline-flex;
                          align-items: center;
                          color: rgba($PrimaryRed, 1);
                          font-weight: $fontMedium;
                          letter-spacing: 0.33px;
                          line-height: $line16;
                          @include fluidFont(10, 10, 1);
                          height: 20px;
                          text-transform: uppercase;
                          cursor: pointer;
                        }
                      }
                    }
                    :deep(&:last-child) {
                      border: none;
                    }
                  }
                  .button {
                    width: 100%;
                    max-width: 100%;
                    :deep(.v-btn__content) {
                      letter-spacing: 0.15px;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
            .modal_form_footer {
              display: flex;
              .button {
                width: 50%;
                max-width: 50%;
                margin-top: 24px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

/* Remove User Connection Modal*/
.remove_user_connections_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          width: 100%;
          text-align: center;
          .delete__icon {
            color: rgba($errorRed, 1);
            box-shadow: none;
          }
          .modal_content {
            .modal_content__title {
              margin-top: 20px;
              @include fluidFont(24, 24, 1.2);
              font-weight: $fontSemiBold;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              line-height: $line24;
            }
            .modal_content__desc {
              margin-top: 12px;
              @include fluidFont(16, 16, 1.2);
              font-weight: $fontMedium;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.15px;
              line-height: $line24;
              width: 255px;
            }
          }
          .modal_form_footer {
            display: flex;
            margin-top: 25px;
            .button {
              width: 50%;
              max-width: 50%;
              margin-top: 24px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

/* TradePassport description card */
.des_editor_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px #0000000d;
      .custom__modal__content {
        padding: 24px;
      }
    }
  }
}

/* Create Trade WS Modal */
.create__trade__modal__box {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px #0000000d;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          .create__trade__modal__setting {
            .create__trade__modal__setting__row {
              flex-direction: column;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .create__trade__modal__setting__left {
                width: 100%;
                max-width: 100%;
                .bottom-btn {
                  margin-top: 50px;
                  .button {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Chat setting modal */

.chat__setting__modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 12px;
      box-shadow: 0px 0px 10px 0px #0000000d;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .header {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 8px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            h4 {
              @include fluidFont(24, 24, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
            }
            .v-icon {
              cursor: pointer;
            }
          }
          .group__members {
            padding-top: 16px;
            span {
              @include fluidFont(18, 18, 1.2);
              font-weight: 600;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
            }
            .group__contact__members {
              margin-top: 18px;
              .group__add__members {
                display: flex;
                align-items: center;
                gap: 8px;
                .v-icon {
                  color: rgba($blueDark, 1);
                  @include fluidFont(28, 28, 1.2);
                }
                p {
                  color: rgba($blueDark, 1);
                  @include fluidFont(18, 18, 1.2);
                  font-weight: 700;
                }
              }
              .group_members {
                margin: 10px 0;
                .group__contact_details {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  .group__members__img {
                    border-radius: 100%;
                    overflow: hidden;
                    width: 30px;
                    height: 30px;
                    max-width: 100%;
                    cursor: pointer;
                    img {
                      object-fit: conver;
                      width: 100%;
                      height: 100%;
                    }
                  }
                  p {
                    color: rgba($blueDark, 1);
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .group__new__members {
            .group_selector {
              .v-list {
                .v-list-item {
                  padding: 0;
                  .v-list-item__content {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .group_img {
                      border-radius: 50%;
                      overflow: hidden;
                      width: 36px;
                      height: 36px;
                    }
                    .v-list-item-action {
                      width: calc(100% - 56px);
                      .v-input {
                        width: 100%;
                        max-width: 100%;
                        .v-input__control {
                          width: 100%;
                          max-width: 100%;
                          .v-selection-control {
                            width: 100%;
                            max-width: 100%;
                            flex-direction: row-reverse;
                            .v-label {
                              width: 100%;
                              display: block;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/* Invitees card edit*/

.edit_invite_modal {
  .v-overlay__content {
    .main {
      border-radius: 8px;
      height: auto;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          :deep(.edit_invitees) {
            box-shadow: none;
            .card_title {
              position: relative;
              display: flex;
              gap: 8px;
              align-items: flex-start;
              justify-content: flex-start;
              padding-bottom: 16px;
              border-bottom: 1px solid rgba($black, 0.1);
              .card_profile_img {
                width: 36px;
                max-width: 100%;
                border-radius: 100%;
                overflow: hidden;
                height: 36px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .card_profile_text {
                text-align: left;
                h4 {
                  @include fluidFont(16, 16, 1.2);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                }
                p {
                  @include fluidFont(12, 12, 1.4);
                  font-weight: 700;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
              }
              .close_icon {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
              }
            }
            .invitation_status {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 16px;
              @include respond(sm) {
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
              }
              p {
                @include fluidFont(14, 14, 1.4);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
              .accepted {
                background-color: rgba($success, 1);
                padding: 2px 8px;
                border-radius: 6px;
                display: inline-flex;
                gap: 4px;
                align-items: center;
                @include fluidFont(12, 12, 1.2);
                font-weight: 700;
                color: rgba($white, 1);
                letter-spacing: 0.15px;
              }
              .rejected {
                background-color: rgba($errorRed, 1);
                padding: 2px 8px;
                border-radius: 6px;
                display: inline-flex;
                gap: 4px;
                align-items: center;
                @include fluidFont(12, 12, 1.2);
                font-weight: 700;
                color: rgba($white, 1);
                letter-spacing: 0.15px;
              }
              .pending {
                background-color: rgba($orange, 1);
                padding: 2px 8px;
                border-radius: 6px;
                display: inline-flex;
                gap: 4px;
                align-items: center;
                @include fluidFont(12, 12, 1.2);
                font-weight: 700;
                color: rgba($white, 1);
                letter-spacing: 0.15px;
              }
            }
            .permission_wrapper {
              margin-top: 16px;
              padding-bottom: 16px;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              p {
                @include fluidFont(14, 14, 1.4);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
                img {
                  vertical-align: middle;
                }
              }
              .c-input {
                margin: 8px 0 0;
                width: 100%;
                max-width: 100%;
                .v-input__control {
                  .v-field {
                    padding: 0 16px;
                    border: 1px solid rgba($buttonText, 0.4);
                    border-radius: 8px;
                    gap: 8px;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        min-height: 42px;
                        padding: 0;
                        input {
                          align-self: center;
                          color: rgba($blueDark, 1);
                        }
                      }
                      .v-text-field__suffix {
                        padding: 0;
                        min-height: 42px;
                      }
                    }
                  }
                }
              }
            }
            .button {
              margin-top: 24px;
              margin-left: auto;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* Invitees card delete*/
.delete_invite_modal {
  .v-overlay__content {
    .main {
      border-radius: 8px;
      height: auto;
      .custom__modal__content {
        padding: 53px 24px 30px;
        height: auto;
        :deep(.delete_invitees) {
          box-shadow: none;
          text-align: center;
          .delete_icon {
            img {
              width: 54px;
              height: 54px;
              filter: invert(50%) sepia(12%) saturate(3730%) hue-rotate(317deg)
                brightness(97%) contrast(97%);
            }
          }
          .content {
            margin-top: 40px;
            h4 {
              @include fluidFont(24, 24, 1.2);
              font-weight: 700;
              color: rgba($blueDark, 1);
            }
            p {
              @include fluidFont(16, 16, 1.2);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
              margin-top: 36px;
              letter-spacing: 0.15px;
              max-width: 90%;
              span {
                color: rgba($blueDark, 1);
              }
            }
            .button {
              margin-top: 66px;
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

/* Add and remove property */
.add_property_modal_box {
  .v-overlay__content {
    .main {
      border-radius: 20px;
      overflow: unset !important;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          .custom__modal__heading {
            .title {
              @include fluidFont(18, 18, 1.3);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
            }
          }
          :deep(.sole-trader-data-wrapper) {
            margin-top: 24px;
            .form__wrapper {
              .v-form {
                .modal_input_wrapper {
                  display: flex;
                  gap: 12px;
                  align-items: center;
                  .modal_input {
                    width: 100%;
                    max-width: 100%;
                    .input_field {
                      margin: 0;
                      .v-input__control {
                        .v-field {
                          border: 1px solid rgba($blueDark, 0.2);
                          border-radius: 8px;
                          min-height: 44px;
                          max-height: 44px;
                          overflow: auto;

                          .v-field__field {
                            .v-field__input {
                              background-color: transparent;
                              border: 0;
                              border-radius: 0;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              letter-spacing: 0.4px;
                              min-height: 42px;
                              padding: 0 16px;
                              top: 0;
                              gap: 0;
                              input {
                                top: 50%;
                                transform: translateY(-50%);
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
:deep(.v-overlay__content.v-select__content) {
  top: 540px !important;
}

/* Personal calendar edit modal and add modal */
.update_event_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(18, 18, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
              .v-btn {
                width: 17px;
                height: 17px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(17, 17, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 24px;
              .form_wrapper {
                .list_block__item {
                  .event_field {
                    margin-bottom: 16px;
                    label {
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      margin-bottom: 8px;
                      display: inline-block;
                      white-space: nowrap;
                    }
                    .input_field {
                      margin: 0;
                      .v-input__control {
                        border: 1px solid rgba($buttonText, 0.4);
                        border-radius: 8px;
                        .v-field {
                          padding: 0;
                          .v-field__field {
                            input {
                              padding: 12px 16px;
                              background-color: transparent;
                              min-height: auto;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                            }
                            .v-label {
                              display: none;
                            }
                            .v-field__input {
                              .v-select__selection {
                                padding: 2px 4px;
                                background-color: #f8f8f8;
                                border-radius: 10px;
                              }
                              input {
                                padding: 12px 16px;
                                background-color: transparent;
                                min-height: auto;
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }
                              &::placeholder {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  .date_time_selector {
                    margin-bottom: 16px;
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    @include respond(s720) {
                      flex-direction: column;
                    }
                    .date_selector {
                      width: 40% !important;
                      max-width: 100%;

                      @include respond(s720) {
                        width: 100% !important;
                      }
                    }
                    .event_field {
                      margin: 0;
                      width: 60%;
                      max-width: 100%;
                      @include respond(s720) {
                        width: 100% !important;
                      }
                      .event_field {
                        display: flex;
                        align-items: center;
                        .v-input {
                          width: 50%;
                          margin: 0;
                          .v-input__control {
                            .v-field {
                              padding: 8px 10px;
                              border: 1px solid rgba($buttonText, 0.4);
                              border-radius: 8px;
                              align-items: center;
                              .v-field__field {
                                .v-label {
                                  display: none;
                                }
                                .v-field__input {
                                  padding: 0;
                                  min-height: auto;
                                  color: rgba($blueDark, 1);
                                  opacity: 1;
                                  .v-select__selection {
                                    padding: 0;
                                    background-color: transparent;
                                    .v-select__selection-text {
                                      @include fluidFont(12, 12, 1.2);
                                      font-weight: 400;
                                      color: rgba($blueDark, 1);
                                      text-transform: lowercase;
                                    }
                                  }
                                  img {
                                    width: 16px;
                                  }
                                  input {
                                    padding: 0;
                                    background-color: transparent;
                                    border: none;
                                    min-height: auto;
                                  }
                                  &::placeholder {
                                    @include fluidFont(12, 12, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                  }
                                }
                              }
                            }
                            .v-field__outline {
                              display: none;
                            }
                          }
                        }
                      }

                      .calendar__select {
                        border: none;
                        .v-input {
                          margin: 0;
                          .v-input__control {
                            .v-field {
                              .v-field__field {
                                .v-label {
                                  display: none;
                                }
                                .v-field__input {
                                  padding: 12px 10px;
                                  min-height: auto;
                                  flex-direction: row-reverse;
                                  border-radius: 8px;
                                  color: rgba($blueDark, 1);
                                  opacity: 1;
                                  img {
                                    width: 16px;
                                  }
                                  input {
                                    padding: 0;
                                    background-color: transparent;
                                    border: none;
                                    min-height: auto;
                                  }
                                  &::placeholder {
                                    @include fluidFont(12, 12, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                  }
                                }
                              }
                            }
                            .v-field__outline {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                    .time_selector {
                      width: 100%;
                      max-width: 100%;
                      .time_wrapper {
                        display: flex;
                        gap: 4px;
                        align-items: flex-start;
                        width: 100%;
                        max-width: 100%;
                        .time_dropdown {
                          width: 50%;
                          max-width: 100%;
                          position: relative;
                          .v-input {
                            margin: 0;
                            .v-input__control {
                              .v-field {
                                .v-field__field {
                                  .v-label {
                                    display: none;
                                  }
                                  .v-field__input {
                                    padding: 12px 10px;
                                    min-height: auto;
                                    flex-direction: row-reverse;
                                    border-radius: 8px;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                    img {
                                      width: 16px;
                                    }
                                    .v-icon {
                                      @include fluidFont(16, 16, 1);
                                      color: rgba($buttonText, 1);
                                    }
                                    input {
                                      padding: 0;
                                      background-color: transparent;
                                      border: none;
                                      min-height: auto;
                                    }
                                    &::placeholder {
                                      @include fluidFont(12, 12, 1.2);
                                      font-weight: 400;
                                      color: rgba($blueDark, 1);
                                      opacity: 1;
                                    }
                                  }
                                }
                              }
                              .v-field__outline {
                                display: none;
                              }
                            }
                          }
                          .custom_dropdown__menu {
                            position: absolute;
                            background-color: rgba($white, 1);
                            width: 100%;
                            border-radius: 6px;
                            padding: 12px;
                            box-shadow: 0px 0px 8px 0px #0c0f4a14;
                            max-height: 190px;
                            overflow: auto;
                            top: 50px;
                            z-index: 1;
                          }
                        }
                        .time__divider {
                          margin-top: 10px;
                        }
                        &_item {
                          position: relative;
                          &_placeholder {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            @include fluidFont(12, 12, 1);
                            color: rgba(12, 15, 74, 0.5);
                            opacity: 1;
                            padding: 12px 10px;
                            pointer-events: none;
                            white-space: nowrap;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            max-width: 100%;
                          }
                        }

                        .select_field {
                          margin: 0;
                          border: 1px solid rgba($buttonText, 0.4);
                          border-radius: 8px;
                          padding: 12px 10px;
                          height: 42px;
                          @include fluidFont(12, 12, 1);

                          @include respond(s720) {
                            width: 100%;
                          }

                          &::placeholder {
                            color: red;
                          }
                        }
                      }
                    }
                  }
                  .desc__field {
                    margin-bottom: 16px;
                    label {
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      display: inline-block;
                      margin-bottom: 8px;
                    }
                    .v-text-field {
                      .v-input__control {
                        .v-field {
                          .v-field__field {
                            .v-field__input {
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .modal_form_footer {
                display: flex;
                gap: 16px;
                margin-top: 40px;
                .button {
                  width: calc(50% - 8px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

:deep(input).v-field__input::placeholder {
  display: none !important;
}

/* Personal calendar delete modal*/

.delete_event_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 10px 0px #2a2a2a05;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              text-align: right;
              .v-btn {
                width: 17px;
                height: 17px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(17, 17, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
              &:hover {
                opacity: 1;
              }
            }
            .form_wrapper {
              .modal_form {
                text-align: center;
                .v-btn {
                  height: 56px;
                  width: 56px;
                  padding: 0;
                }
                h6 {
                  margin-top: 24px;
                  @include fluidFont(24, 24, 1);
                  font-weight: 600;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                }
                p {
                  max-width: 80%;
                  margin: 18px auto 0;
                  @include fluidFont(14, 14, 1);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                }
              }
            }
            .modal_form_footer {
              display: flex;
              gap: 16px;
              margin-top: 40px;
              .button {
                width: calc(50% - 8px);
                max-width: 100%;
                .v-btn__content {
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}

.enrollment__wrapper {
  :deep(.v-overlay__content) {
    margin: 0 !important;
    .main {
      // border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 28px 80px;
          height: calc(100dvh - 240px);
          overflow: auto;
          @include respond(s720) {
            padding: 30px 16px;
          }
        }
      }
    }
  }
}

/* Team Member List Dialog */
.team_member_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(sm) {
            padding: 20px;
          }
          .header {
            margin: 0;
            padding: 0 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              border: none;
            }
            h4 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              @include respond(sm) {
                font-weight: 700;
              }
            }
            .v-btn {
              height: unset;
              width: unset;
              box-shadow: none;
              :deep(.v-btn__content) {
                .v-icon {
                  @include fluidFont(15, 15, 1);
                  @include respond(sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(md) {
              flex-direction: column;
              align-items: flex-start;
              border: none;
            }
            .member-list-card {
              &__list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-bottom: 20px;
              }
              &__item {
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
                padding: 15px 0;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 100%;
                .member-list-card__item_info {
                  display: flex;
                  gap: 12px;
                  align-items: center;
                  @include respond(md) {
                    width: 90%;
                    max-width: 100%;
                  }
                  .img-wrap {
                    width: 48px;
                    height: 48px;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 100%;
                    }
                  }
                  .profile_info_wrapper {
                    max-width: calc(100% - 50px);
                    .text-wrap {
                      .member-list-first-row {
                        display: flex;
                        gap: 6px;
                        align-items: center;
                        @include respond(md) {
                          flex-direction: column;
                          align-items: flex-start;
                        }
                        .member-list-info {
                          .member-list-name {
                            font-weight: 700;
                            @include fluidFont(14, 14, 1.3);
                            color: rgba($blueDark, 1);
                          }
                        }
                      }
                      p {
                        color: rgba($blueDark, 0.5);
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15.06px;
                        margin-top: 3px;
                      }
                      span {
                        @include fluidFont(10, 10, 1);
                        color: rgba($blueDark, 0.7);
                        .v-icon {
                          @include fluidFont(12, 12, 1);
                        }
                      }
                    }
                  }
                }
                .action-btn-wrap {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  .v-btn--icon.v-btn--density-default {
                    height: 18px;
                    width: 18px;
                  }
                }
              }
            }
            .modal__content__empty_state {
              padding: 10px 0;
            }
            .member-loader {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 10px auto;
            }
          }
          .modal__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
:deep(.custom_tags) {
  display: flex;
  gap: 6px;
  align-items: center;
  .member-list-role {
    background-color: rgba($blueDark, 0.1);
    border-radius: 4px;
    padding: 2px 8px;
    text-transform: capitalize;
    @include fluidFont(12, 12, 1.2);
    font-weight: 400;
    color: rgba($blueDark, 1);
  }
  .member-list-tags {
    .member-list-tag {
      background-color: rgba($orange, 0.2);
      border-radius: 4px;
      padding: 2px 8px;
      color: rgba($orange, 1);
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($orange, 1);
    }
  }
}

:deep(.desktop_wrapper) {
  @include respond(md) {
    display: none;
  }
}
:deep(.mobile_wrapper) {
  display: none;
  @include respond(md) {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
:deep(.custom_tags.mobile_wrapper) {
  margin-top: 12px;
}

/* Add Team Member Dialog */
.add_team_member_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(s720) {
            padding: 24px 16px;
          }
          .header {
            margin: 0;
            padding: 0 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include respond(sm) {
              border: none;
            }
            h4 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              @include respond(sm) {
                font-weight: 700;
              }
            }
            .v-btn {
              height: unset;
              width: unset;
              box-shadow: none;
              :deep(.v-btn__content) {
                .v-icon {
                  @include fluidFont(15, 15, 1);
                  @include respond(sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              flex-direction: column;
              align-items: center;
              border: none;
            }
            .modal__content_header_note {
              background-color: rgba($SecondaryBrightBlue, 0.1);
              padding: 16px;
              border-radius: 4px;
              gap: 10px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.25px;
              span {
                color: rgba($SecondaryBrightBlue, 1);
                text-decoration: underline;
                cursor: pointer;
              }
            }
            .modal__inner__content {
              .v-form {
                display: flex;
                gap: 16px;
                flex-direction: column;
                .invite_members_form {
                  display: flex;
                  gap: 16px;
                  align-items: flex-start;
                  @include respond(s720) {
                    flex-wrap: wrap;
                    padding: 16px 10px;
                    border: 1px solid rgba($buttonText, 0.4);
                    border-radius: 8px;
                  }
                  .email_address {
                    width: 40%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                      order: 2;
                    }
                  }
                  .invite_wrapper {
                    width: 20%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: calc(50% - 8px);
                      order: 3;
                    }
                  }
                  .tags_wrapper {
                    width: 40%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                      order: 3;
                    }
                  }
                  .input_field {
                    label {
                      @include fluidFont(12, 12, 2);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.15px;
                    }
                    .v-input {
                      margin: 0;
                      .v-input__control {
                        .v-field {
                          background-color: transparent;
                          border: 1px solid rgba($blueDark, 0.2);
                          border-radius: 8px;
                          background-color: transparent;
                          .v-field__field {
                            .v-field__input {
                              background-color: transparent;
                              border: 0;
                              border-radius: 0;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              letter-spacing: 0.4px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .remove_btn {
                    width: 34px;
                    height: 34px;
                    background-color: #f1f6fb;
                    border-radius: 100%;
                    color: rgba($blueDark, 1);
                    top: 30px;
                    @include respond(s720) {
                      order: 1;
                      margin-left: auto;
                    }
                  }
                }
                .add_btn {
                  max-width: 100px;
                  margin-top: 12px;
                }
                .message_wrapper {
                  margin-top: 20px;
                  padding-bottom: 28px;
                  label {
                    @include fluidFont(12, 12, 2);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                  }
                  .v-input {
                    margin: 0;
                    .v-input__control {
                      .v-field {
                        border: 1px solid rgba($blueDark, 0.2);
                        border-radius: 8px;
                        .v-field__field {
                          .v-field__input {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .modal__footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
            gap: 16px;
            @include respond(s720) {
              flex-direction: column-reverse;
            }
            .button-orange {
              min-width: 255px;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
              }
            }
            .button {
              @include respond(s720) {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* invite sent Team Member Dialog */
.invite_sent_member_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        max-height: 100%;
        overflow: hidden;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(md) {
            padding: 20px;
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            .modal__content_img {
              background-color: rgba(#f3f3f3, 1);
              width: 100%;
              border-radius: 8px;
              gap: 10px;
              display: flex;
              justify-content: center;
              img {
                margin: 10px auto;
              }
            }
          }
          .modal__content_text {
            h4 {
              @include fluidFont(14, 14, 1.2);
              font-weight: 700;
              color: rgba($blueDark, 1);
              letter-spacing: 0.25px;
            }
            p {
              margin-top: 8px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
            }
            .invite_mail {
              margin-top: 24px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.25px;
            }
          }
          .modal__footer {
            display: flex;
            justify-content: end;
            .button {
              margin-top: 24px;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
/* Remove Team Member Dialog */
.remove_team_member_dialog {
  :deep(.v-overlay__content) {
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 40px 16px 20px;
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 26px;
            .modal__content_img {
              margin: 0 auto;
              width: 50px;
              height: 50px;
              padding: 9px 13px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: invert(59%) sepia(100%) saturate(4684%)
                  hue-rotate(334deg) brightness(101%) contrast(89%);
              }
            }
            .modal__content_header {
              text-align: center;
              h2 {
                @include fluidFont(24, 24, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 1);
                max-width: 60%;
                margin: 0 auto;
                @include respond(md) {
                  max-width: 65%;
                }
              }
              p {
                margin-top: 24px;
                @include fluidFont(16, 16, 1.3);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                span {
                  color: rgba($blueDark, 1);
                }
              }
            }
          }
          .modal__footer {
            width: 100%;
            max-width: 100%;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
/* Edit Team Member Dialog*/
.edit_team_member_dialog {
  :deep(.v-overlay__content) {
    margin: 0 !important;
    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(md) {
            padding: 16px;
          }
          .header {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              letter-spacing: 0.15px;
              color: rgba($blueDark, 1);
            }
            .v-btn {
              width: auto;
              height: auto;
              .v-btn__content {
                @include fluidFont(10, 10, 1.2);
                color: rgba($blueDark, 1);
              }
            }
          }
          .modal__content {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            .v-form {
              display: flex;
              flex-direction: column;
              gap: 24px;

              .input_field {
                label {
                  @include fluidFont(12, 12, 2);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                  display: inline-block;
                  margin-bottom: 8px;
                }
                .v-input {
                  margin: 0;
                  .v-input__control {
                    .v-field {
                      border: 1px solid rgba($blueDark, 0.2);
                      border-radius: 8px;
                      padding: 0;
                      background-color: transparent;
                      .v-field__field {
                        .v-field__input {
                          background-color: transparent;
                          border-radius: 0;
                          @include fluidFont(12, 12, 2);
                          font-weight: 500;
                          letter-spacing: 0.15px;
                          color: rgba($blueDark, 1);
                          padding: 8px 16px;
                          min-height: 40px;
                          .v-select__selection {
                            .v-select__selection-text {
                              @include fluidFont(12, 12, 2);
                              font-weight: 500;
                              letter-spacing: 0.15px;
                              color: rgba($blueDark, 1);
                            }
                          }
                        }
                      }
                      .v-field__outline {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .modal__footer {
            width: 100%;
            max-width: 100%;
            margin-top: 24px;
            .button {
              margin-left: auto;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
              }
              .v-btn__content {
                letter-spacing: 1.25px;
              }
            }
          }
        }
      }
    }
  }
}

/* Subscription Plans Modal */

.manage_subscription_modal {
  :deep(.v-overlay__content) {
    margin: 0 !important;
    .main {
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 48px 72px;
          height: calc(100dvh - 290px);
          overflow: auto;
          @include respond(md) {
            padding: 16px;
          }
          .manage_subscription__card {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
            margin-top: 40px;
            display: flex;
            gap: 1.5rem;
            @include respond(s720) {
              flex-direction: column;
            }

            &-item {
              box-shadow: 0px 0px 8px 0px #0c0f4a14;
              border-radius: 16px;
              padding: 20px 16px;
              width: 50%;
              max-width: 100%;
              @include respond(s720) {
                width: 100%;
              }
              &.selected {
                box-shadow: 0 0 0 2px rgba($orange, 1);
              }
              // @include respond(sm) {
              //   padding: 16px;
              //   border-radius: 8px;
              // }
            }
            &-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .manage_subscription__card-tag {
                display: inline-block;
                padding: 4px 8px;
                @include fluidFont(14, 14, 1);
                font-weight: 600;
                border: 1px solid rgba($orange, 1);
                border-radius: 0.25rem;
                font-weight: 700;
                color: rgba($blueDark, 1);
              }
              span {
                @include fluidFont(14, 14, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
            }

            &-details {
              margin-top: 20px;
              // display: flex;
              &__title {
                display: flex;
                align-items: center;
                span {
                  @include fluidFont(28, 28, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                }
                p {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  margin-top: 3px;
                }
              }
              &__icon {
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background-color: rgba($blueDark, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba($white, 1);
              }
              &__text {
                margin-top: 40px;
                .check-list {
                  gap: 18px;
                  display: flex;
                  flex-direction: column;
                  list-style: none;
                  @include respond(md) {
                    margin-bottom: 1.5rem;
                  }
                  &__item {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba($blueDark, 1);
                    position: relative;
                    padding-left: 32px;
                    line-height: 1.5rem;

                    &::after {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 1.5rem;
                      height: 1.5rem;
                      border-radius: 100%;
                      background-color: $skyBlue;
                    }
                    &::before {
                      content: "";
                      position: absolute;
                      z-index: 1;
                      left: 7px;
                      top: 8px;
                      width: 10px;
                      height: 6px;
                      border-right: 0;
                      border-top: 0;
                      border-bottom: 2px solid white;
                      border-left: 2px solid white;
                      transform: rotate(-48deg);
                    }
                  }
                }
              }
              .bottom-btn {
                margin-top: 40px;
                .v-btn {
                  width: 100%;
                  max-width: 100%;
                }
              }
            }
          }
          .description {
            margin-top: 30px;
            @include fluidFont(12, 12, 1.6);
            font-weight: 500;
            color: rgba(13, 9, 34, 0.5);
          }
        }
      }
    }
  }
}

/* Onboarding Dialog Box (Property Owner)*/

.onboarding__dialog {
  :deep(.v-overlay__content) {
    .main {
      .custom__modal__content {
        padding: 48px 80px 32px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        @include respond(s720) {
          padding: 24px 16px;
        }
        .custom__modal__body__content {
          .custom__modal__heading {
            max-width: 685px;
            width: 100%;
          }
          .modal__carousel__content {
            max-width: 400px;
            height: calc(100dvh - 450px);
            .onboarding-steps-content__upload {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: space-between;
              .next_step_btns {
                // .v-btn--disabled {
                //   background-color: rgba($blueDark, 0.04);
                //   .v-btn__content {
                //     color: rgba($blueDark, 0.5);
                //   }
                // }
              }
            }
          }
          .modal__inner__body {
            width: 343px;
            max-width: 100%;
            margin-top: 30px;
            .v-form {
              .contact__wrapper {
                display: flex;
                flex-direction: column;
                height: calc(100dvh - 450px);
                justify-content: space-between;
                .contact_form {
                  .contact_input {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    max-width: 100%;
                    background-color: rgba($blueDark, 0.04);
                    border-radius: 40px;
                    min-height: 42px;
                    padding: 0 17px;
                    height: 42px;

                    .contact_input_code {
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      padding: 0 0 0 4px;
                      height: 42px;
                      color: $blueDark;
                    }

                    .flag-selector {
                      display: flex;
                      width: 48px;
                      min-width: 48px;
                      max-width: 48px;
                      .v-input__control {
                        .v-field {
                          padding: 0;
                          .v-field__field {
                            .v-field__input {
                              .v-autocomplete__selection {
                                span {
                                  font-size: 100px !important;
                                  line-height: 1 !important;
                                  height: 14px !important;
                                  background-position: center center;
                                  background-size: cover;
                                  border-radius: 3px;
                                }
                              }
                            }
                          }

                          .mdi-menu-down {
                            font-size: 20px;
                            opacity: 1;

                            &::before {
                              content: "\F0140";
                              color: $blueDark;
                            }
                          }
                        }
                      }
                    }
                    .c-input {
                      width: 100%;
                      margin: 0;
                      .v-input__control {
                        width: 100%;
                        max-width: 100%;
                        .v-field {
                          .v-field__field {
                            .v-field__input {
                              background-color: transparent;
                              padding: 0 10px;
                              font-size: 12px;
                              line-height: 1;
                              color: $blueDark;
                            }
                          }
                        }
                        .v-field__overlay {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Property Owner workstation dialog */
.propertyowner_workstation_modal {
  :deep(.v-overlay__content) {
    .main {
      .custom__modal__content {
        padding: 48px 80px 32px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        @include respond(s720) {
          padding: 24px 16px;
        }
        .custom__modal__body__content {
          overflow: auto;

          .custom__modal__heading {
            max-width: 685px;
            width: 100%;
          }
          .modal__carousel__content {
            max-width: 400px;
            margin-top: 36px;
            height: calc(100dvh - 500px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include respond(md) {
              //height: calc(100dvh - 500px);
              margin-top: 16px;
            }
            .onboarding-steps-content__upload {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              .next_step_btns {
                .skip__wrapper {
                  text-align: center;
                  margin-top: 8px;
                  span {
                    @include fluidFont(14, 14, 1.3);
                    color: rgba($blueDark, 0.5);
                    letter-spacing: 0.25px;
                  }
                  .skip__btn {
                    height: auto;
                    padding: 0 8px;
                    opacity: 1;
                    .v-btn__content {
                      color: rgba($orange, 1);
                      text-transform: capitalize;
                      font-weight: 500;
                    }
                    .v-btn__append {
                      color: rgba($orange, 1);
                    }
                  }
                }
              }
            }
            .custom__modal__sub__content {
              label {
                @include fluidFont(16, 16, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
                display: inline-block;
                margin-bottom: 8px;
              }
              .v-form {
                .v-input {
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Upgrade Premium Plan Modal */
.upgrade_premium_plan_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .sub-title {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
              padding: 8px 0 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            }
            .modal_form {
              display: flex;
              justify-content: space-between;
              padding: 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              @include respond(s720) {
                flex-direction: column-reverse;
                height: calc(100dvh - 300px);
              }
              .modal_form_left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                justify-content: center;
                width: 50%;
                max-width: 100%;
                @include respond(s720) {
                  width: 100%;
                }
                .modal_form_benefits {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  h5 {
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.4px;
                  }
                }
              }
              .modal_form_right {
                width: 50%;
                max-width: 100%;
                overflow: hidden;
                @include respond(s720) {
                  width: 100%;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .modal_footer_button {
              display: flex;
              justify-content: end;
              gap: 20px;
              margin-top: 24px;
              .button {
                width: auto;
                @include respond(s720) {
                  width: calc(50% - 10px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Property owner Subscription Modal */
.property_owner_subscription_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      overflow: auto !important;
      .custom__modal__content {
        border-radius: 8px;
        padding: 32px;
        max-height: 100%;

        .custom__modal__body__content {
          // @include respond(s720) {
          //   overflow: auto;
          //   height: calc(100dvh - 400px);
          // }
          .modal_content {
            margin-top: 0;
            text-align: center;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .v-btn {
                height: auto;
                width: auto;
              }
            }
            .modal_form_header {
              img {
                @include respond(s720) {
                  width: 90px;
                  max-width: 100%;
                }
              }
              h3 {
                @include fluidFont(32, 26, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 1);
                margin-top: 32px;
              }
              p {
                @include fluidFont(14, 14, 1.3);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.15px;
                margin-top: 8px;
              }
            }
            .modal_form_top {
              margin-top: 32px;
              display: flex;
              gap: 8px;
              flex-direction: column;
              align-items: flex-start;
              .modal_form_benefits {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                margin: 0;
                p {
                  @include fluidFont(16, 14, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.4px;
                  text-align: left;
                  span {
                    font-weight: 700;
                  }
                }
              }
            }
            .plan-cards {
              display: flex;
              flex-direction: column;
              gap: 16px;
              margin-top: 40px;

              .plan-card {
                box-shadow: 0px 0px 8px 0px #0c0f4a14;

                padding: 24px 16px;
                border-radius: 16px;
                &__tags {
                  display: flex;
                  justify-content: space-between;
                }
                &__tag-item {
                  padding: 0.25rem 0.5rem;
                  display: block;
                  font-size: 14px;
                  line-height: 1;
                  color: $blueDark;
                  text-transform: uppercase;
                  font-weight: 700;
                  border: 1px solid $orange;
                  border-radius: 6px;
                  color: rgba($blueDark, 1);
                }
                &__tag-item-discount {
                  padding: 3px 6px;
                  color: rgba($white, 1);
                  background-color: rgba($orange, 1);
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 600;
                  letter-spacing: 0.15px;
                  border-radius: 8px;
                  text-transform: capitalize;
                }

                &__price {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  text-align: left;
                  margin-top: 16px;
                  span {
                    @include fluidFont(28, 28, 1.3);
                    font-weight: 700;
                  }
                }
                .button {
                  width: 100%;
                  max-width: 100%;
                }
              }
              .selected {
                border: 2px solid rgba($orange, 1);
              }
            }
            .modal_footer_text {
              margin-top: 30px;
              .modal_footer_TC_text {
                display: flex;
                justify-content: center;
                gap: 5px;
                color: rgba($blueDark, 0.5);
                @include fluidFont(14, 14, 1.3);
                font-weight: 400;
                margin-top: 32px;
                .yellow-text {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Property owner Terms & Condition Modal */
.property_owner_terms_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      .custom__modal__content {
        border-radius: 8px;
        padding: 44px 80px;
        height: 100%;
        overflow: unset;

        @include respond(md) {
          padding: 1rem 1.5rem;
        }
        .custom__modal__body__content {
          .modal_content {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 24px;
            .modal_form_top {
              display: flex;
              flex-direction: column;
              gap: 8px;
              .modal_form_top_title {
                @include fluidFont(18, 18, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.5px;
                margin-bottom: 8px;
              }
              .modal_form_benefits {
                display: flex;
                align-items: center;
                gap: 8px;
                h5 {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.4px;
                }
              }
            }
            .modal_form_bottom {
              .modal_form_bottom_title {
                @include fluidFont(18, 18, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.5px;
                margin-bottom: 8px;
              }
              .modal_form_bottom_des {
                @include fluidFont(14, 14, 1.8);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }
    }
  }
}

/* Select Workstation Type Modal */
.select_workstation_type_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .sub-title {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
              padding: 8px 0 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            }
            .modal_form {
              display: flex;
              gap: 12px;
              justify-content: space-between;
              padding: 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              // @include respond(s720) {
              //   flex-direction: column-reverse;
              // }
              .select_type__card-item {
                width: 50%;
                max-width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 10px 0px #0c0f4a33;
                border-radius: 8px;
                .select_type__card-details {
                  .workstation_icon {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    background-color: rgba($blueDark, 1);
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 12px;
                    }
                  }
                  .select_type__card-details__text {
                    margin-top: 10px;
                    h4 {
                      @include fluidFont(14, 14, 1.2);
                      font-weight: 700;
                      color: rgba($blueDark, 1);
                    }
                  }
                }
              }
              .selected {
                border: 1px solid rgba($orange, 1);
              }
              .modal_form_left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                justify-content: center;
                width: 50%;
                max-width: 100%;
                @include respond(s720) {
                  width: 100%;
                }
                .modal_form_benefits {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  h5 {
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.4px;
                  }
                }
              }
              .modal_form_right {
                width: 50%;
                max-width: 100%;
                overflow: hidden;
                @include respond(s720) {
                  width: 100%;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .modal_footer_button {
              display: flex;
              justify-content: end;
              gap: 24px;
              margin-top: 24px;
              .button {
                width: calc(50% - 12px);
                max-width: 100%;
                @include respond(s720) {
                  width: calc(50% - 10px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Property owner ID check Modal */
.property_owner_id_Check_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      .custom__modal__content {
        border-radius: 8px;
        padding: 44px 80px;
        height: 100%;
        overflow: unset;
        @include respond(s720) {
          padding: 24px 16px;
        }
        .custom__modal__body__content {
          .custom__modal__heading {
            .title {
              @include fluidFont(36, 28, 1.3);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
          .modal_content {
            margin-top: 16px;
            .description {
              p {
                @include fluidFont(16, 16, 1.3);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
              }
            }
            .content {
              width: 400px;
              max-width: 100%;
            }
            .modal_form_top {
              display: flex;
              flex-direction: column;
              gap: 8px;
              .modal_form_top_title {
                @include fluidFont(18, 18, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.5px;
                margin-bottom: 8px;
              }
              .modal_form_benefits {
                display: flex;
                align-items: center;
                gap: 8px;
                h5 {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.4px;
                }
              }
            }
            .modal_form_bottom {
              .modal_form_bottom_title {
                @include fluidFont(18, 18, 1.2);
                font-weight: 600;
                color: rgba($blueDark, 1);
                letter-spacing: 0.5px;
                margin-bottom: 8px;
              }
              .modal_form_bottom_des {
                @include fluidFont(14, 14, 1.8);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
            }
          }
        }
      }
    }
  }
}

//  Emergency Service Modal
.emergency_service_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      overflow: hidden !important;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .custom__modal__heading {
            padding-bottom: 24px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            .title {
              @include fluidFont(18, 18, 1.2);
              font-weight: 400;
              letter-spacing: 0.15px;
              color: rgba($blueDark, 1);
            }
            .description {
              display: none;
            }
          }
          .emergency_service_content {
            display: flex;
            flex-direction: column;
            gap: 32px;
            height: calc(100dvh - 250px);
            overflow: auto;
            .emergency_service_input {
              padding-top: 24px;
              .v-input__control {
                .v-field {
                  .v-field__field {
                    .v-field__input {
                      padding: 0;
                      @include fluidFont(28, 12, 1.2);
                      font-weight: 600;
                      letter-spacing: 0.15px;
                      color: rgba($blueDark, 1);
                    }
                  }
                }
              }
            }
            .emergency_service_editor {
              margin-top: 20px;
              border-radius: 8px;
              box-shadow: 0px 0px 8px 0px #0c0f4a14;
              @include respond(s720) {
                margin: 0;
              }
              .ql-toolbar.ql-snow {
                border: none;
              }
              .ql-container.ql-snow {
                border: none;
                height: 200px;
                .ql-editor {
                  color: rgba($blueDark, 1);
                }
              }
            }
            .emergency_date_time_picker {
              .date_time_selector {
                background-color: rgba($cardBorder, 1);
                border-radius: 8px;
                .weekdays {
                  padding: 12px;
                  border-bottom: 1px solid rgba($buttonText, 0.4);
                  .weekdays_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    label {
                      @include fluidFont(16, 10, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.15px;
                      color: rgba($blueDark, 1);
                    }
                    .allday_selector {
                      @include fluidFont(16, 10, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.15px;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .days {
                    margin-top: 16px;
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    button {
                      width: 36px;
                      height: 36px;
                      border-radius: 100%;
                      border: 1px solid rgba($blueDark, 1);
                      @include fluidFont(12, 8, 1.2);
                      font-weight: 500;
                      letter-spacing: 0.15px;
                      color: rgba($blueDark, 1);
                      @include respond(sm) {
                        width: 21px;
                        height: 21px;
                      }
                    }
                    .selected {
                      background-color: rgba($blueDark, 1);
                      color: rgba($white, 1);
                    }
                  }
                }
                .time-range {
                  padding: 12px;
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  .time-select {
                    width: 120px;
                    background-color: rgba($white, 1);
                    margin: 0;
                    border-radius: 6px;
                    border: 1px solid rgba($black, 0.1);
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    input {
                      @include fluidFont(16, 16, 1.2);
                      font-weight: 600;
                      letter-spacing: 0.09px;
                      color: rgba($blueDark, 1);
                      text-align: center;
                      padding: 0;
                      min-height: 44px;
                      width: 30px;
                    }
                    .v-field__outline {
                      display: none;
                    }
                    select {
                      text-align: center;
                      @include fluidFont(16, 16, 1.2);
                      font-weight: 600;
                      letter-spacing: 0.09px;
                      color: rgba($blueDark, 1);
                    }
                  }
                  span {
                    text-align: center;
                    @include fluidFont(16, 16, 1.2);
                    font-weight: 600;
                    letter-spacing: 0.09px;
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
            .custom_column {
              .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                label {
                  @include fluidFont(16, 12, 1.2);
                  font-weight: 600;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                }
                .v-icon {
                  @include fluidFont(20, 12, 1.2);
                }
              }

              .emergency_service_time_inputs {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                grid-gap: 8px;
                align-items: center;

                span {
                  font-weight: 600;
                }
              }

              .custom_input {
                .v-input__control {
                  .v-field {
                    border: 1px solid rgba($buttonText, 0.4);
                    border-radius: 8px;
                    .v-field__field {
                      .v-field__input {
                        padding: 8px 16px;
                        min-height: 40px;
                        @include fluidFont(16, 9, 1.2);
                        font-weight: 600;
                        letter-spacing: 0.15px;
                        color: rgba($blueDark, 1);
                        opacity: 1;

                        @include respond(s720) {
                          min-height: 32px;
                        }
                      }
                      &::placeholder {
                        @include fluidFont(16, 9, 1.2);
                        font-weight: 600;
                        letter-spacing: 0.15px;
                        color: rgba($blueDark, 1);
                        opacity: 1;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        .emergency_footer {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-top: 20px;
          .button {
            width: calc(50% - 6px);
            max-width: 100%;
          }
        }
      }

      //  Specialisms Dropdown
      .custom_dropdown {
        position: relative;
        padding-bottom: 10px;
        &_trigger {
          min-height: 40px;
          border: 1px solid rgba(128, 130, 159, 0.4);
          border-radius: 8px;
          padding: 8px 8px 8px 16px;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: space-between;
          @include fluidFont(16, 9, 1);
          font-weight: 600;
          letter-spacing: 0.15px;
          cursor: pointer;
          color: #0c0f4a;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @include respond(s720) {
            min-height: 32px;
            font-size: 9px;
            line-height: 1;
            border-radius: 4px;
          }

          .mdi-chevron-down {
            flex-shrink: 0;
            font-size: 24px;

            @include respond(s720) {
              font-size: 16px;
            }
          }

          &.is-open {
            border-color: #3b67e9;

            .mdi-chevron-down {
              transform: scaleY(-1);
              margin-top: -1px;
            }
          }
        }

        &_search {
          margin-bottom: 16px;

          @include respond(s720) {
            margin-bottom: 8px;
          }
        }

        .v-checkbox .v-selection-control {
          min-height: 16px;

          @include respond(s720) {
            min-height: 10px;
          }
        }

        .mdi-checkbox-marked::before {
          color: #4b4bff;
        }

        .v-selection-control__input {
          width: 16px;
          height: 16px;
        }

        .v-selection-control__wrapper {
          width: 16px;
          height: 16px;
        }

        :deep(.v-selection-control__wrapper),
        :deep(.v-selection-control__input) {
          width: 16px;
          height: 16px;

          .mdi {
            font-size: 20px;
          }
        }

        :deep(.v-checkbox .v-selection-control) {
          min-height: 1px;
        }

        &_content {
          &_inner {
            background-color: rgba($white, 1);
            border-radius: 8px;
            margin-top: 16px;
            padding: 20px 16px;
            box-shadow: 0px 0px 20px 0px #0000001a;

            @include respond(s720) {
              margin-top: 10px;
              padding: 10px;
              border-radius: 4px;
              box-shadow: 0px 0px 11.46px 0px #0000001a;
            }

            .mdi-magnify {
              font-size: 18px;

              @include respond(s720) {
                font-size: 12px;
                margin-top: 2px;
              }
            }

            .v-field {
              border-radius: 30px !important;
              border: 0 !important;
              padding-left: 16px;

              @include respond(s720) {
                padding-left: 10px;
              }

              .v-field__input {
                height: 38px;
                padding-left: 8px !important;
                font-size: 12px !important;

                @include respond(s720) {
                  height: 22px;
                  padding-left: 5px !important;
                  font-size: 7px !important;
                }
              }
            }
          }
        }

        &_list {
          padding: 0 16px;
          display: grid;
          grid-gap: 24px;
          max-height: 465px;
          overflow: auto;

          @include respond(s720) {
            padding: 0 10px;
            max-height: 285px;
            grid-gap: 19px;
          }

          li {
            display: grid;
            grid-template-columns: 16px 1fr;
            grid-gap: 12px;
            align-items: center;
            @include fluidFont(16, 9, 1.2);
            font-weight: 700;
            letter-spacing: 0.15px;
            color: $blueDark;
            padding: 12px 0;
            cursor: pointer;

            @include respond(s720) {
              padding: 6px 0;
              grid-template-columns: 8px 1fr;
              grid-gap: 10px;
              font-size: 10px;
            }
          }

          &_content {
            display: grid;
            grid-template-columns: 32px 1fr;
            grid-gap: 12px;
            align-items: center;

            @include respond(s720) {
              grid-gap: 6px;
              grid-template-columns: 18px 1fr;
            }
          }

          .service-icon-wrapper {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
            min-height: 32px !important;
            background-color: #0c0f4a !important;

            @include respond(s720) {
              width: 18px !important;
              min-width: 18px !important;
              height: 18px !important;
              min-height: 18px !important;
              padding: 4px;
            }

            &__icon {
              filter: invert(98%) sepia(100%) saturate(2%) hue-rotate(17deg)
                brightness(105%) contrast(101%);

              @include respond(s720) {
                width: 100% !important;
                height: auto !important;
              }
            }
          }
        }

        &__checkbox {
          &-label {
            display: grid;
            grid-template-columns: 16px 1fr;
            grid-gap: 6px;
            align-items: center;
          }

          &-group {
            display: grid;
            grid-gap: 16px;
          }

          &-image {
            position: relative;
            height: 0;
            width: 100%;
            padding-bottom: 100%;
            border-radius: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

        &_select_all {
          padding: 0 16px 12px;
          border-bottom: 1px solid rgba(#c5d2f2, 0.5);

          @include respond(s720) {
            padding: 0 10px 12px;
          }
        }

        &__heading {
          display: flex;
          align-items: center;
          gap: 12px;
          width: 88%;
        }

        &__title {
          display: grid;
          grid-template-columns: 32px 1fr;
          align-items: flex-start;
          grid-gap: 12px;
          width: 100%;

          @include respond(s720) {
            grid-template-columns: 18px 1fr;
            grid-gap: 6px;
          }

          &_text {
            @include fluidFont(12, 12, 16px);
            letter-spacing: 0.4px;
            color: rgba($blueDark, 0.4);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include respond(s720) {
              line-height: 10px;
              letter-spacing: 0.32px;
              font-size: 6px;
            }

            h2,
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            h2 {
              @include fluidFont(16, 16, 24px);
              font-weight: 700;
              letter-spacing: 0.15px;
              color: $blueDark;

              @include respond(s720) {
                line-height: 14px;
                font-size: 9px;
                letter-spacing: 0.09px;
              }
            }
          }

          &_icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background-color: $blueDark;
            display: flex;
            align-items: center;
            justify-content: center;

            @include respond(s720) {
              width: 18px;
              height: 18px;
            }

            img {
              width: auto;
              height: auto;
              object-fit: contain;
              max-width: 10px;
              filter: invert(100%) sepia(2%) saturate(7425%) hue-rotate(152deg)
                brightness(109%) contrast(116%);

              @include respond(s720) {
                max-width: 6px;
              }
            }
          }
        }

        &__content {
          padding: 5px;
          display: grid;
          grid-gap: 12px;
          max-height: 140px;
          overflow: auto;

          @include respond(s720) {
            grid-gap: 14px;
          }
        }

        .v-expansion-panels {
          padding: 0;
          box-shadow: none;
          gap: 16px;

          @include respond(s720) {
            gap: 8px;
          }

          .v-expansion-panel {
            border-radius: 0 !important;
            overflow: hidden;
            box-shadow: none !important;
            margin: 0 !important;

            &::after {
              display: none !important;
            }
          }

          .v-expansion-panel-title__overlay {
            display: none;
          }

          .v-expansion-panel__shadow {
            box-shadow: none;
          }

          .v-expansion-panel__shadow {
            box-shadow: none;
          }

          .v-expansion-panel-text__wrapper {
            padding: 0 0 0 46px;

            @include respond(s720) {
              padding-left: 28px;
            }
          }

          .v-expansion-panel-title {
            padding: 12px 16px;
            min-height: 64px;

            @include respond(s720) {
              padding: 8px;
              min-height: 40px;
            }
          }

          .v-expansion-panel-title__icon .mdi {
            font-size: 42px;

            @include respond(s720) {
              font-size: 24px;
            }
          }
        }

        &-specialisms {
          .custom_dropdown_list {
            padding: 0;

            .v-checkbox-btn label {
              @include fluidFont(12, 9, 1);
              padding-left: 12px;
            }
          }

          .v-selection-control__input {
            @include respond(s720) {
              width: 10px;
              height: 10px;
            }

            .v-icon {
              @include respond(s720) {
                font-size: 15px;
              }
            }
          }

          .v-selection-control__wrapper {
            @include respond(s720) {
              width: 10px;
              height: 10px;
            }
          }
        }

        &-contractor,
        &-property {
          .v-checkbox-btn label {
            @include fluidFont(12, 12, 1);
            padding-left: 12px;
          }

          ul {
            grid-gap: 0;
          }

          li {
            padding: 10px 0;
            grid-template-columns: 1fr;
          }
        }

        &-contractor {
          .v-checkbox-btn label {
            font-weight: 500;
          }

          li {
            padding: 8px 0;
          }
        }

        &-occupier {
          .custom_dropdown__checkbox-label {
            grid-template-columns: 20px 1fr;
          }
        }
      }
    }
  }
}

/* Add Contractor Dialog */
.add_contractor_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(s720) {
            padding: 24px 16px;
          }
          .header {
            padding: 0 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0;
            @include respond(s720) {
              align-items: flex-start;
            }
            @include respond(sm) {
              border: none;
            }
            h4 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              @include respond(sm) {
                font-weight: 700;
              }
            }
            .v-btn {
              height: unset;
              width: unset;
              box-shadow: none;
              :deep(.v-btn__content) {
                .v-icon {
                  @include fluidFont(15, 15, 1);
                  @include respond(sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              flex-direction: column;
              align-items: center;
              border: none;
            }
            .modal__inner__content {
              .v-form {
                display: flex;
                gap: 16px;
                flex-direction: column;
                .invite_members_form {
                  display: flex;
                  gap: 16px;
                  align-items: flex-start;
                  @include respond(s720) {
                    flex-wrap: wrap;
                    padding: 16px 10px;
                    border: 1px solid rgba($buttonText, 0.4);
                    border-radius: 8px;
                  }
                  .email_address {
                    width: 60%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                      order: 2;
                    }
                  }
                  .tags_wrapper {
                    width: 40%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                      order: 3;
                    }
                  }
                  .input_field {
                    label {
                      @include fluidFont(12, 12, 2);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.15px;
                    }
                    .v-input {
                      margin: 0;
                      .v-input__control {
                        .v-field {
                          border: 1px solid rgba($blueDark, 0.2);
                          border-radius: 8px;
                          background-color: transparent;
                          .v-field__field {
                            .v-field__input {
                              background-color: transparent;
                              border: 0;
                              border-radius: 0;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              letter-spacing: 0.4px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .remove_btn {
                    width: 34px;
                    height: 34px;
                    background-color: #f1f6fb;
                    border-radius: 100%;
                    color: rgba($blueDark, 1);
                    top: 30px;
                    @include respond(s720) {
                      order: 1;
                      margin-left: auto;
                    }
                  }
                }
                .add_btn {
                  max-width: 100px;
                  margin-top: 12px;
                  @include respond(s720) {
                    margin: 0;
                  }
                }
                .message_wrapper {
                  margin-top: 20px;
                  padding-bottom: 28px;
                  @include respond(s720) {
                    margin: 0;
                  }
                  label {
                    @include fluidFont(12, 12, 2);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                  }
                  .v-input {
                    margin: 0;
                    .v-input__control {
                      .v-field {
                        border: 1px solid rgba($blueDark, 0.2);
                        border-radius: 8px;
                        .v-field__field {
                          .v-field__input {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .modal__footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
            gap: 16px;
            @include respond(s720) {
              flex-direction: column-reverse;
              margin: 0;
            }
            .button-orange {
              min-width: 255px;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
              }
            }
            .button {
              @include respond(s720) {
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
:deep(.inner_input) {
  margin: 0;
  .v-input__control {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          border: 0;
          border-radius: 0;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

/* invite sent Contractor Dialog */
.invite_sent_contrtactor_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        max-height: 100%;
        overflow: hidden;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(md) {
            padding: 20px;
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            .modal__content_img {
              background-color: rgba(#f3f3f3, 1);
              width: 100%;
              border-radius: 8px;
              gap: 10px;
              display: flex;
              justify-content: center;
              img {
                margin: 10px auto;
              }
            }
          }
          .modal__content_text {
            h4 {
              @include fluidFont(14, 14, 1.2);
              font-weight: 700;
              color: rgba($blueDark, 1);
              letter-spacing: 0.25px;
            }
            p {
              margin-top: 8px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
            }
            .invite_mail {
              margin-top: 24px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.25px;
            }
          }
          .modal__footer {
            display: flex;
            justify-content: end;
            .button {
              margin-top: 24px;
              @include respond(sm) {
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

/* Raise Issue image upload modal */
.issue__upload__modal {
  :deep(.v-overlay__content) {
    padding: 16px !important;
    margin: 0 !important;
    .main {
      border-radius: 10px;
      .custom__modal__content {
        height: 100%;
        padding: 0;
        max-height: 100%;
        .custom__modal__body__content {
          position: relative;
          height: calc(100% - 96px);
          .close__icon {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
          }
          .modal__content {
            display: flex;
            padding: 46px 46px 0;
            border-bottom: 1px dashed #8687a5;
            gap: 25px;
            height: 100%;
            @include respond(s720) {
              flex-direction: column;
              padding: 15px;
            }
            .left_upload_img {
              width: 375px;
              max-width: 100%;
              @include respond(md) {
                width: 100%;
                border: none;
              }
              .upload__area {
                border-radius: 6px;
                margin-top: 20px;
                border: 1px dashed rgba($black, 0.4);
                background-color: rgba($cardBorder, 1);
                padding: 0;
                .uploade-wrapper {
                  position: relative;
                  width: 100%;
                  height: 300px;
                  margin: 0;
                  z-index: 1;
                  .uploaded-img {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    border-radius: 100%;
                    object-fit: cover;
                    height: 100%;
                    width: 100% !important;
                    pointer-events: none;
                    z-index: -1;
                  }
                }
                .upload_file {
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: relative;
                  border-radius: 100%;
                  /* overflow: hidden;*/
                  cursor: pointer;
                  margin: 0 auto 24px;
                  //margin: 5px auto 24px;
                  .v-field__overlay,
                  .v-field {
                    border-radius: 0;
                    //border-radius: 100%;
                    background-color: rgba($blueDark, 0.15) !important;
                    box-shadow: none;
                    .v-field__overlay {
                      display: none;
                    }
                  }
                  &.added-img {
                    .v-field__overlay {
                      background: transparent;
                    }
                    .v-field {
                      background: transparent;
                    }
                    .v-field__input {
                      display: none;
                    }
                  }

                  input {
                    cursor: pointer;
                  }
                  .v-field__clearable {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    opacity: 1;
                    overflow: unset;
                    grid-area: unset;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($black, 0.5);
                    color: rgba($white, 1);
                    border-radius: 100%;
                  }
                  .v-input__prepend {
                    display: none;
                  }
                  .v-input__control {
                    position: absolute;
                    inset: 0;
                    border-radius: 100%;
                  }
                  .v-field--appended {
                    padding: 0;
                    position: relative;
                  }
                  .v-field__append-inner {
                    all: initial;
                    color: rgba($blueDark, 0.5);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }

                  .v-field-label {
                    left: 50%;
                    transform: translateX(-50%) !important;
                    margin: 28px 0 0 0;
                    font-size: 16px;
                    font-weight: 500;
                    /* max-width: 130px;*/
                    bottom: -30px;
                    top: unset !important;
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 700;
                    color: rgba($blueDark, 0.7);
                    opacity: 1;
                  }
                }
                .upload__text {
                  text-align: center;
                  padding: 30px 50px;
                  p {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 500;
                    color: rgba($blueDark, 0.5);
                    span {
                      text-decoration: underline;
                    }
                  }
                }
                .upload__button {
                  .button {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
              .selected-info {
                max-width: 342px;
                display: flex;
                gap: 12px;
                align-items: center;
                margin: 24px auto;
                .button {
                  min-width: auto;
                }

                &.w-full {
                  max-width: 100%;

                  .button {
                    min-width: 100%;
                  }
                }
              }
            }
            .image__upload__wrapper {
              height: auto;
              padding: 0;
              overflow: unset;
              width: calc(100% - 400px);

              @include respond(md) {
                width: 100%;
                max-width: 100%;
                padding: 0;
              }
              .blank_project {
                text-align: center;
                p {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 0.5);
                }
              }
              h5 {
                @include fluidFont(14, 14, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                margin-bottom: 16px;
              }
              .uploaded_details_wrapper {
                height: calc(100dvh - 350px);
                overflow: auto;
                .show_uploaded_details {
                  border-top: 1px solid rgba($PrimaryBlueLight, 1);
                  padding: 24px 0;
                  .v-responsive {
                    border-radius: 8px;
                    overflow: hidden;
                  }
                  .prop_img_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:hover {
                      background-color: transparent;
                    }
                    h5 {
                      margin: 0;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 700;
                      color: rgba($blueDark, 1);
                    }
                    .v-btn {
                      padding: 0;
                      min-width: auto;
                    }
                  }
                  .upload__list {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    flex-direction: column;
                    .selectprop_dropdown {
                      width: 100%;
                      max-width: 100%;
                      .v-input__control {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              height: 32px;
                              padding: 0 16px;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                              }
                            }
                            .v-combobox__selection {
                              span {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .v-text-custom {
                      margin: 0;
                      width: 100%;
                      max-width: 100%;
                      :deep(.v-input__control) {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              flex-direction: row-reverse;
                              height: 32px;
                              padding: 0;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                              }
                              .mdi-menu-down {
                                margin-top: -5px;
                              }
                            }
                            .v-select__selection {
                              span {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .footer-buttons,
            .footer__button {
              padding: 23px 26px;
              display: flex;
              justify-content: flex-end;
              gap: 12px;
              @include respond(s720) {
                padding: 15px;
              }
              .button {
                min-width: 140px;
                @include respond(s720) {
                  width: calc(50% - 4px);
                }
                .v-btn__content {
                  text-transform: capitalize;
                }
              }
            }
          }

          .footer-buttons {
            padding: 23px 26px;
            display: flex;
            justify-content: flex-end;
            gap: 12px;
            @include respond(s720) {
              padding: 15px;
            }
            .button {
              min-width: 140px;
              @include respond(s720) {
                width: calc(50% - 4px);
              }
              .v-btn__content {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

/* Trade Passport Preview Modal */
.trade_passport_preview_modal {
  :deep(.v-overlay__content) {
    overflow: hidden;
    max-height: 100%;
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        padding: 65px 88px;
        box-shadow: 0px 0px 10px 0px #2a2a2a05;
        color: $blueDark;
        max-height: 100%;

        overflow: hidden;
        @media (max-width: 1199px) {
          padding: 45px 58px;
        }

        @include respond(s720) {
          padding: 36px 22px;
        }

        .custom__modal__body__content {
          .v-window {
            height: auto !important;
            @include respond(xs) {
              // height: 480px !important;
              overflow: auto;
            }
            .v-window__container {
              height: max-content;
            }
          }
          .custom__modal__heading {
            @include respond(s720) {
              text-align: left;
            }
          }

          h4 {
            @include fluidFont(32, 28, 1.2);
            color: rgba($blueDark, 1);
            font-weight: 700;
            margin-bottom: 30px;

            @media (max-width: 1439px) {
              margin-bottom: 25px;
            }

            @include respond(s720) {
              margin-bottom: 15px;
            }
          }

          .carousel__img {
            max-width: 100%;
            width: 440px;
            height: 330px;
            margin: 0 auto;

            @media (max-width: 1439px) {
              margin-bottom: 40px;
            }

            @include respond(s720) {
              margin-bottom: 20px;
            }

            @include respond(sm) {
              height: auto;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .trade_passport_preview_modal_title {
            @include fluidFont(24, 24, 1.2);
            font-weight: 700;
            margin-bottom: 24px;

            @include respond(s720) {
              margin-bottom: 15px;
            }
          }

          p {
            margin-top: 52px;
            @include fluidFont(16, 16, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 0.5);
            @include respond(s720) {
              margin-top: 20px;
              text-align: left;
            }
          }

          .trade_passport_preview_modal_text {
            @include fluidFont(16, 16, 24px);
            font-weight: 500;
            color: rgba($blueDark, 0.5);
            max-width: 544px;
            width: 100%;
            margin: 0 auto;
            letter-spacing: 0.15px;
            min-height: 96px;

            p {
              font-weight: 500;
              @include fluidFont(16, 16, 24px);
            }
          }

          .header-title {
            @include fluidFont(36, 36, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            display: flex;
            justify-content: center;
          }

          .welcome__image {
            margin-top: 56px;
            @include respond(s720) {
              margin-top: 20px;
            }
          }

          .carousel-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-top: 50px !important;
            // position: absolute;
            // bottom: 30px;
            // left: 0;
            // right: 0;

            @include respond(sm) {
              flex-direction: column;
              bottom: 10px;
              width: 80%;
              max-width: 100%;
              margin: 0 auto;
            }

            .v-btn--disabled {
              background-color: rgb($blueDark, 0.04);
              color: rgb($blueDark, 0.5);
            }

            .button {
              max-width: auto;
              width: auto;
              min-width: 192px;
              margin-top: 0;

              @include respond(sm) {
                min-width: 100%;
              }
            }
          }
        }

        .button-gray {
          color: rgba($blueDark, 0.5);
          text-transform: uppercase;
          font-weight: 500;

          .v-btn__overlay {
            background-color: rgba($blueDark, 0.04);
            opacity: 1;
          }

          &:hover,
          &:focus {
            box-shadow: none;
          }
        }

        .v-carousel__controls {
          bottom: -40px;
          height: 16px;

          .v-btn--icon {
            width: 16px !important;
            height: 16px !important;
          }
        }

        .v-carousel__controls__item {
          margin: 0 4px;

          .v-icon {
            color: #9bbeff;
            opacity: 1;
          }
        }

        .v-btn--active {
          .v-icon {
            color: #000;
          }
        }

        .button {
          margin-top: 44px;
          width: 100%;
          max-width: 100%;
          @include respond(s720) {
            margin-top: 30px;
          }
        }

        .header {
          position: absolute;
          top: 28px;
          right: 25px;
          padding: 0;
          width: 24px;
          height: 24px;
          margin: 0;

          @include respond(s720) {
            position: relative;
            top: -30px;
            right: -18px;
            margin-left: auto;
          }
        }

        .v-btn--icon.v-btn--density-default {
          width: 24px;
          height: 24px;
          box-shadow: none;
          min-height: 24px;
          border-radius: 0;
        }

        .v-btn--icon.v-btn--density-default .v-btn__overlay,
        .v-btn--icon.v-btn--density-default .v-btn__underlay {
          display: none;
        }
      }
    }
  }
}

/* Post project visit modal */
.project_vist__modal_box {
  :deep(.v-overlay__content) {
    .main {
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border-radius: 8px;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .action-icon {
            width: 48px;
            height: 48px;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              filter: invert(55%) sepia(11%) saturate(742%) hue-rotate(198deg)
                brightness(92%) contrast(88%);
            }
          }
          .custom__modal__heading {
            margin-top: 16px;
            text-align: center;
            h4 {
              @include fluidFont(24, 24, 1.2);
              font-weight: 600;
              color: rgba($blueDark, 1);
            }
            p {
              @include fluidFont(16, 16, 1.2);
              font-weight: 500;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.15px;
            }
          }
          .project_vist__selector {
            margin-top: 36px;
            .v-input__control {
              .v-field {
                border: 1px solid rgba($buttonText, 0.4);
                border-radius: 8px;
                padding: 7px 16px;
                .v-field__field {
                  align-items: center;
                  .v-field__input {
                    padding: 0;
                    min-height: unset;
                    .v-select__selection {
                      @include fluidFont(14, 14, 1.2);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.4px;
                    }
                  }
                }
                .v-field__outline {
                  display: none;
                }
              }
            }
          }
          .button {
            margin-top: 36px;
          }
        }
      }
    }
  }
}

/* Specialisms Multiselect Modal */
.specialisms_multiselect_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      text-align: left;
      color: $blueDark;

      .custom__modal__content {
        padding: 24px;
        max-height: 100%;
        .custom__modal__body__content {
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 0 0 24px 0;
            margin: 0;
            margin-bottom: 24px;
            border-bottom: 1px solid #c5d2f2;

            h2 {
              @include fluidFont(18, 18, 24px);
              letter-spacing: 0.15px;
              font-weight: 400;
            }

            .mdi-close {
              font-size: 24px !important;
            }
          }

          .specialisms_multiselect_modal_search {
            margin-top: 24px;
          }

          .specialisms_multiselect_modal_search .v-field {
            border: 0;
            border-radius: 30px;
            background-color: rgba($blueDark, 0.04);

            .v-field__field {
              .v-field__input {
                padding: 8px 16px 8px 5px;
                min-height: 38px;
                height: 38px;
                @include fluidFont(12, 12, 1);
                font-weight: 400;
                letter-spacing: -0.41px;
                color: rgba($blueDark, 1);
                opacity: 1;
              }

              &::placeholder {
                @include fluidFont(12, 12, 1);
                font-weight: 400;
                letter-spacing: -0.41px;
                color: #8e8e93;
                opacity: 1;
              }
            }

            .mdi-magnify {
              font-size: 18px;
            }

            .v-field__outline {
              display: none;
            }
          }

          .specialisms_multiselect_modal_item_heading {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 88%;
          }

          .v-checkbox {
            flex-shrink: 0;

            .v-selection-control__input {
              width: 16px;
              height: 16px;

              .mdi-checkbox-blank-outline {
                opacity: 1;
                color: rgba($blueDark, 0.5);
              }
            }

            .v-selection-control__wrapper {
              width: 16px;
              height: 16px;
            }

            .v-selection-control {
              min-height: 16px;
            }
          }

          .specialisms_multiselect_modal_inner {
            margin-top: 24px;
            max-height: 100%;
            overflow: auto;
            height: calc(100dvh - 260px);
            // @include respond(sm) {
            //   max-height: 200px;
            // }
            .v-expansion-panels {
              display: flex;
              flex-direction: column;
              gap: 16px;
              padding: 12px;
              .v-expansion-panel {
                border-radius: 8px !important;
                overflow: hidden;
                box-shadow: 0px 0px 10px 0px #0c0f4a33;
                margin: 0;
              }
            }
          }

          .specialisms_multiselect_modal_item_title_text {
            @include fluidFont(12, 12, 16px);
            letter-spacing: 0.4px;
            color: rgba($blueDark, 0.4);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            h2,
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            h2 {
              @include fluidFont(16, 16, 24px);
              font-weight: 700;
              letter-spacing: 0.15px;
              color: $blueDark;
            }
          }

          .specialisms_multiselect_modal_item_title {
            display: grid;
            grid-template-columns: 32px 1fr;
            align-items: flex-start;
            grid-gap: 12px;
            width: 100%;
          }

          .specialisms_multiselect_modal_item_title_icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background-color: $blueDark;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: auto;
              height: auto;
              object-fit: contain;
              max-width: 10px;
              filter: invert(100%) sepia(2%) saturate(7425%) hue-rotate(152deg)
                brightness(109%) contrast(116%);
            }
          }

          .v-expansion-panel-title__overlay {
            display: none;
          }

          .v-expansion-panel__shadow {
            box-shadow: none;
          }

          .v-expansion-panel-text__wrapper {
            padding: 0 20px 20px;
          }

          .v-expansion-panel-title {
            padding: 12px 16px;
            min-height: 64px;

            @include respond(s720) {
              padding: 12px;
            }
          }

          .v-expansion-panel-title__icon .mdi {
            font-size: 42px;

            @include respond(s720) {
              font-size: 32px;
            }
          }

          .specialisms_multiselect_modal_item_content {
            padding: 0 5px 0 28px;
            display: grid;
            grid-gap: 12px;

            @include respond(s720) {
              padding: 0 0 0 20px;
            }
          }

          .specialisms_multiselect_modal_item_input_wrapper {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-gap: 15px;
            align-items: center;

            @include respond(s720) {
              grid-gap: 8px;
            }

            .button {
              padding: 0;
              height: 100%;
              width: 34px;
              min-height: 100%;
            }
          }

          .specialisms_multiselect_modal_item_input .v-field {
            border: 1px solid rgba(#80829f, 0.4);
            border-radius: 8px;

            .v-field__field {
              .v-field__input {
                padding: 8px 16px;
                min-height: 32px;
                height: 32px;
                @include fluidFont(12, 12, 1);
                font-weight: 400;
                letter-spacing: 0.41px;
                color: rgba($blueDark, 1);
                opacity: 1;
              }

              &::placeholder {
                @include fluidFont(12, 12, 1);
                font-weight: 400;
                letter-spacing: 0.41px;
                color: #80829f;
                opacity: 1;
              }
            }

            .v-field__outline {
              display: none;
            }
          }

          .specialisms_multiselect_modal_item_content .v-checkbox {
            .v-selection-control__wrapper,
            .v-selection-control__input {
              width: 14px;
              height: 14px;
            }

            .v-selection-control {
              min-height: 14px;
            }

            .v-selection-control__wrapper {
              margin-right: 6px;
            }

            .v-label {
              @include fluidFont(12, 12, 16px);
              color: rgba($blueDark, 0.5);
              font-weight: 500;
            }

            .mdi {
              font-size: 20px;
            }
          }

          .specialisms_multiselect_modal_footer {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 12px;
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #c5d2f2;
            .button {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

/* Contract Assign Modal */
.contract_assign_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;

      .custom__modal__content {
        padding: 26px 24px;

        @media (max-width: 767px) {
          padding: 16px;
        }

        .custom__modal__body__content {
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 0;
            margin: 0;
            margin-bottom: 26px;

            h2 {
              @include fluidFont(18, 18, 24px);
              letter-spacing: 0.15px;
              font-weight: 700;
            }

            .mdi-close {
              font-size: 24px !important;
            }
          }
          .modal__content {
            height: 500px;
            overflow: auto;
            padding-right: 5px;
            .v-expansion-panels {
              gap: 10px;
              .v-expansion-panel {
                &::after {
                  display: none;
                }
              }
            }
          }
          .v-expansion-panel-title {
            min-height: 42px;
            padding: 10px 20px;
          }

          .contract_assign_modal_item_title {
            display: flex;
            align-items: center;
            gap: 10px;

            h2 {
              @include fluidFont(14, 14, 17px);
              font-weight: 700;
            }
          }

          .v-expansion-panel {
            border-radius: 8px !important;
            background-color: rgba($blueDark, 0.05);
            color: rgba($blueDark, 1);
          }

          .contract_assign_modal_tag {
            border-radius: 5px;
            min-height: 22px;
            padding: 4px 10px;
            background-color: rgba(#4a92e5, 0.1);
            color: #4a92e5;
            @include fluidFont(12, 12, 16px);
            letter-spacing: 0.25px;
            font-weight: 600;
          }

          .v-expansion-panel__shadow,
          .v-expansion-panel-title__overlay {
            display: none;
          }

          .v-expansion-panel-text__wrapper {
            padding: 0 20px 20px;
          }

          .contract_assign_modal_info {
            @include fluidFont(14, 14, 20px);
            letter-spacing: 0.4px;

            &:not(:first-child) {
              padding-top: 16px;
              margin-top: 16px;
              border-top: 1px solid #c5d2f2;
            }

            p {
              word-break: break-all;
            }
          }

          .contract_assign_modal_info_footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include fluidFont(10, 10, 1);
            letter-spacing: 0.4px;
            margin-top: 16px;
          }

          .contract_assign_modal_info_author {
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-gap: 8px;
            align-items: center;
          }

          .contract_assign_modal_info_author_image {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: 100%;
            overflow: hidden;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .contract_assign_modal_footer {
            margin-top: 26px;
            display: flex;
            justify-content: flex-end;
          }
          .show-add-btn {
            justify-content: space-between;
          }
        }
      }
    }
  }
}

/* Proof Work Modal */

.proof_work_modal {
  .v-overlay__content {
    .main {
      @include respond(s720) {
        border-radius: 13px;
      }

      .custom__modal__content {
        padding: 36px 30px;

        @include respond(s720) {
          padding: 18px 15px;
        }

        :deep(.header) {
          position: relative;
          padding-bottom: 30px !important;

          @include respond(s720) {
            padding: 0 10px 10px !important;
          }

          &::after {
            content: "";
            position: absolute;
            left: -30px;
            bottom: 0;
            height: 1px;
            border-bottom: 0.66px dashed #8687a5;
            width: calc(100% + 60px);

            @include respond(s720) {
              left: -15px;
              width: calc(100% + 30px);
            }
          }
        }

        :deep(.proof_work_modal_inner) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: 20px 16px;

          @include respond(s720) {
            grid-template-columns: 1fr;
            grid-gap: 8px;
          }
        }

        :deep(.proof_work_modal_doc_list) {
          background-color: #f1f6fb;
          border-radius: 7px;
        }

        :deep(.proof_work_modal_doc_list_header) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding: 14px 14px 14px 20px;
          border-bottom: 0.66px dashed #8687a5;

          @include respond(s720) {
            padding: 10px 8px 8px 15px;
            border-bottom-width: 0.5px;
          }
        }

        :deep(.proof_work_modal_doc_list_header h2) {
          @include fluidFont(18, 14, 1);
          letter-spacing: 0.15px;
          flex: auto;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :deep(.proof_work_modal_doc_list_header .v-btn) {
          background-color: $white;
          height: auto;
          padding: 8px 10px;
          border-radius: 8px;
          text-transform: none;
          flex-shrink: 0;
        }

        :deep(.proof_work_modal_doc_list_header .v-btn__overlay) {
          display: none;
        }

        :deep(.proof_work_modal_doc_list_header .v-btn__content) {
          color: #80829f;
          @include fluidFont(12, 10, 1);
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 3px;
        }

        :deep(.proof_work_modal_doc_list_header .v-btn__content img) {
          width: auto;
          height: auto;
          object-fit: contain;
          max-width: 12px;
        }

        :deep(.proof_work_modal_doc_list_content) {
          padding: 10px 12px 6px 20px;

          @include respond(s720) {
            padding: 10px 20px 4px 15px;
          }
        }

        :deep(.proof_work_modal_doc_list_inner) {
          height: 142px;
          overflow: auto;

          @include respond(s720) {
            height: 115px;
          }
        }

        :deep(.proof_work_modal_doc) {
          display: flex;
          align-items: center;
          gap: 20px;
          @include fluidFont(12, 9, 28px);
          letter-spacing: 0.5px;
          font-weight: 600;
          color: $blueDark;
          text-decoration: none;
          padding: 4px 0;
          transition: color 0.3s ease;

          @include respond(s720) {
            padding: 0;
            gap: 12px;
          }

          &:hover {
            color: $brightBlue;
          }
        }

        :deep(.proof_work_modal_doc img) {
          flex-shrink: 0;
          width: 20px;
          height: auto;
          object-fit: contain;

          @include respond(s720) {
            width: 12px;
          }
        }

        :deep(.proof_work_modal_doc_image_list) {
          grid-column: span 2;
          display: grid;
          grid-template-columns: repeat(5, minmax(0, 1fr));
          grid-gap: 16px;

          @include respond(s720) {
            grid-column: span 1;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 10px;
          }
        }

        :deep(.proof_work_modal_doc_image_list_add_more),
        :deep(.proof_work_modal_doc_image_list_item) {
          position: relative;
          height: 0;
          padding-bottom: 100%;
          overflow: hidden;
          border-radius: 8px;
        }

        :deep(.proof_work_modal_doc_image_list_add_more) {
          cursor: pointer;
          border: 1px solid rgba($blueDark, 0.5);
        }

        :deep(.proof_work_modal_doc_image_list_add_more p) {
          @include fluidFont(50, 25, 1);
          font-weight: 700;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        :deep(.proof_work_modal_doc_image_list_item img) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        :deep(.contract_assign_modal_footer) {
          margin-top: 46px !important;

          @include respond(s720) {
            margin-top: 15px !important;
          }
        }
      }
    }
  }
}
/* Pay money to escrow wallet */
.escrow_pay_money_wallet {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      .custom__modal__content {
        padding: 40px;
        @include respond(s1024) {
          overflow: auto;
          height: calc(100dvh - 250px);
        }
        @include respond(s720) {
          padding: 32px 17px;
          overflow: auto;
          height: calc(100dvh - 250px);
        }
        .custom__modal__body__content {
          padding: 0 36px;
          @include respond(s720) {
            padding: 0 15px;
          }
          .custom__modal__heading {
            text-align: center;
            h3 {
              @include fluidFont(36, 26, 1.4);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
            p {
              display: none;
            }
          }
          .wallet_summary {
            .v-form {
              .bank__select {
                .custom_selector.v-autocomplete {
                  width: 100%;
                  max-width: 100%;

                  .v-input__control {
                    border-bottom: 2px solid rgba($blueDark, 0.2);
                    .v-field {
                      border-radius: 0;
                      background-color: transparent;
                      .v-field__field {
                        .v-field__input {
                          padding: 8px 0;
                          @include fluidFont(14, 14, 1.5);
                          font-weight: 400;
                          color: rgba($blueDark, 0.5);
                        }
                      }
                      .v-field__append-inner {
                        padding: 0;
                      }
                    }
                  }
                }
              }
              .amount_details {
                margin-top: 40px;
              }
              .confirm_wrapper {
                .v-btn {
                  margin-top: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* Pay money to escrow wallet */
.escrow_pay_money_wallet {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      .custom__modal__content {
        padding: 40px;
        @include respond(s1024) {
          overflow: auto;
          height: calc(100dvh - 250px);
        }
        @include respond(s720) {
          padding: 32px 17px;
          overflow: auto;
          height: calc(100dvh - 250px);
        }
        .custom__modal__body__content {
          padding: 0 36px;
          @include respond(s720) {
            padding: 0 15px;
          }
          .custom__modal__heading {
            text-align: center;
            h3 {
              @include fluidFont(36, 26, 1.4);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
            p {
              display: none;
            }
          }
          .wallet_summary {
            .v-form {
              .bank__select {
                .custom_selector.v-autocomplete {
                  width: 100%;
                  max-width: 100%;

                  .v-input__control {
                    border-bottom: 2px solid rgba($blueDark, 0.2);
                    .v-field {
                      border-radius: 0;
                      background-color: transparent;
                      .v-field__field {
                        .v-field__input {
                          padding: 8px 0;
                          @include fluidFont(14, 14, 1.5);
                          font-weight: 400;
                          color: rgba($blueDark, 0.5);
                        }
                      }
                      .v-field__append-inner {
                        padding: 0;
                      }
                    }
                  }
                }
              }
              .amount_details {
                margin-top: 40px;
              }
              .confirm_wrapper {
                .v-btn {
                  margin-top: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Pay money escrow e-wallet */
.escrow_pay_money_e-wallet {
  :deep(.v-overlay__content) {
    .main {
      .custom__modal__content {
        height: calc(100dvh - 200px);
        overflow: auto;
        position: relative;
        .custom__modal__body__content {
          .custom_loader {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

/* Estimate Detaisl Modal*/

.estimate_details_modal {
  :deep(.v-overlay__content) {
    .main {
      padding: 24px;
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      .custom__modal__content {
        padding: 0;
        max-height: unset;
        .custom__modal__body__content {
          .custom__modal__heading {
            .title {
              @include fluidFont(24, 24, 1.5);
              font-weight: 600;
              color: rgba($blueDark, 1);
            }
          }
          .user_details {
            margin: 24px 0 0;
            ul {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: 10px;
              li {
                display: flex;
                align-items: center;
                p {
                  @include fluidFont(16, 16, 2);
                  font-weight: 500;
                  color: rgba($blueDark, 0.5);
                }
                span {
                  @include fluidFont(16, 16, 2);
                  font-weight: 500;
                  color: rgba($blueDark, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
/* Create Workstation Preview Modal */
.create_workstation_preview_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .sub-title {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
              letter-spacing: 0.25px;
              padding: 8px 0 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            }
            .modal_form {
              display: flex;
              justify-content: space-between;
              padding: 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              @include respond(s720) {
                flex-direction: column-reverse;
                height: calc(100dvh - 300px);
              }
              .modal_form_left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                justify-content: center;
                width: 50%;
                max-width: 100%;
                @include respond(s720) {
                  width: 100%;
                }
                .modal_form_benefits {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  h5 {
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.4px;
                  }
                }
              }
              .modal_form_right {
                width: 50%;
                max-width: 100%;
                overflow: hidden;
                @include respond(s720) {
                  width: 100%;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .modal_footer_button {
              display: flex;
              justify-content: end;
              gap: 20px;
              margin-top: 24px;
              .button {
                width: auto;
                @include respond(s720) {
                  width: calc(50% - 10px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Create Workstation Preview Modal New */
.create_workstation_preview_modal_new {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;

      .custom__modal__content {
        .custom__modal__body__content {
          .modal_content {
            .header {
              h4 {
                @include fluidFont(18, 18, 1);
              }
            }

            .modal__image {
              width: 100%;
              height: 235px;
              margin-bottom: 24px;

              @include respond(s720) {
                height: 132px;
                margin-bottom: 30px;
              }

              img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
              }
            }

            .title {
              @include fluidFont(30, 24, 37px);
              color: $blueDark;
              margin-bottom: 15px;

              @include respond(s720) {
                font-weight: 600;
              }
            }

            .sub-title {
              @include fluidFont(16, 16, 20px);
              font-weight: 500;
              padding: 14px 30px 42px;
              letter-spacing: 0.15px;
              border-bottom: 0;

              @include respond(s720) {
                padding: 14px 26px 30px 0;
              }
            }

            .modal__carousel__content {
              text-align: center;

              @include respond(s720) {
                text-align: left;
              }
            }

            .modal_form {
              padding-bottom: 0;
              border-bottom: 0;

              @include respond(s720) {
                height: auto;
              }
            }
          }
        }

        .modal__navigation__buttons {
          display: flex;
          gap: 13px;
          flex-wrap: wrap;
          justify-content: center;

          .button {
            min-width: 194px;

            @include respond(s720) {
              min-width: 140px;
            }
          }
          .disable {
            background-color: rgba($buttonText, 0.5);
            color: rgba($buttonText, 1);
          }
        }

        .onboard-stepper {
          margin-top: 38px;

          .v-stepper-item {
            &__title {
              font-weight: 700;
              letter-spacing: 0.4px;
            }

            &__avatar {
              margin: 0;
            }

            &--selected {
              @include respond(md) {
                flex: auto;
              }
            }

            &.done {
              opacity: 0.48;

              .v-stepper-item__title {
                color: #faa500;

                @include respond(md) {
                  display: none;
                }
              }
            }
          }

          &__item:not(.v-stepper-item--selected):not(.done) {
            opacity: 0.24;

            @include respond(md) {
              flex: 0 0 13%;
            }

            .v-stepper-item__title {
              color: $blueDark;

              @include respond(md) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

/* Phaseproof modal*/

.phaseproof__upload__modal {
  :deep(.v-overlay__content) {
    padding: 16px !important;
    margin: 0 !important;
    .main {
      border-radius: 10px;
      .custom__modal__content {
        height: 100%;
        padding: 0;
        max-height: 100%;
        .custom__modal__body__content {
          position: relative;
          height: calc(100% - 96px);
          .close__icon {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
          }
          .modal__content {
            display: flex;
            padding: 46px 46px 0;
            border-bottom: 1px dashed #8687a5;
            gap: 25px;
            height: 100%;
            @include respond(s720) {
              flex-direction: column;
              padding: 15px;
            }
            .left_upload_img {
              width: 375px;
              max-width: 100%;
              @include respond(md) {
                width: 100%;
                border: none;
              }
              .upload__area {
                border-radius: 6px;
                margin-top: 20px;
                border: 1px dashed rgba($black, 0.4);
                background-color: rgba($cardBorder, 1);
                padding: 0;
                .uploade-wrapper {
                  position: relative;
                  width: 100%;
                  height: 300px;
                  margin: 0;
                  z-index: 1;
                  .uploaded-img {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    border-radius: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100% !important;
                    pointer-events: none;
                    z-index: -1;
                  }
                }
                .upload_file {
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: relative;
                  border-radius: 0;
                  cursor: pointer;
                  margin: 0 auto 24px;
                  .v-field__overlay,
                  .v-field {
                    border-radius: 0;
                    background-color: rgba($blueDark, 0.15) !important;
                    box-shadow: none;
                    .v-field__overlay {
                      display: none;
                    }
                  }
                  &.added-img {
                    .v-field__overlay {
                      background: transparent;
                    }
                    .v-field {
                      background: transparent;
                    }
                    .v-field__input {
                      display: none;
                    }
                  }

                  input {
                    cursor: pointer;
                  }
                  .v-field__clearable {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    opacity: 1;
                    overflow: unset;
                    grid-area: unset;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($black, 0.5);
                    color: rgba($white, 1);
                    border-radius: 0;
                  }
                  .v-input__prepend {
                    display: none;
                  }
                  .v-input__control {
                    position: absolute;
                    inset: 0;
                    border-radius: 0;
                  }
                  .v-field--appended {
                    padding: 0;
                    position: relative;
                  }
                  .v-field__append-inner {
                    all: initial;
                    color: rgba($blueDark, 0.5);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }

                  .v-field-label {
                    left: 50%;
                    transform: translateX(-50%) !important;
                    margin: 28px 0 0 0;
                    font-size: 16px;
                    font-weight: 500;
                    /* max-width: 130px;*/
                    bottom: -30px;
                    top: unset !important;
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 700;
                    color: rgba($blueDark, 0.7);
                    opacity: 1;
                  }
                }
                .upload__text {
                  text-align: center;
                  padding: 30px 50px;
                  p {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 500;
                    color: rgba($blueDark, 0.5);
                    span {
                      text-decoration: underline;
                    }
                  }
                }
                .upload__button {
                  .button {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
              .selected-info {
                max-width: 342px;
                display: flex;
                gap: 12px;
                align-items: center;
                margin: 24px auto;
                .button {
                  min-width: 100%;
                  max-width: 100%;
                }

                &.w-full {
                  max-width: 100%;

                  .button {
                    min-width: 100%;
                  }
                }
              }
            }
            .image__upload__wrapper {
              height: auto;
              padding: 0;
              overflow: unset;
              width: calc(100% - 400px);

              @include respond(md) {
                width: 100%;
                max-width: 100%;
                padding: 0;
              }
              .blank_project {
                text-align: center;
                p {
                  @include fluidFont(12, 12, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 0.5);
                }
              }
              h5 {
                @include fluidFont(14, 14, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                margin-bottom: 16px;
              }
              .uploaded_details_wrapper {
                height: calc(100dvh - 350px);
                overflow: auto;
                .show_uploaded_details {
                  border-top: 1px solid rgba($PrimaryBlueLight, 1);
                  padding: 24px 0;
                  .v-responsive {
                    border-radius: 8px;
                    overflow: hidden;
                  }
                  .prop_img_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:hover {
                      background-color: transparent;
                    }
                    h5 {
                      margin: 0;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 700;
                      color: rgba($blueDark, 1);
                    }
                    .v-btn {
                      padding: 0;
                      min-width: auto;
                    }
                  }
                  .upload__list {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    flex-direction: column;
                    .selectprop_dropdown {
                      width: 100%;
                      max-width: 100%;
                      .v-input__control {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              height: 32px;
                              padding: 0 16px;
                              flex-direction: row;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                                margin-right: auto;
                              }
                              .v-select__selection {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 0;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .v-text-custom {
                      margin: 0;
                      width: 100%;
                      max-width: 100%;
                      :deep(.v-input__control) {
                        .v-field {
                          padding: 0;
                          border-radius: 8px;
                          background-color: rgba($white, 1);
                          border: 1px solid rgba($buttonText, 0.4);
                          .v-field__field {
                            .v-field__input {
                              min-height: 32px;
                              flex-direction: row-reverse;
                              height: 32px;
                              padding: 0;
                              input {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                                padding: 8px 16px;
                              }
                              .mdi-menu-down {
                                margin-top: -5px;
                              }
                            }
                            .v-select__selection {
                              span {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgb($blueDark, 1);
                                letter-spacing: 0.4px;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .footer-buttons {
            padding: 23px 26px;
            display: flex;
            justify-content: flex-end;
            gap: 12px;
            @include respond(s720) {
              padding: 15px;
            }
            .button {
              min-width: 140px;
              @include respond(s720) {
                width: calc(50% - 4px);
              }
              .v-btn__content {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
}

/* Setting Up Preview Modal New */
.setting_up_preview_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 10px;
      .custom__modal__content {
        padding: 36px;
        overflow: unset;
        max-height: 100%;
        @include respond(md) {
          padding: 24px;
        }
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              color: rgba($blueDark, 1);
              text-align: right;
              .v-btn {
                height: auto;
                width: auto;
              }
              h4 {
                @include fluidFont(18, 18, 1);
              }
            }
            .modal_content_main {
              text-align: center;
              padding: 0 28px;
              margin-top: 16px;
              .title {
                @include fluidFont(34, 24, 1.4);
                color: rgba($blueDark, 1);
                font-weight: 600;
              }
              .sub-title {
                margin: 36px auto 0;
                @include fluidFont(16, 16, 1.3);
                font-weight: 500;
                letter-spacing: 0.15px;
                border-bottom: 0;
                color: rgba($blueDark, 1);
                max-width: 75%;
                @include respond(md) {
                  max-width: 100%;
                }
              }
              .title_img {
                width: 100%;
                max-width: 100%;
                margin-top: 40px;
                height: 375px;
                @include respond(xs) {
                  height: 225px;
                }
                img {
                  width: 100%;
                  max-width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          .modal_footer_button {
            margin-top: 32px;
          }
        }
      }
    }
  }
}

/* Restricted Modal */
.restricted__modal_box {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        padding: 57px 59px 32px;
        max-height: 100%;
        @include respond(s720) {
          padding: 36px 22px;
        }
        .custom__modal__body__content {
          .dashboard-welcome__close {
            border: none;
            color: rgba($blueDark, 1);
            &:hover {
              background: transparent;
            }
          }
          .custom__modal__heading {
            @include respond(s720) {
              text-align: left;
            }
          }
          .header-title {
            @include fluidFont(24, 24, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
          }
          .header-sub-title {
            margin-top: 24px;
            @include fluidFont(16, 16, 1.2);
            font-weight: 500;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
          }
          .welcome__image {
            margin-top: 50px;
            @include respond(s720) {
              margin-top: 20px;
            }
          }
        }
        .button {
          margin-top: 38px;
          width: 100%;
          max-width: 100%;
          @include respond(s720) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
/* Project chat */
.project_chat_modal {
  :deep(.v-overlay__content) {
    .previous_btn {
      display: none;
    }
    .main {
      border-radius: 8px;
      .custom__modal__header {
        display: none;
      }
      .custom__modal__content {
        padding: 60px 24px 24px;
        max-height: unset;
        .custom__modal__body__content {
          .project_chat {
            min-height: 280px;
            display: flex;
            justify-content: center;
            align-items: center;
            .project_chat__wrapper {
              text-align: center;

              .project_chat__logo {
                width: 54px;
                height: 54px;
                border-radius: 100%;
                background-color: rgba($blueDark, 1);
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  @include fluidFont(32, 32, 1.4);
                  color: rgba($white, 1);
                  font-weight: 700;
                  letter-spacing: 0.56;
                }
              }
              .pending {
                background-color: rgba($orange, 1);
                img {
                  width: 40px;
                  height: 40px;
                  filter: invert(100%) sepia(100%) saturate(1%)
                    hue-rotate(306deg) brightness(106%) contrast(101%);
                }
              }
              .project_chat__text {
                margin-top: 30px;
                h5 {
                  @include fluidFont(24, 24, 1.4);
                  color: rgba($blueDark, 1);
                  font-weight: 700;
                }
                p {
                  margin: 16px auto 0;
                  @include fluidFont(16, 16, 1.4);
                  font-weight: 500;
                  color: rgba($blueDark, 0.5);
                  max-width: 70%;
                }
                .pending_text {
                  max-width: 73%;
                }
                .project_chat__notice {
                  margin-top: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;
                  .v-btn {
                    padding: 0;
                    height: auto;
                    opacity: 1;
                    .v-btn__content {
                      @include fluidFont(16, 16, 1.4);
                      font-weight: 500;
                      color: rgba($orange, 1);
                      text-transform: capitalize;
                      letter-spacing: 0.15px;
                    }
                  }
                  span {
                    @include fluidFont(16, 16, 1.4);
                    font-weight: 500;
                    color: rgba($blueDark, 0.5);
                  }
                }
              }
              .project_chat__buttons {
                margin-top: 36px;
                display: flex;
                gap: 12px;
                align-items: center;
                justify-content: center;
                .v-btn {
                  width: calc(50% - 4px);
                  max-width: 100%;
                }
              }
              .pending_button {
                .v-btn {
                  width: 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Money out Modal */
.money_out_e_wallet_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 8px;
      padding: 24px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(18, 18, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 24px;
              .form_wrapper {
                padding-bottom: 24px;
                .form_wrapper__title {
                  display: flex;
                  align-items: flex-start;
                  gap: 8px;
                  label {
                    @include fluidFont(12, 12, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    margin-bottom: 4px;
                    display: inline-block;
                  }
                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
                .input_field {
                  .v-input__control {
                    .v-field {
                      padding: 0;
                      border-radius: 8px;
                      border: 1px solid rgba($buttonText, 0.4);
                      background-color: transparent;
                      .v-field__field {
                        .v-field__input {
                          column-gap: 7px;
                          padding: 8px 16px;
                          min-height: auto;
                          @include fluidFont(12, 12, 1.3);
                          font-weight: 500;
                          color: rgba($blueDark, 1);
                          &::placeholder {
                            color: rgba($blueDark, 1);
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                          }
                        }
                      }
                      .v-field__outline {
                        display: none;
                      }
                    }
                    .v-selection-control-group {
                      .v-selection-control.v-selection-control--dirty {
                        .v-selection-control__wrapper {
                          .v-selection-control__input {
                            .v-icon {
                              color: rgba($SecondaryBrightBlue, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
                p {
                  color: rgba($blueDark, 0.5);
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  margin-top: 8px;
                  span {
                    opacity: 1;
                  }
                }
              }
            }
            .notes__wrapper {
              text-align: center;
              p {
                color: rgba($blueDark, 0.5);
                @include fluidFont(10, 10, 1.3);
                font-weight: 400;
                letter-spacing: 0.25px;
                span {
                  color: rgba($blueDark, 1);
                }
              }
            }
            .button {
              width: 100%;
              max-width: 100%;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}

/* Money out SuccessFully Modal */
.money_out_success_modal {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;
    .main {
      border-radius: 20px;
      padding: 36px;
      .custom__modal__content {
        padding: 0;
        height: 100%;
        overflow: unset;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .v-btn {
                height: unset;
                width: unset;
                box-shadow: none;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 36px;
              .success_img {
                text-align: center;
                margin: 0 auto;
              }
              .balance_amount {
                text-align: center;
                margin-top: 36px;
                @include fluidFont(34, 34, 1.4);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
              .success__message {
                margin-top: 36px;
                padding: 36px;
                background-color: rgba($cardBorder, 1);
                text-align: center;
                h4 {
                  @include fluidFont(24, 24, 1.2);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                }
                p {
                  @include fluidFont(16, 14, 1.2);
                  font-weight: 500;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                  max-width: 50%;
                  margin: 36px auto 0;
                  @include respond(sm) {
                    max-width: 100%;
                  }
                }
              }
            }
            .modal_content_footer {
              display: flex;
              gap: 36px;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              .button {
                width: calc(50% - 18px);
                max-width: 100%;
                margin-top: 24px;
                @include respond(sm) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Select Workstation Modal */
.select_workstation_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      overflow: hidden;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .modal_content {
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 24px;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              .v-btn {
                width: auto;
                height: auto;
              }
              h4 {
                @include fluidFont(18, 18, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
            }
            .modal_form {
              p {
                margin-top: 24px;
                @include fluidFont(16, 16, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }

              .workstation__list__content {
                margin-top: 24px;
                .v-input {
                  .v-input__control {
                    border-radius: 30px;
                    background-color: rgba($blueDark, 0.04);
                    .v-field {
                      .v-field__outline {
                        display: none;
                      }
                      .v-field__field {
                        .v-field__input {
                          @include fluidFont(12, 12, 1.3);
                          font-weight: 400;
                          color: rgba($blueDark, 1);
                          &::placeholder {
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                          }
                        }
                      }
                    }
                  }
                }
                .workstation__results {
                  margin-top: 16px;
                  display: flex;
                  align-items: center;
                  padding-bottom: 20px;
                  border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
                  p {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 400;
                    color: rgba($blueDark, 0.5);
                    margin: 0;
                    span {
                      font-weight: 700;
                    }
                  }
                }
                .workstation__companies__list {
                  margin-top: 20px;
                  display: flex;
                  gap: 16px;
                  align-items: center;
                  flex-wrap: wrap;
                  max-height: 350px;
                  overflow: auto;
                  .workstation__companies-item {
                    width: calc(50% - 8px);
                    max-width: 100%;
                    padding: 12px 16px;
                    box-shadow: 0px 0px 8px 0px #0c0f4a14;
                    border-radius: 8px;
                    border: 2px solid transparent;
                    @include respond(s720) {
                      width: 100%;
                    }
                    .workstation__companies-item__content {
                      display: flex;
                      align-items: flex-start;
                      gap: 16px;
                      .company-img {
                        width: 36px;
                        height: 36px;
                        border-radius: 100%;
                        .contractor-detail__img-wrapper {
                          width: 100% !important;
                          height: 100% !important;
                          min-width: 100% !important;
                          min-height: 100% !important;
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                          }
                        }
                      }
                      .company-text {
                        p {
                          margin: 0;
                          @include fluidFont(16, 16, 1.3);
                          font-weight: 700;
                          color: rgba($blueDark, 1);
                        }
                        span {
                          @include fluidFont(12, 12, 1.3);
                          font-weight: 700;
                          color: rgba($blueDark, 0.5);
                        }
                      }
                    }
                  }
                  .selected {
                    border: 2px solid rgba($orange, 1);
                  }
                }
              }
            }
            .modal_content_footer {
              margin-top: 24px;
              display: flex;
              gap: 16px;
              align-items: center;
              .button {
                width: calc(50% - 8px);
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* Accepted Workstation */

.accepted_workstation_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .modal_content {
            .header {
              padding: 0;
              margin: 0;
              text-align: right;
              .v-btn {
                width: auto;
                height: auto;
              }
            }
            .modal_form {
              margin-top: 48px;
              text-align: center;
              .modal_img {
                width: 135px;
                height: 135px;
                margin: 0 auto;
              }
              h3 {
                margin-top: 24px;
                @include fluidFont(24, 24, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
              p {
                margin-top: 24px;
                @include fluidFont(16, 16, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.14px;
              }
            }
            .button {
              margin-top: 24px;
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

/* Edit Certificate Modal */
.edit_certificate_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      .custom__modal__content {
        padding: 24px;
        max-height: 100%;
        .custom__modal__body__content {
          .modal_content {
            position: relative;
            .header {
              padding: 0 0 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(18, 18, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                width: auto;
                height: auto;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
                .v-ripple__container {
                  display: none;
                }
              }
            }
            .modal_form {
              padding-top: 16px;
              h4 {
                @include fluidFont(20, 20, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .cerificate__content {
                margin-top: 24px;
                display: flex;
                align-items: flex-start;
                gap: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
                @include respond(s720) {
                  flex-direction: column;
                }
                .left_block {
                  border-radius: 8px;
                  background-color: rgba($cardBorder, 1);
                  padding: 16px;
                  width: 50%;
                  max-width: 100%;
                  @include respond(s720) {
                    width: 100%;
                  }
                  h5 {
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed rgba(134, 135, 165, 0.66);
                  }
                  .left_block__content {
                    padding-top: 16px;
                    .left_block__content__item {
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      p {
                        @include fluidFont(14, 14, 1.3);
                        font-weight: 500;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.15px;
                      }
                    }
                  }
                }
                .right_block {
                  border-radius: 8px;
                  background-color: rgba($cardBorder, 1);
                  padding: 16px;
                  width: 50%;
                  max-width: 100%;
                  @include respond(s720) {
                    width: 100%;
                  }
                  h5 {
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed rgba(134, 135, 165, 0.66);
                  }
                  .right_block__content {
                    padding-top: 16px;
                    .search_field {
                      .v-input__control {
                        .v-field {
                          padding: 8px 16px;
                          border-radius: 30px;
                          .v-field__field {
                            .v-field__input {
                              top: 5px;
                              min-height: auto;
                              padding: 0;
                              @include fluidFont(12, 12, 1.3);
                              font-weight: 400;
                              color: rgba(142, 142, 147, 1);
                              &::placeholder {
                                @include fluidFont(12, 12, 1.3);
                                font-weight: 400;
                                color: rgba(142, 142, 147, 1);
                              }
                            }
                          }
                          .v-field__prepend-inner {
                            .v-icon {
                              color: rgba(142, 142, 147, 1);
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .members__list {
                      margin-top: 16px;
                      .members__list__status {
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        li {
                          display: flex;
                          gap: 6px;
                          align-items: center;
                          .v-checkbox {
                            height: auto;
                            --v-input-control-height: auto;
                            .v-input__control {
                              .v-selection-control {
                                width: auto;
                                min-width: auto;
                                .v-selection-control__wrapper {
                                  width: auto;
                                  height: auto;
                                  .v-selection-control__input {
                                    width: auto;
                                    height: auto;
                                  }
                                }
                              }
                            }
                          }
                          .member_img {
                            width: 20px;
                            height: 20px;
                            border-radius: 100%;
                            overflow: hidden;
                            img {
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                            }
                          }
                          p {
                            @include fluidFont(14, 14, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                          }
                        }
                      }
                    }
                  }
                }
              }
              .footer {
                padding-top: 24px;
                display: flex;
                gap: 12px;
                align-items: center;
                // .v-btn {
                //   width: 50%;
                //   max-width: 100%;
                // }
              }
            }
            .loader {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

/* Add Certificate Modal */
.add_certificate_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      .custom__modal__content {
        padding: 20px;
        max-height: 100%;
        .custom__modal__body__content {
          .modal_content {
            .header {
              padding: 0 0 20px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(18, 18, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                width: auto;
                height: auto;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
                .v-ripple__container {
                  display: none;
                }
              }
            }
            .modal_form {
              padding-top: 16px;

              .footer {
                padding-top: 24px;
                display: flex;
                gap: 12px;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

/* Edit Specialsim Modal */
.edit_specialism_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      .custom__modal__content {
        padding: 24px;
        max-height: 100%;
        .custom__modal__body__content {
          .modal_content {
            position: relative;
            .header {
              padding: 0 0 24px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0;
              h4 {
                @include fluidFont(18, 18, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
              .v-btn {
                width: auto;
                height: auto;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                  }
                }
                .v-ripple__container {
                  display: none;
                }
              }
            }
            .modal_form {
              padding-top: 16px;
              .service_wrapper {
                display: flex;
                gap: 8px;
                align-items: center;
                h4 {
                  @include fluidFont(20, 20, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.15px;
                }
                .v-icon {
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  background-color: rgba($PrimaryBlueLight, 1);
                  color: rgba($blueDark, 1);
                }
              }
              .cerificate__content {
                margin-top: 24px;
                display: flex;
                align-items: flex-start;
                gap: 24px;
                padding-bottom: 24px;
                border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
                @include respond(s720) {
                  flex-direction: column;
                }
                .left_block {
                  border-radius: 8px;
                  background-color: rgba($cardBorder, 1);
                  padding: 16px;
                  width: 100%;
                  max-width: 100%;
                  min-height: 290px;
                  max-height: 290px;
                  @include respond(s720) {
                    width: 100%;
                  }
                  h5 {
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed rgba(134, 135, 165, 0.66);
                  }
                  .left_block__content {
                    padding-top: 16px;
                    .search_field {
                      .v-input__control {
                        .v-field {
                          padding: 8px 16px;
                          border-radius: 30px;
                          .v-field__field {
                            .v-field__input {
                              top: 5px;
                              min-height: auto;
                              padding: 0;
                              @include fluidFont(12, 12, 1.3);
                              font-weight: 400;
                              color: rgba(142, 142, 147, 1);
                              &::placeholder {
                                @include fluidFont(12, 12, 1.3);
                                font-weight: 400;
                                color: rgba(142, 142, 147, 1);
                              }
                            }
                          }
                          .v-field__prepend-inner {
                            .v-icon {
                              color: rgba(142, 142, 147, 1);
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .left_block__content__item {
                      .service__list {
                        margin-top: 16px;
                        max-height: 150px;
                        overflow: auto;
                        ul {
                          display: flex;
                          flex-direction: column;
                          gap: 16px;
                          li {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            p {
                              @include fluidFont(14, 14, 1.3);
                              font-weight: 500;
                              color: rgba($blueDark, 1);
                              letter-spacing: 0.15px;
                            }
                            .v-checkbox {
                              height: auto;
                              --v-input-control-height: auto;
                              .v-input__control {
                                .v-selection-control {
                                  width: auto;
                                  min-width: auto;
                                  .v-selection-control__wrapper {
                                    width: auto;
                                    height: auto;
                                    .v-selection-control__input {
                                      width: auto;
                                      height: auto;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .right_block {
                  border-radius: 8px;
                  background-color: rgba($cardBorder, 1);
                  padding: 16px;
                  width: 100%;
                  max-width: 100%;
                  min-height: 290px;
                  max-height: 290px;
                  @include respond(s720) {
                    width: 100%;
                  }
                  h5 {
                    @include fluidFont(16, 16, 1.3);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed rgba(134, 135, 165, 0.66);
                  }
                  .right_block__content {
                    padding-top: 16px;
                    .search_field {
                      .v-input__control {
                        .v-field {
                          padding: 8px 16px;
                          border-radius: 30px;
                          .v-field__field {
                            .v-field__input {
                              top: 5px;
                              min-height: auto;
                              padding: 0;
                              @include fluidFont(12, 12, 1.3);
                              font-weight: 400;
                              color: rgba(142, 142, 147, 1);
                              &::placeholder {
                                @include fluidFont(12, 12, 1.3);
                                font-weight: 400;
                                color: rgba(142, 142, 147, 1);
                              }
                            }
                          }
                          .v-field__prepend-inner {
                            .v-icon {
                              color: rgba(142, 142, 147, 1);
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                    .members__list {
                      margin-top: 16px;
                      max-height: 150px;
                      overflow: auto;
                      .members__list__status {
                        display: flex;
                        gap: 16px;
                        flex-direction: column;
                        li {
                          display: flex;
                          gap: 6px;
                          align-items: center;
                          .v-checkbox {
                            height: auto;
                            --v-input-control-height: auto;
                            .v-input__control {
                              .v-selection-control {
                                width: auto;
                                min-width: auto;
                                .v-selection-control__wrapper {
                                  width: auto;
                                  height: auto;
                                  .v-selection-control__input {
                                    width: auto;
                                    height: auto;
                                  }
                                }
                              }
                            }
                          }
                          .member_img {
                            width: 20px;
                            height: 20px;
                            border-radius: 100%;
                            overflow: hidden;
                            img {
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                            }
                          }
                          p {
                            @include fluidFont(14, 14, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                          }
                        }
                      }
                    }
                  }
                }
              }
              .footer {
                padding-top: 24px;
                display: flex;
                gap: 12px;
                align-items: center;
              }
            }
            .loader {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

/* Calendar event edit*/
.member_event_edit_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      .custom__modal__content {
        padding: 24px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              h4 {
                @include fluidFont(18, 18, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
              .v-btn {
                width: 17px;
                height: 17px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(17, 17, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 24px;
              .form_wrapper {
                .list_block__item {
                  .event_field {
                    margin-bottom: 16px;
                    label {
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      margin-bottom: 8px;
                      display: inline-block;
                    }
                    .v-input {
                      margin: 0;

                      .v-input__control {
                        border: 1px solid rgba($buttonText, 0.4);
                        border-radius: 8px;
                        .v-field {
                          padding: 0;
                          --v-input-control-height: auto;
                          .v-field__field {
                            .v-label {
                              display: none;
                            }
                            .v-field__input {
                              padding: 12px 16px;
                              min-height: auto;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              overflow: auto;
                              scrollbar-width: none;
                              &::placeholder {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }

                              .v-select__selection {
                                padding: 2px 4px;
                                background-color: #f8f8f8;
                                border-radius: 10px;
                              }
                              .v-autocomplete__selection {
                                .v-chip {
                                  .v-chip__content {
                                    @include fluidFont(10, 10, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                  }
                                  .v-chip__close {
                                    width: 16px;
                                    height: 16px;
                                  }
                                }
                              }
                              input {
                                padding: 12px 16px;
                                background-color: transparent;
                                min-height: auto;
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }
                              &::placeholder {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }
                  }

                  .date_time_selector {
                    display: flex;
                    gap: 16px;
                    align-items: flex-start;
                    margin-bottom: 16px;
                    @include respond(s720) {
                      flex-direction: column;
                    }
                    .date_selector {
                      width: 40% !important;
                      max-width: 100%;
                      @include respond(s720) {
                        width: 100% !important;
                      }
                      label {
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        margin-bottom: 8px;
                        display: inline-block;
                      }
                      .calendar__select {
                        .v-input {
                          width: 100%;
                          margin: 0;
                          .v-input__control {
                            .v-field {
                              padding: 11px;
                              border-radius: 8px;
                              align-items: center;
                              border: 1px solid rgba($buttonText, 0.5);
                              .v-field__field {
                                .v-label {
                                  display: none;
                                }
                                .v-field__input {
                                  padding: 0;
                                  min-height: auto;
                                  color: rgba($blueDark, 1);
                                  opacity: 1;
                                  flex-direction: row-reverse;
                                  .v-icon {
                                    @include fluidFont(16, 16, 1.2);
                                    scrollbar-width: none;
                                  }
                                  .v-select__selection {
                                    padding: 0;
                                    background-color: transparent;
                                    .v-select__selection-text {
                                      @include fluidFont(12, 12, 1.2);
                                      font-weight: 400;
                                      color: rgba($blueDark, 1);
                                      text-transform: lowercase;
                                    }
                                  }
                                  img {
                                    width: 16px;
                                  }
                                  input {
                                    padding: 0;
                                    background-color: transparent;
                                    border: none;
                                    min-height: auto;

                                    @include fluidFont(12, 12, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                  }
                                  &::placeholder {
                                    @include fluidFont(12, 12, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                  }
                                }
                              }
                            }
                            .v-field__outline {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                    .time_selector {
                      width: 100% !important;
                      max-width: 100%;
                      label {
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        margin-bottom: 8px;
                        display: inline-block;
                      }
                      .time_selector_wrapper {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                        .custom_time_selector {
                          width: 50%;
                          max-width: 100%;
                          position: relative;
                          .v-input {
                            width: 100%;
                            margin: 0;
                            .v-input__control {
                              .v-field {
                                padding: 11px;
                                border-radius: 8px;
                                align-items: center;
                                border: 1px solid rgba($buttonText, 0.5);
                                .v-field__field {
                                  .v-label {
                                    display: none;
                                  }
                                  .v-field__input {
                                    padding: 0;
                                    min-height: auto;
                                    color: rgba($blueDark, 1);
                                    opacity: 1;
                                    flex-direction: row-reverse;
                                    scrollbar-width: none;
                                    @include fluidFont(12, 12, 1.2);
                                    font-weight: 400;
                                    color: rgba($blueDark, 1);
                                    .v-icon {
                                      @include fluidFont(16, 16, 1.2);
                                    }
                                    .v-select__selection {
                                      padding: 0;
                                      background-color: transparent;
                                      .v-select__selection-text {
                                        @include fluidFont(12, 12, 1.2);
                                        font-weight: 400;
                                        color: rgba($blueDark, 1);
                                        text-transform: lowercase;
                                      }
                                    }
                                    img {
                                      width: 16px;
                                    }
                                    input {
                                      padding: 0;
                                      background-color: transparent;
                                      border: none;
                                      min-height: auto;
                                      @include fluidFont(12, 12, 1.2);
                                      font-weight: 400;
                                      color: rgba($blueDark, 1);
                                    }
                                    &::placeholder {
                                      @include fluidFont(12, 12, 1.2);
                                      font-weight: 400;
                                      color: rgba($blueDark, 0.5);
                                    }
                                  }
                                }
                                .v-field__append-inner {
                                  .v-icon {
                                    @include fluidFont(16, 16, 1);
                                    color: rgba($blueDark, 0.5);
                                  }
                                }
                              }
                              .v-field__outline {
                                display: none;
                              }
                            }
                            .v-input__details {
                              padding: 0;

                              .v-messages {
                                width: 100%;
                                max-width: 100%;
                                .v-messages__message {
                                  @include fluidFont(12, 12, 1.2);
                                }
                              }
                            }
                          }
                          .custom_dropdown__menu {
                            position: absolute;
                            right: 0;
                            left: 0;
                            background-color: rgba($white, 1);
                            max-height: 200px;
                            overflow: auto;
                            border-radius: 6px;
                            box-shadow: 0px 0px 8px 0px #0c0f4a14;
                            padding: 12px;
                            z-index: 1;
                            top: 50px;
                            .custom_dropdown__item {
                              label {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                                text-transform: lowercase;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .desc__field {
                    margin-bottom: 16px;
                    label {
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      display: inline-block;
                      margin-bottom: 8px;
                    }
                    .v-text-field {
                      .v-input__control {
                        .v-field {
                          .v-field__field {
                            .v-field__input {
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .modal_form_footer {
                display: flex;
                gap: 16px;
                margin-top: 40px;
                .button {
                  width: calc(50% - 8px);
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.custom_time_selector {
  position: relative;
  width: 20%;
  .v-input {
    width: 100%;
    max-width: 100%;
  }
}
.v-field__input::-webkit-scrollbar,
.v-field__input {
  display: none;
  scrollbar-width: none;
}
/* Remove Invitation Dialog */
.remove_invitation_dialog {
  :deep(.v-overlay__content) {
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 40px 16px 20px;
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 26px;
            .modal__content_img {
              margin: 0 auto;
              width: 50px;
              height: 50px;
              padding: 6px 7px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: invert(59%) sepia(100%) saturate(4684%)
                  hue-rotate(334deg) brightness(101%) contrast(89%);
              }
            }
            .modal__content_header {
              text-align: center;
              h2 {
                @include fluidFont(24, 24, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 1);
                max-width: 75%;
                margin: 0 auto;
                @include respond(md) {
                  max-width: 75%;
                }
              }
              p {
                margin-top: 24px;
                @include fluidFont(16, 16, 1.3);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                padding: 0 30px;
                span {
                  color: rgba($blueDark, 1);
                }
              }
            }
          }
          .modal__footer {
            display: flex;
            justify-content: space-between;
            .button {
              margin-top: 24px;
              width: 48%;
              max-width: 100%;
              @include respond(sm) {
                width: 48%;
                max-width: 100%;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.gemini_model_dialog {
  display: none;
  @include respond(s720) {
    display: flex;
  }
  .v-overlay__content {
    .main {
      border-radius: 8px;
    }
  }
}

/* Welcome Trade Network modal */
.welcome_trade_network_modal {
  :deep(.v-overlay__content) {
    padding: 0 15px !important;
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        height: 100%;
        overflow: hidden;
        padding: 36px 50px;
        .custom__modal__body__content {
          .header-title {
            // display: flex;
            justify-content: center;
            @include fluidFont(24, 24, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            span {
              @include fluidFont(24, 24, 1.2);
              font-weight: 700;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 1);
            }
          }
          .header-sub-title {
            margin-top: 16px;
            justify-content: center;
            @include fluidFont(16, 16, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            span {
              @include fluidFont(24, 24, 1.2);
              font-weight: 700;
              letter-spacing: 0.25px;
              color: rgba($blueDark, 1);
            }
          }
          .custom__modal__heading {
            .custom__modal__img {
              margin-top: 75px;
            }
            .description.explore__desc {
              margin-top: 32px;
            }
          }
          .button {
            margin-top: 46px;
          }
        }
      }
    }
  }
}

/* Restricted Trade Network Modal */
.restricted_trade_network__modal_box {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        padding: 57px 59px 32px;
        max-height: 100%;
        @include respond(s720) {
          padding: 36px 22px;
        }
        .custom__modal__body__content {
          .dashboard-welcome__close {
            border: none;
            color: rgba($blueDark, 1);
            &:hover {
              background: transparent;
            }
          }
          .custom__modal__heading {
            @include respond(s720) {
              text-align: left;
            }
          }
          .header-title {
            @include fluidFont(24, 24, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
          }
          .header-sub-title {
            margin: 33px 0;
            @include fluidFont(16, 16, 1.2);
            font-weight: 500;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
          }
          .welcome__image {
            margin-top: 50px;
            @include respond(s720) {
              margin-top: 20px;
            }
          }
        }
        .button {
          margin-top: 17px;
          width: 100%;
          max-width: 100%;
          @include respond(s720) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

/* Add Contractor Trade Network Dialog */
.add_contractor_network_dialog {
  :deep(.v-overlay__content) {
    padding: 15px !important;
    margin: 0 !important;

    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      .custom__modal__content {
        padding: 0;
        height: auto;
        .custom__modal__body__content {
          padding: 24px;
          @include respond(s720) {
            padding: 24px 16px;
          }
          .header {
            margin: 0;
            padding: 0 0 24px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include respond(sm) {
              border: none;
            }
            h4 {
              @include fluidFont(20, 20, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
              @include respond(sm) {
                font-weight: 700;
              }
            }
            .v-btn {
              height: unset;
              width: unset;
              box-shadow: none;
              :deep(.v-btn__content) {
                .v-icon {
                  @include fluidFont(15, 15, 1);
                  @include respond(sm) {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
            @include respond(sm) {
              flex-direction: column;
              align-items: center;
              border: none;
            }
            .modal__content_header_note {
              background-color: rgba($SecondaryBrightBlue, 0.1);
              padding: 16px;
              border-radius: 4px;
              gap: 10px;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              letter-spacing: 0.25px;
              span {
                color: rgba($SecondaryBrightBlue, 1);
                text-decoration: underline;
                cursor: pointer;
              }
            }
            .modal__inner__content {
              width: 100%;
              .mobile__wrapper {
                display: none;
                @include respond(md) {
                  margin-bottom: 16px;
                  display: block;
                  width: 100%;
                  .button {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
              .v-form {
                display: flex;
                gap: 12px;
                flex-direction: column;
                .invite_members_form {
                  display: flex;
                  gap: 16px;
                  align-items: flex-start;
                  @include respond(s720) {
                    flex-wrap: wrap;
                    padding: 16px 10px;
                    border: 1px solid rgba($buttonText, 0.4);
                    border-radius: 8px;
                  }
                  .email_address {
                    width: 60%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                    }
                  }
                  .tags_wrapper {
                    width: 40%;
                    max-width: 100%;
                    @include respond(s720) {
                      width: 100%;
                    }
                  }
                  .input_field {
                    label {
                      @include fluidFont(12, 12, 2);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.15px;
                    }
                    .v-input {
                      margin: 0;
                      .v-input__control {
                        .v-field {
                          border: 1px solid rgba($blueDark, 0.2);
                          border-radius: 8px;
                          background-color: transparent;
                          .v-field__field {
                            .v-field__input {
                              background-color: transparent;
                              border: 0;
                              border-radius: 0;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              letter-spacing: 0.4px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .remove_btn {
                    width: 34px;
                    height: 34px;
                    background-color: #f1f6fb;
                    border-radius: 100%;
                    color: rgba($blueDark, 1);
                    top: 30px;
                    @include respond(s720) {
                      margin-left: auto;
                      top: 0;
                      width: 24px;
                      height: 24px;
                    }
                  }
                }
                .add_btn {
                  max-width: 100px;
                  margin-top: 12px;
                }
                .desktop__wrapper {
                  @include respond(md) {
                    display: none !important;
                  }
                }
                .message_wrapper {
                  margin-top: 20px;
                  padding-bottom: 28px;
                  label {
                    @include fluidFont(12, 12, 2);
                    font-weight: 600;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.15px;
                  }
                  .v-input {
                    margin: 0;
                    .v-input__control {
                      .v-field {
                        border: 1px solid rgba($blueDark, 0.2);
                        border-radius: 8px;
                        background-color: transparent;
                        .v-field__field {
                          .v-field__input {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .modal__footer {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            align-items: center;
            margin-top: 24px;
            @include respond(s720) {
              margin: 0;
            }

            .button-orange {
              min-width: 255px;
              @include respond(s720) {
                width: calc(50% - 8px);
                max-width: 100%;
                margin-top: 0px;
                min-width: auto;
              }
            }
            .button {
              @include respond(s720) {
                width: calc(50% - 8px);
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/* succes_tradeowner_modal */
.succes_tradeowner_modal {
  :deep(.v-overlay__content) {
    border-radius: 20px;
    .main {
      .custom__modal__content {
        padding: 36px 26px;
        .custom__modal__body__content {
          .modal__text {
            text-align: center;
            .header-title,
            .header-sub-title {
              @include fluidFont(24, 22, 1.3);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
          .custom__modal__img {
            text-align: center;
            margin-top: 32px;
          }
          p {
            @include fluidFont(16, 16, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 0.5);
            margin-top: 22px;
            text-align: center;
          }
        }
      }
    }
  }
}

/* Welcome Property owner Modal Box */

.succes_tradeowner_modal {
  :deep(.v-overlay__content) {
    border-radius: 20px;
    .main {
      .custom__modal__content {
        padding: 36px 26px;
        .custom__modal__body__content {
          .modal__text {
            text-align: center;
            .header-title,
            .header-sub-title {
              @include fluidFont(24, 22, 1.3);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
          .custom__modal__img {
            text-align: center;
            margin-top: 32px;
          }
          p {
            @include fluidFont(16, 16, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 0.5);
            margin-top: 22px;
            text-align: center;
          }
        }
      }
    }
  }
}

/* Welcome Property owner Modal Box */
.welcome__property_owner_modal_box {
  .v-overlay__content {
    .main {
      border-radius: 20px;
      text-align: center;
      .custom__modal__content {
        padding: 24px;

        @include respond(s720) {
          padding: 36px 22px;
        }
        .custom__modal__body__content {
          .custom__modal__heading {
            @include respond(s720) {
              text-align: left;
            }
          }
          :deep(.header-title-close-icon) {
            border: none;
            color: rgba($blueDark, 1);
            text-align: right;
            &:hover {
              background: transparent;
            }
          }
          :deep(.header-title) {
            @include fluidFont(36, 36, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            display: flex;
            justify-content: center;
          }
          :deep(.header-sub-title) {
            @include fluidFont(36, 36, 1.2);
            font-weight: 700;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 1);
            display: flex;
            justify-content: center;
          }
          :deep(.welcome__image) {
            margin-top: 56px;
            @include respond(s720) {
              margin-top: 20px;
            }
          }
          :deep(.welcome__text) {
            margin-top: 52px;
            padding: 0px 20px;
            @include fluidFont(16, 16, 1.2);
            font-weight: 400;
            letter-spacing: 0.25px;
            color: rgba($blueDark, 0.5);
            @include respond(s720) {
              margin-top: 20px;
              text-align: left;
            }
          }
        }
        :deep(.button) {
          margin-top: 44px;
          width: 100%;
          max-width: 100%;
          @include respond(s720) {
            margin-top: 30px;
          }
        }
        :deep(.footer_skip_text) {
          margin-top: 16px;
          cursor: pointer;
          @include fluidFont(16, 16, 1.2);
          font-weight: 400;
          letter-spacing: 0.25px;
          color: rgba($blueDark, 0.5);
          text-transform: uppercase;
          @include respond(s720) {
            margin-top: 14px;
            text-align: left;
          }
        }
      }
    }
  }
}

/* Preferred Contractor sent invotaiton */
.invite_sent_contractor_dialog {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 8px;
      box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
      .custom__modal__content {
        padding: 24px;
        .custom__modal__body__content {
          .modal__content {
            .modal__content_img {
              background-color: rgba(243, 243, 243, 1);
              border-radius: 8px;
              padding: 10px;
              text-align: center;
            }
            .modal__content_text {
              padding: 16px 0;
              border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
              h4 {
                @include fluidFont(14, 14, 1.3);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
              p {
                margin-top: 8px;
                @include fluidFont(12, 12, 1.3);
                font-weight: 400;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
              }
              .invite_mail {
                margin-top: 28px;
                span {
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                }
              }
            }
          }
          .modal__footer {
            margin-top: 16px;
            .button {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

/* job rate modal */
.job_rating_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;
      @media (max-width: 767px) {
        border-radius: 12px;
      }

      .custom__modal__content {
        padding: 24px;
        height: auto;

        @media (max-width: 767px) {
          padding: 15px;
        }

        color: rgba($blueDark, 1);
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .modal__header {
              @include fluidFont(12, 12, 15px);
              padding-bottom: 16px;
              margin-bottom: 24px;
              border-bottom: 1px solid rgba(12, 15, 74, 0.2);

              @media (max-width: 767px) {
                margin-bottom: 10px;
              }

              &-top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                gap: 5px;
                margin-bottom: 4px;
              }

              p {
                letter-spacing: 0.25px;
                color: rgba($blueDark, 0.5);
              }

              h4 {
                @include fluidFont(20, 14, 24px);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }

              .v-btn {
                width: 17px;
                height: 17px;
                opacity: 1;
                flex-shrink: 0;

                .v-btn__content {
                  .v-icon {
                    @include fluidFont(17, 17, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }

            .modal__body {
              display: grid;
              grid-gap: 24px;

              @media (max-width: 767px) {
                grid-gap: 18px;
              }

              h3 {
                @include fluidFont(16, 10, 24px);
                letter-spacing: 0.15px;
                font-weight: 500;
                margin-bottom: 10px;

                @media (max-width: 767px) {
                  margin-bottom: 6px;
                }
              }

              &-item {
                &-inner {
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  gap: 12px;

                  @media (max-width: 767px) {
                    gap: 8px;
                  }
                }

                .rating-button {
                  min-height: 44px;
                  min-width: 58px;
                  border: 1px solid rgba(230, 230, 230, 1);
                  background-color: #fff;
                  @include fluidFont(14, 10, 14px);
                  letter-spacing: 0.09px;
                  font-weight: 500;
                  border-radius: 10px;
                  padding: 10px 16px;
                  transition: background-color 0.3s ease, border-color 0.3s ease,
                    color 0.3s ease;

                  @media (max-width: 767px) {
                    padding: 6px 10px;
                    min-height: 28px;
                    min-width: 38px;
                    border-radius: 6px;
                  }

                  &.active,
                  &:hover {
                    border-color: rgba(255, 165, 0, 1);
                  }

                  &.active {
                    background-color: #ffa500;
                    color: #fff;
                  }
                }
              }

              .input_field {
                margin: 0;

                label {
                  margin-bottom: 4px;
                  display: block;
                  font-weight: 400;

                  @media (max-width: 767px) {
                    font-size: 10px;
                  }
                }

                .v-input__control {
                  .v-field {
                    border: 1px solid rgba(128, 130, 159, 0.4);
                    border-radius: 8px;
                    .v-field__field {
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.04px;
                        min-height: 80px;
                        padding: 12px 16px;

                        @media (max-width: 767px) {
                          padding: 8px 10px;
                          font-size: 8px;
                        }
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }

            .modal__footer {
              display: flex;
              align-items: center;
              gap: 12px;
              flex-wrap: wrap;
              margin-top: 24px;

              @media (max-width: 767px) {
                margin-top: 18px;
              }

              button {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
}

/* Job Review Modal */
.job_review_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 16px;

      .custom__modal__content {
        padding: 24px 24px 40px;
        height: auto;
        color: rgba($blueDark, 1);

        @media (max-width: 767px) {
          padding: 15px;
        }

        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .modal__header {
              display: flex;
              flex-direction: column;
              padding-bottom: 22px;
              margin-bottom: 22px;
              border-bottom: 0.66px dashed #8687a5;

              .modal__close {
                margin-left: auto;
                width: 32px;
                height: 32px;
                opacity: 1;
                flex-shrink: 0;
                margin-bottom: 30px;

                @media (max-width: 767px) {
                  margin-bottom: 20px;
                }

                .v-btn__content {
                  .v-icon {
                    @include fluidFont(26, 26, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }

              &-top {
                display: grid;
                grid-template-columns: 60px 1fr;
                grid-gap: 12px 16px;
                align-items: flex-start;

                @media (max-width: 767px) {
                  grid-template-columns: 50px 1fr;
                }
              }

              h2 {
                @include fluidFont(20, 16, 25px);
                letter-spacing: 0.25px;
              }

              &-image {
                width: 100%;
                aspect-ratio: 1;
                position: relative;
                border-radius: 100%;
                overflow: hidden;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              &-rating-wrapper {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;
              }

              &-rating {
                background-color: #f3f3f6;
                border-radius: 6px;
                padding: 10px 14px;
                @include fluidFont(16, 14, 20px);
                font-weight: 700;
                letter-spacing: 0.12px;
                display: flex;
                align-items: center;
                gap: 8px;
                justify-self: flex-start;
                flex-wrap: wrap;

                @media (max-width: 767px) {
                  grid-column: span 2;
                  padding: 8px 10px;
                }

                .v-btn {
                  width: 20px;
                  height: 20px;
                  --v-btn-size: 12px;
                }
              }

              &-notification-wrapper {
                position: relative;
                flex-shrink: 1;
                margin-left: auto;
              }

              &-notification {
                opacity: 1;
                background: #f3f3f6;
                min-width: 40px;
                border-radius: 6px;

                @media (max-width: 767px) {
                  min-width: 38px;
                  height: 38px !important;
                  width: 38px !important;
                }

                &-badge {
                  position: absolute;
                  top: 0;
                  right: 0;
                  min-width: 20px;
                  min-height: 20px;
                  border-radius: 100%;
                  color: #fff;
                  background: rgba(255, 165, 0, 1);
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: translateY(-50%);
                  pointer-events: none;

                  @media (max-width: 767px) {
                    font-size: 10px;
                    min-width: 18px;
                    min-height: 18px;
                    line-height: 18px;
                  }
                }
              }
            }

            .modal__body {
              display: grid;
              grid-gap: 26px;

              @media (max-width: 767px) {
                grid-gap: 20px;
                padding: 20px 0;
              }

              &-item {
                &-title {
                  display: grid;
                  grid-template-columns: 1fr auto;
                  grid-gap: 10px;
                  align-items: center;
                  font-weight: 600;
                  letter-spacing: 0.15px;
                  margin-bottom: 10px;

                  @media (max-width: 767px) {
                    font-size: 14px;
                  }
                }

                .v-progress-linear {
                  .v-progress-linear__determinate {
                    border-radius: inherit;
                  }

                  .v-progress-linear__background {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Job Review Comment Modal */
.job_review_comment_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;

      .custom__modal__content {
        padding: 24px;
        height: auto;
        color: rgba($blueDark, 1);

        @media (max-width: 767px) {
          padding: 15px;
        }

        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .modal__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              margin-bottom: 26px;

              .modal__close {
                margin-left: auto;
                width: 32px;
                height: 32px;
                opacity: 1;
                flex-shrink: 0;
              }

              h2 {
                @include fluidFont(18, 18, 24px);
                letter-spacing: 0.15px;
              }
            }

            .modal__body {
              .v-expansion-panel-title {
                padding: 10px 20px;
                min-height: 38px;
                @include fluidFont(14, 14, 1);
                font-weight: 700;
                gap: 10px;
              }

              .v-expansion-panel-title__overlay {
                display: none;
              }

              .v-expansion-panel__shadow {
                display: none;
              }

              .v-expansion-panel-text__wrapper {
                padding: 0 20px 20px;
                @include fluidFont(14, 14, 20px);
                letter-spacing: 0.04px;
                font-weight: 500;
              }

              .modal__comment-accordion-content {
                padding-right: 28px;
              }

              .modal__comment-accordion-content-footer {
                @include fluidFont(12, 12, 15px);
                color: rgba(12, 15, 74, 0.68);
                border-top: 0.66px dashed rgba(134, 135, 165, 1);
                margin-top: 12px;
                padding-top: 8px;
                font-weight: 400;
              }

              .v-expansion-panel {
                background-color: rgba(12, 15, 74, 0.05);
                color: $blueDark;
                border-radius: 8px !important;
                box-shadow: none !important;
                margin: 0 !important;
              }

              .v-expansion-panels {
                gap: 10px;
              }

              .v-expansion-panel:not(:first-child)::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

/* property service plan modal */
.service_plan_modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 20px;

      @include respond(s720) {
        border-radius: 6px;
      }

      .custom__modal__content {
        padding: 24px;
        height: auto;
        color: rgba($blueDark, 1);

        @include respond(s720) {
          padding: 14px 18px;
        }

        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .modal__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              margin-bottom: 26px;

              .modal__close {
                margin-left: auto;
                width: 32px;
                height: 32px;
                opacity: 1;
                flex-shrink: 0;
              }

              h2 {
                @include fluidFont(18, 18, 24px);
                letter-spacing: 0.15px;
              }
            }

            .modal__body {
              .empty__content {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .empty__banner {
                  max-width: 480px;
                  width: 100%;
                  margin: 0 auto;

                  .empty__banner__img {
                    max-width: 218px;
                    width: 100%;
                    margin: 0 auto 40px;

                    @include respond(s720) {
                      width: 100%;
                      max-width: 150px;
                      margin-bottom: 28px;
                    }

                    img {
                      width: 100%;
                      height: auto;
                      object-fit: contain;
                    }
                  }

                  .empty__banner__content {
                    h4 {
                      @include fluidFont(28, 28, 1.3);
                      font-weight: 500;
                      color: rgba($blueDark, 1);
                      text-align: center;
                    }

                    p {
                      margin-top: 16px;
                      @include fluidFont(14, 14, 1.3);
                      font-weight: 500;
                      color: rgba($blueDark, 0.5);
                      letter-spacing: 0.15px;
                      text-align: center;
                    }

                    .empty__banner__content__list {
                      margin-top: 24px;
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      align-items: flex-start;
                      padding: 0 28px;

                      @include respond(s720) {
                        padding: 0;
                      }

                      .list__item {
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        justify-content: flex-start;
                        .v-icon {
                          @include fluidFont(24, 24, 1);
                          color: rgba($skyBlue, 1);
                        }
                        p {
                          @include fluidFont(14, 14, 1.3);
                          font-weight: 400;
                          color: rgba($blueDark, 1);
                          letter-spacing: 0.4px;
                          margin: 0;
                          text-align: left;
                        }
                      }
                    }
                  }
                  .empty__banner__footer {
                    margin-top: 24px;
                    text-align: center;

                    .button-normal {
                      margin-top: 16px;
                      color: rgba(12, 15, 74, 0.5);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Add New Safety Modal  */
.add-new-safety-modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 8px;
      .custom__modal__content {
        padding: 20px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              padding-bottom: 20px;
              border-bottom: 0.66px dashed rgba(134, 135, 165, 1);

              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
              }

              .v-btn {
                width: 16px;
                height: 16px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
            .modal_form {
              margin-top: 20px;
              .form_wrapper {
                .list_block__item {
                  display: grid;
                  grid-template-columns: repeat(2, minmax(0, 1fr));
                  grid-gap: 20px;
                  border-bottom: 0.66px dashed rgba(134, 135, 165, 1);
                  padding-bottom: 20px;
                  margin-bottom: 20px;
                  align-items: flex-start;

                  @media (max-width: 767px) {
                    grid-gap: 16px;
                  }

                  .full-width {
                    grid-column: span 2;
                  }

                  .event_field__group {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-gap: 0 20px;
                    align-items: flex-start;

                    @media (max-width: 767px) {
                      grid-gap: 0 16px;
                    }
                  }

                  .list_block__item_title {
                    @include fluidFont(16, 16, 1);
                    font-weight: 700;
                    color: rgba($blueDark, 1);
                  }

                  .event_field {
                    label {
                      @include fluidFont(14, 14, 16px);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      margin-bottom: 8px;
                      display: block;
                    }

                    .v-input {
                      margin: 0;

                      .v-input__control {
                        border: 1px solid rgba($buttonText, 0.4);
                        border-radius: 8px;
                        .v-field {
                          padding: 0;
                          --v-input-control-height: 40px;

                          .v-field__field {
                            .v-label {
                              display: none;
                            }
                            .v-field__input {
                              padding: 12px 16px;
                              min-height: 40px;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              overflow: auto;
                              display: block;
                              scrollbar-width: none;

                              &::placeholder {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }

                              .v-select__selection {
                                padding: 0 16px;
                              }

                              ::-internal-datetime-container {
                                width: 100%;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }

                    .v-select .v-field .v-field__input {
                      padding: 13px 0 12px !important;

                      input {
                        padding: 0 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Safety Check Details Modal */
.safety-check-details-modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 8px;
      .custom__modal__content {
        padding: 20px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              padding-bottom: 20px;
              border-bottom: 0.66px dashed rgba(134, 135, 165, 1);

              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
              }

              .v-btn {
                width: 16px;
                height: 16px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }
            .modal_form {
              padding: 20px 0;
              border-bottom: 0.66px dashed rgba(134, 135, 165, 1);

              .form_wrapper {
                .list_block__item {
                  display: grid;
                  grid-gap: 16px;

                  .event_field {
                    label {
                      @include fluidFont(14, 14, 16px);
                      font-weight: 600;
                      color: rgba($blueDark, 1);
                      margin-bottom: 8px;
                      display: block;
                    }

                    .v-input {
                      margin: 0;

                      .v-input__control {
                        border: 1px solid rgba($buttonText, 0.4);
                        border-radius: 8px;
                        .v-field {
                          padding: 0;
                          --v-input-control-height: 40px;

                          .v-field__field {
                            .v-label {
                              display: none;
                            }
                            .v-field__input {
                              padding: 12px 16px;
                              min-height: 40px;
                              @include fluidFont(12, 12, 1.2);
                              font-weight: 400;
                              color: rgba($blueDark, 1);
                              overflow: auto;
                              display: block;
                              scrollbar-width: none;

                              &::placeholder {
                                @include fluidFont(12, 12, 1.2);
                                font-weight: 400;
                                color: rgba($blueDark, 1);
                              }

                              .v-select__selection {
                                padding: 0 16px;
                              }

                              ::-internal-datetime-container {
                                width: 100%;
                              }
                            }
                          }
                          .v-field__outline {
                            display: none;
                          }
                        }
                      }
                    }

                    .v-select .v-field .v-field__input {
                      padding: 13px 0 12px !important;

                      input {
                        padding: 0 16px;
                      }
                    }
                  }
                }
              }
            }

            .safety-check-details-modal__block-service-plan {
              .v-selection-control--density-default {
                --v-selection-control-size: 14px;
              }

              .v-selection-control-group--inline {
                gap: 26px;
              }

              .v-selection-control--inline {
                gap: 10px;
              }

              .v-label {
                opacity: 1;
                @include fluidFont(14, 14, 1);
                font-weight: 500;
                color: $blueDark;
              }

              .v-btn {
                padding: 0;
                height: auto;
                min-height: 1px;
                text-transform: none;
                color: #4d5ef3;
                font-weight: 600;
                @include fluidFont(12, 12, 12px);

                .v-btn__overlay,
                .v-btn__underlay {
                  display: none;
                }

                .v-btn__append {
                  margin-inline: 0;
                }
              }
            }

            .safety-check-details-modal__block {
              padding: 20px 0;
              border-bottom: 0.66px dashed rgba(134, 135, 165, 1);

              &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
              }

              .safety-check-details-modal__block-header {
                @include fluidFont(16, 16, 1);
                font-weight: 700;
                color: rgba($blueDark, 1);
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
                flex-wrap: wrap;

                .button {
                  height: auto;
                  min-height: 32px;
                  border-radius: 4px;

                  .v-btn__content {
                    @include fluidFont(12, 12, 1);
                  }
                }
              }

              &-inner {
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
              }
            }

            .safety-check-details-modal__card {
              background-color: #f1f6fb;
              border-radius: 8px;
              padding: 10px;
              @include fluidFont(14, 14, 14px);

              &-header {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 20px;
                align-items: center;
              }

              &-title {
                display: flex;
                justify-content: flex-start;
                //grid-template-columns: 24px 1fr;
                align-items: center;
                gap: 4px;
              }

              &-footer {
                margin-top: 10px;
                padding-top: 10px;
                border-top: 0.66px dashed #8687a5;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 10px;

                @media (min-width: 768px) and (max-width: 992px) {
                  flex-direction: column;
                  align-items: flex-start;
                }

                .v-btn {
                  padding: 0;
                  height: auto;
                  min-height: 1px;
                  text-transform: none;
                  color: rgb($orange, 1);
                  font-weight: 600;
                  @include fluidFont(11, 11, 12px);

                  .v-btn__overlay,
                  .v-btn__underlay {
                    display: none;
                  }

                  .v-btn__append {
                    margin-inline: 0;
                  }
                }
              }

              &-date {
                color: #fff;
                @include fluidFont(12, 12, 14px);
                letter-spacing: 0.24px;
                font-weight: 600;
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                min-height: 24px;
                padding: 4px 6px;
                background-color: #036700;

                &.is-red {
                  background-color: #f7422e;
                }
              }

              &-divider {
                height: 20px;
                width: 1px;
                flex-shrink: 0;
                background-color: rgba(12, 15, 74, 0.54);
                margin: 0 auto;

                @media (min-width: 768px) and (max-width: 992px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Compliance Record Modal  */
.compliance-record-modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 8px;
      .custom__modal__content {
        padding: 20px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              padding-bottom: 20px;
              margin-bottom: 20px;
              border-bottom: 0.66px dashed rgba(134, 135, 165, 1);

              h4 {
                @include fluidFont(20, 20, 1.2);
                font-weight: 700;
                color: rgba($blueDark, 1);
              }

              .v-btn {
                width: 16px;
                height: 16px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }

            .compliance-record-modal__sub-title {
              @include fluidFont(16, 16, 1);
              color: rgba($blueDark, 1);
              font-weight: 700;
              margin-bottom: 20px;
            }

            .compliance-record-modal__content {
              @include fluidFont(14, 14, 1);
              color: rgba(12, 15, 74, 0.6);
              display: grid;
              grid-gap: 10px;
              font-weight: 700;

              strong {
                color: rgba($blueDark, 1);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

/* Property Service Plan Modal */
.property-service-plan-modal {
  :deep(.v-overlay__content) {
    .main {
      border-radius: 10px;
      .custom__modal__content {
        padding: 36px;
        height: auto;
        .custom__modal__body__content {
          .modal_content {
            height: auto;
            .header {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 56px;

              .v-btn {
                width: 16px;
                height: 16px;
                opacity: 1;
                .v-btn__content {
                  .v-icon {
                    @include fluidFont(16, 16, 1);
                    color: rgba($blueDark, 1);
                  }
                }
              }
            }

            .property-service-plan-modal__content {
              text-align: center;
              font-weight: 500;
              color: rgba($blueDark, 1);

              strong {
                font-weight: 700;
              }

              h4 {
                @include fluidFont(34, 34, 42px);
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                font-weight: 600;
                padding: 0 6px;
                margin-bottom: 36px;
              }
            }

            .property-service-plan-modal__pay {
              background-color: #f3f3f6;
              border-radius: 8px;
              padding: 24px;
              font-weight: 400;
              display: grid;
              gap: 8px;
              margin-top: 56px;

              strong {
                @include fluidFont(30, 30, 36px);
                letter-spacing: 0.15px;
              }
            }

            .property-service-plan-modal__footer {
              margin-top: 42px;
            }
          }
        }
      }
    }
  }
}
</style>
