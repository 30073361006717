<template>
  <WizardModal
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="data"
    @on-close="onClose"
  >
    <template #content>
      <main class="tw-w-full rmd:tw-w-[50%]">
        <v-form ref="addressFormRef" :rules="addressRule" @submit.prevent>
          <div v-if="isShowAddressData">
            <div class="tw-flex tw-flex-col tw-gap-1">
              <Text variant="h6">Address line</Text>
              <v-text-field
                density="compact"
                variant="outlined"
                class="c-input rounded-full"
                v-model="addressForm.address"
                :rules="addressRule.address"
                :disabled="!isChangeManualAddress"
              ></v-text-field>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-1">
              <Text variant="h6">City / Town</Text>
              <v-text-field
                density="compact"
                variant="outlined"
                class="c-input rounded-full"
                v-model="addressForm.place"
                :rules="addressRule.place"
                :disabled="!isChangeManualAddress"
              ></v-text-field>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1 postcode__wrapper">
            <Text variant="h6">Postcode</Text>
            <v-text-field
              density="compact"
              placeholder="e.g E1 1BA"
              variant="outlined"
              class="c-input rounded-full tw-mb-2"
              v-model="addressForm.postCode"
              :rules="addressRule.postCode"
              :disabled="!isChangeManualAddress && isShowAddressData"
            ></v-text-field>
            <ul
              v-if="addressListFromPostcode?.length && !isShowAddressData"
              class="list tw-w-full tw-flex tw-flex-col tw-gap-3 tw-bg-[rgba(12,15,74,0.05)] tw-rounded-xl tw-h-[239px] tw-overflow-auto tw-px-5 tw-py-7 tw-box-border"
            >
              <li
                v-for="{ DPA } in addressListFromPostcode"
                :key="DPA?.UPRN"
                :class="{ 'is-active': selectedUPRN === DPA?.UPRN }"
                @click="onSelectedAddress(DPA)"
              >
                <div>{{ DPA?.ADDRESS }}</div>
              </li>
            </ul>
          </div>
          <span class="error_msg" v-if="errorMessagePostcode">{{
            errorMessagePostcode
          }}</span>
          <v-btn
            v-if="!isShowAddressData && !addressListFromPostcode.length"
            class="button disabled tw-mt-4 rmd:tw-mt-6"
            :class="{ 'button-orange': isDisabledNextButton }"
            :loading="isActiveNextButton"
            :disabled="!isDisabledNextButton"
            @click.enter="onClickFindAddress"
            size="large"
            block
          >
            {{ selectedDPA ? "continue" : "find address" }}
          </v-btn>
          <v-btn
            v-if="isShowAddressData"
            class="button disabled tw-mt-4 rmd:tw-mt-6"
            :class="{ 'button-orange': isDisabledNextButton }"
            size="large"
            block
            :disabled="!isDisabledNextButton"
            :loading="loadingContinue"
            @click.enter="onClickAddressContinue"
          >
            Confirm
          </v-btn>
          <div
            v-if="!isShowAddressData"
            class="onboarding-not-service !tw-mt-3 rmd:!tw-mt-4"
          >
            <Text variant="p">
              Cannot find address?
              <Text @click="showManualForm" variant="span" textColor="#FFA500"
                >Enter Manually
                <v-icon icon="mdi-chevron-right" color="#FFA500"
              /></Text>
            </Text>
          </div>
          <div
            v-if="isShowAddressData"
            class="onboarding-not-service !tw-mt-2 rmd:!tw-mt-4"
          >
            <Text variant="p">
              Address Not Correct?
              <Text @click="changeAddress" variant="span" textColor="#FFA500"
                >Change Address
                <v-icon icon="mdi-chevron-right" color="#FFA500"
              /></Text>
            </Text>
          </div>
        </v-form>
      </main>
    </template>
  </WizardModal>
</template>
<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

import WizardModal from "@/core/components/modals/WizardModal.vue";
import Text from "@/core/components/ui/general/Text.vue";
import axios from "axios";
import { VUE_APP_OS_POSTCODE_URL, VUE_APP_OS_API } from "@/config";
import { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { BusinessDetailTypeEnum, UserRolesEnum } from "@/core/enums/RolesEnum";
import UserService from "@/core/services/user.service";

const store = useStore();

const emit = defineEmits(["on-close"]);

const data = ref({
  name: "Address",
  header: "Where is your physical HQ?",
  title: "Please give the best address for your business?",
  description:
    "This is usually the address you receive business-related correspondence to!",
  hideAction: true,
});
const addressFormRef = ref(null);
const addressForm = ref({
  postCode: "",
  address: "",
  place: "",
  latitude: "",
  longitude: "",
});
const selectedUPRN = ref("");
const selectedDPA = ref(null);
const isManualForm = ref(false);
const isShowAddressData = ref(false);
const billingAddressForm = ref(null);
const addressListFromPostcode = ref([]);
const errorMessagePostcode = ref("");
const isActiveNextButton = ref(false);
const isChangeManualAddress = ref(false);
const loadingContinue = ref(false);

const addressRule = ref({
  address: [(v) => !!v || "Address is required"],
  place: [(v) => !!v || "City/Town is required"],
  postCode: [(v) => !!v || "PostCode is required"],
});

const isDisabledNextButton = computed(() =>
  /^[a-zA-Z0-9\s]+$/.test(addressForm.value.postCode)
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isLimitedCompany = computed(() => {
  return (
    activeUserWorkstation.value?.workstationAccountType?.code ===
    BusinessDetailTypeEnum.LIMITED_COMPANY
  );
});

const isPropertyOwner = computed(() => {
  return user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER;
});

const onSelectedAddress = (DPA) => {
  selectedUPRN.value = DPA?.UPRN;
  selectedDPA.value = DPA;
  addressForm.value.address = removePostCodeAndPostTownInFullAddress(
    DPA.POSTCODE,
    DPA.POST_TOWN,
    DPA.ADDRESS
  );
  addressForm.value.place = DPA.POST_TOWN;
  addressForm.value.postCode = DPA.POSTCODE;
  addressForm.value.latitude = DPA.LAT;
  addressForm.value.longitude = DPA.LNG;
  billingAddressForm.value = DPA;
  if (billingAddressForm.value) {
    isShowAddressData.value = true;
  }
};

const removePostCodeAndPostTownInFullAddress = (
  postcode,
  postTown,
  address
) => {
  if (!postcode && !address && !postTown) {
    console.log("Please enter a word to remove and the address.");
    return;
  }

  const concatRemovedWord = `, ${postTown}, ${postcode}`;

  // Escape special characters in the word to be removed
  const escapedWord = concatRemovedWord.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create a regular expression to match the word to be removed
  const regex = new RegExp(`\\b${escapedWord}\\b`, "gi");

  // Remove the word from the input string
  return address.replace(regex, "").trim();
};
const onClickFindAddress = async () => {
  const isFormValid = await addressFormRef.value.validate();
  if (!isFormValid.valid) return;
  if (billingAddressForm.value) {
    isShowAddressData.value = true;
  } else {
    try {
      isActiveNextButton.value = true;

      const response = await UserService.fetchAddressFromPostCode(
        addressForm.value.postCode
      );
      addressListFromPostcode.value = response.data.results;
      if (response.status === 200) {
        errorMessagePostcode.value = "";
        addressForm.value.address = response.data?.result?.primary_care_trust;
        addressForm.value.place = response.data?.result?.region;
      } else if (response.status === 400) {
        errorMessagePostcode.value = "Invalid postcode";
        console.error("Failed to fetch address data");
      }
    } catch (error) {
      errorMessagePostcode.value = "Invalid postcode";
      console.error("Error fetching address data:", error);
    } finally {
      isActiveNextButton.value = false;
    }
  }
};
const onClickAddressContinue = async () => {
  const isFormValid = await addressFormRef.value.validate();
  if (!isFormValid.valid) return;
  loadingContinue.value = true;
  const userBusinessDetails = user.value?.userBusinessDetails;

  delete userBusinessDetails.dateUpdated;
  delete userBusinessDetails.dateCreated;
  delete userBusinessDetails.latitude;
  delete userBusinessDetails.longitude;

  let formData = {};
  try {
    if (isLimitedCompany.value) {
      delete userBusinessDetails.businessAddress;
      delete userBusinessDetails.businessPostcode;
      if (isManualForm.value) {
        formData = {
          businessAddress: `${addressForm.value.address} ${addressForm.value.place}`,
          businessPostcode: addressForm.value.postCode,
          type: user.value?.userBusinessDetails?.type,
          userVerificationStatus:
            user.value?.userBusinessDetails?.userVerificationStatus,
          ...userBusinessDetails,
        };
      } else {
        formData = {
          businessAddress: selectedDPA.value?.ADDRESS,
          businessPostcode: addressForm.value.postCode,
          latitude: addressForm.value.latitude.toString(),
          longitude: addressForm.value.longitude.toString(),
          type: user.value?.userBusinessDetails?.type,
          userVerificationStatus:
            user.value?.userBusinessDetails?.userVerificationStatus,
          ...userBusinessDetails,
        };
      }
    } else {
      delete userBusinessDetails.residencyAddressLookup;
      delete userBusinessDetails.residencyAddressLine;
      delete userBusinessDetails.residencyTown;
      delete userBusinessDetails.residencyPostcode;
      if (isManualForm.value) {
        formData = {
          residencyAddressLookup: "",
          residencyAddressLine: addressForm.value.address,
          residencyTown: addressForm.value.place,
          residencyPostcode: addressForm.value.postCode,
          type: user.value?.userBusinessDetails?.type,
          userVerificationStatus:
            user.value?.userBusinessDetails?.userVerificationStatus,
          ...userBusinessDetails,
        };
      } else {
        formData = {
          residencyAddressLookup: selectedDPA.value?.ADDRESS,
          residencyAddressLine: addressForm.value.address,
          residencyTown: addressForm.value.place,
          residencyPostcode: addressForm.value.postCode,
          latitude: addressForm.value.latitude.toString(),
          longitude: addressForm.value.longitude.toString(),
          type: user.value?.userBusinessDetails?.type,
          userVerificationStatus:
            user.value?.userBusinessDetails?.userVerificationStatus,
          ...userBusinessDetails,
        };
      }
    }
    if (!selectedDPA.value) return;

    await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
      userId: user.value.id,
      formData,
    });

    await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

    store.dispatch(`${USER_STORE}/initializeUserState`);
  } catch (error) {
    console.log("error:", error);
  } finally {
    loadingContinue.value = false;
    selectedDPA.value = null;
    selectedUPRN.value = "";
    onClose();
  }
};

watch(
  () => addressForm.value.postCode,
  (newValue) => {
    if (newValue === null || newValue === "") {
      addressListFromPostcode.value = [];
    }
  },
  { deep: true, immediate: true }
);

const showManualForm = () => {
  isChangeManualAddress.value = true;
  isShowAddressData.value = true;
  isManualForm.value = true;
  resetForm();
};

const changeAddress = () => {
  isShowAddressData.value = false;
  isManualForm.value = false;
  selectedDPA.value = null;
  selectedUPRN.value = "";
  resetForm();
};

const resetForm = () => {
  addressForm.value.address = "";
  addressForm.value.place = "";
  addressForm.value.postCode = "";
  addressForm.value.latitude = "";
  addressForm.value.longitude = "";
  billingAddressForm.value = null;
};

const onClose = () => {
  emit("on-close");
};
</script>
<style lang="scss" scoped>
li {
  color: rgba($blueDark, 1);
  @include fluidFont(14, 14, 1);
  text-align: left;
  padding: 4px 12px;
  border-radius: 30px !important;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;

  &:hover {
    background-color: rgba($orange, 1);
    color: rgba($white, 1);
    border-radius: 20px;
  }
}

li.is-active {
  background-color: rgba(255, 165, 0, 0.6);
  color: #fff;
  border-radius: 20px;
}
</style>
