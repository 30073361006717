<template>
  <commonDialog
    HeaderTittle="Residential Address"
    title="What’s your residential address"
    description="This is required for our compliance requirements."
    :onClickBackButton="onClickBackButton"
    class="carousel__dialog custom__modal__box"
    height="100%"
  >
    <template v-slot:body>
      <div class="">
        <div class="modal__inner__body">
          <v-form ref="postcodeform" :rules="rules" @submit.prevent>
            <div v-if="isShowAddressData">
              <div class="input__file">
                <label>Address line</label>
                <v-text-field
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full"
                  v-model="address"
                  disabled
                ></v-text-field>
              </div>
              <div class="input__file">
                <label>City / Town</label>
                <v-text-field
                  density="compact"
                  variant="outlined"
                  class="c-input rounded-full"
                  v-model="place"
                  disabled
                ></v-text-field>
              </div>
            </div>
            <div class="input__file">
              <label>Postcode</label>
              <v-text-field
                density="compact"
                placeholder="e.g E1 1BA"
                variant="outlined"
                class="c-input rounded-full"
                v-model="postCode"
                :rules="rules.postCode"
                :disabled="isShowAddressData ? true : false"
              ></v-text-field>
              <ul
                v-if="addressListfromPostcode?.length && !isShowAddressData"
                class="address__postcodes"
              >
                <li
                  v-for="{ DPA } in addressListfromPostcode"
                  :key="DPA?.UPRN"
                  @click="handleSelectAddress(DPA)"
                >
                  <div>{{ DPA?.ADDRESS }}</div>
                </li>
              </ul>
            </div>
            <span class="error_msg" v-if="errorMessagePostcode">{{
              errorMessagePostcode
            }}</span>
            <v-btn
              v-if="!isShowAddressData"
              class="button disabled"
              :class="{ 'button-orange': isDisabledNextButton }"
              size="large"
              block
              :loading="isActiveNextButton"
              :disabled="!isDisabledNextButton"
              @click.enter="GoToFindAddress"
            >
              find address
            </v-btn>
            <v-btn
              v-if="isShowAddressData"
              class="button disabled"
              :class="{ 'button-orange': isDisabledNextButton }"
              size="large"
              block
              :disabled="!isDisabledNextButton"
              :loading="loadingContinue"
              @click.enter="onClickContinue"
            >
              CONTINUE
            </v-btn>
            <div v-if="isShowAddressData" class="onboarding-not-service">
              <p>
                Address Not Correct?
                <span @click="changeAddress"
                  >Change Address <v-icon icon="mdi-chevron-right"
                /></span>
              </p>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </commonDialog>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { VUE_APP_OS_API, VUE_APP_OS_POSTCODE_URL } from "@/config";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CommonDialog from "@/core/components/CommonDialog.vue";
import UserService from "@/core/services/user.service";
export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const router = useRouter();
    const store = useStore();
    const postCode = ref("");
    const address = ref("");
    const place = ref("");
    const postcodeform = ref(null) as any;
    const errorMessagePostcode = ref("");
    const addressListfromPostcode = ref([]) as any;
    const billingAddressForm = ref(null);
    const isShowAddressData = ref(false);
    const isActiveNextButton = ref(false);
    const loadingContinue = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const onClickBackButton = () => {
      ctx.emit("onClickCloseTab");
    };

    const rules = ref({
      postCode: [(v: string) => !!v || "PostCode is required"],
    });

    const isDisabledNextButton = computed(() => {
      return /^[a-zA-Z0-9\s]+$/.test(postCode.value);
    });

    const GoToFindAddress = async (e: any) => {
      e.preventDefault();
      const isFormValid = await postcodeform.value.validate();
      if (isFormValid.valid) {
        if (billingAddressForm.value) {
          isShowAddressData.value = true;
        } else {
          try {
            isActiveNextButton.value = true;
            // Make an HTTP GET request to the API endpoint
            const response = await UserService.fetchAddressFromPostCode(
              postCode.value
            );
            addressListfromPostcode.value = response.data.results;

            // Check if the request was successful
            if (response.status === 200) {
              errorMessagePostcode.value = "";
              address.value = response.data?.result?.primary_care_trust;
              place.value = response.data?.result?.region;
            } else if (response.status === 400) {
              // Handle error cases
              errorMessagePostcode.value = "Invalid postcode";
              console.error("Failed to fetch address data");
            }
          } catch (error) {
            // Handle any errors that occur during the HTTP request
            errorMessagePostcode.value = "Invalid postcode";
            console.error("Error fetching address data:", error);
          } finally {
            isActiveNextButton.value = false;
          }
        }
      }
    };

    const handleSelectAddress = (DPA: any) => {
      address.value = DPA.THOROUGHFARE_NAME
        ? DPA.THOROUGHFARE_NAME
        : DPA.ORGANISATION_NAME;
      place.value = DPA.POST_TOWN;
      postCode.value = DPA.POSTCODE;
      billingAddressForm.value = DPA;
    };

    const changeAddress = () => {
      postCode.value = "";
      errorMessagePostcode.value = "";
      addressListfromPostcode.value = [];
      billingAddressForm.value = null;
      isShowAddressData.value = false;
    };

    const onClickContinue = async () => {
      if (!postCode.value) return;
      loadingContinue.value = true;
      try {
        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value.id,
          userData: {
            postcode: postCode.value,
            address: address.value + "," + place.value,
          },
        });
      } catch (error) {
        console.log("error:", error);
      } finally {
        loadingContinue.value = false;
        ctx.emit("onClickCloseTab");
      }
    };

    return {
      postCode,
      address,
      place,
      postcodeform,
      errorMessagePostcode,
      addressListfromPostcode,
      billingAddressForm,
      onClickBackButton,
      VUE_APP_OS_API,
      VUE_APP_OS_POSTCODE_URL,
      rules,
      isDisabledNextButton,
      isShowAddressData,
      GoToFindAddress,
      handleSelectAddress,
      isActiveNextButton,
      changeAddress,
      onClickContinue,
      loadingContinue,
    };
  },
};
</script>
<style lang="scss" scoped>
.description {
  display: block;
}
@import "@/modules/owner-onboarding/styles/OwnerOnboarding.scss";
</style>
