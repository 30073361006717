<template>
  <div v-if="getCurrentMessageType === 'chat'" class="mainchatbox__wrapper">
    <div class="chatbox__wrapper" :class="{ chatbox_active: isOpenChatBox }">
      <v-card class="chatbox__inner" variant="flat">
        <template v-slot:text>
          <div
            class="chatbox__top"
            :class="isCreateChat ? 'back__section' : ''"
            @click="toggleChatBox"
          >
            <div class="chatbox__left">
              <v-icon
                icon="mdi-chevron-left"
                @click.stop="handleBackButton"
                v-if="isCreateChat"
              />
              <div class="chatbox__heading">
                <h5>Chat</h5>
                <span v-if="totalUnreadMessageCount">
                  {{ totalUnreadMessageCount }}
                </span>
              </div>
            </div>
            <div class="chatbox__right">
              <v-icon icon="mdi-chevron-down"></v-icon>
            </div>
          </div>
          <!-- chat box section-->
          <div class="chatbox__section">
            <div v-if="!isLoading" class="chatbox__selection_inner">
              <v-text-field
                v-if="isCreateChat && !isCreateNewGroupChat"
                class="chatbox__search create_group__search"
                v-model="searchNetworkUsers"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="solo-filled"
                hide-details
                single-line
                flat
                placeholder="Search"
              ></v-text-field>

              <div
                class="chatbox__message"
                v-if="!isCreateNewGroupChat && !isCreateChat"
              >
                <div class="chatbox__center">
                  <v-text-field
                    class="chatbox__search"
                    v-model="searchChats"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="solo-filled"
                    hide-details
                    single-line
                    flat
                    placeholder="Search"
                  ></v-text-field>
                  <!-- v-if="!isProjectOwner" -->
                  <div class="chatbox__edit" @click="onCreateNewChat">
                    <img
                      :src="require('@/assets/icons/' + 'edit-chat.svg')"
                      alt=""
                    />
                  </div>
                </div>
                <!-- v-if="!isAppIknowa" -->
                <!-- <div class="chatbox__list krystal_chat">
                  <div class="chatbox__bottom" @click.capture="openKrystalChat">
                    <div class="chatbox__bottom__chats">
                      <div class="chatbox__bottom__chats__list">
                        <div class="chatbox__left">
                          <div class="chatbox__img">
                            <img
                              :src="
                                require('@/assets/images/krystal-large.png')
                              "
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="chatbox__center">
                          <div class="chatbox__heading">
                            <div class="chatbox__title">
                              <h6>KRYSTAL</h6>
                              <v-icon
                                icon="mdi-check-decagram"
                                size="x-small"
                                class="tw-text-xs"
                                color="#4B6CCD"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="chatbox__right">
                          <span>{{
                            moment(
                              getKrystalConversationLastAnswer?.date
                            ).format("dddd")
                          }}</span>
                        </div>
                      </div>
                      <div class="chatbox__bottom__chats__text">
                        <p>{{ getKrystalConversationLastAnswer?.text }}....</p>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div
                  v-if="selectedUserMessages?.length > 0"
                  class="chatbox__list"
                  :class="{ 'without-krystal': !isKrystalChatActive }"
                >
                  <!-- // real chat  -->
                  <div
                    class="chatbox__bottom"
                    v-for="(message, idx) in filteredMessages"
                    :key="message?.id"
                    @click="openMessageChatBox(message)"
                    @mouseleave="handleMouseLeave(idx)"
                  >
                    <div class="chatbox__bottom__chats">
                      <div
                        class="chatbox__bottom__chats__list"
                        :class="{ 'is-locked': isProjectChatLock(message) }"
                      >
                        <div class="chatbox__left">
                          <div class="chatbox__img">
                            <UserProfileLogo
                              v-if="message?.project && message?.project?.id"
                              :userName="message?.groupName"
                            />
                            <img
                              v-else
                              loading="lazy"
                              :src="
                                !message?.isGroup
                                  ? getWorkStationImagePublicUrl(
                                      message?.isUserChat
                                        ? message?.toUser?.profileImage
                                        : message?.toWorkstationId?.profileImage
                                    )
                                  : getWorkStationImagePublicUrl(
                                      message?.groupAvatar
                                    )
                                  ? getWorkStationImagePublicUrl(
                                      message?.groupAvatar
                                    )
                                  : require('@/assets/images/user-placeholder.png')
                              "
                              alt="userWorkStation__img"
                            />
                            <div
                              v-if="!message?.isGroup"
                              :class="{
                                online: isUserOnline(
                                  message?.toWorkstationId?.id
                                ),
                                offline: !isUserOnline(
                                  message?.toWorkstationId?.id
                                ),
                              }"
                            ></div>
                          </div>
                        </div>

                        <div class="chatbox__center">
                          <div class="chatbox__heading">
                            <h6>
                              {{
                                message?.isGroup
                                  ? message?.groupName
                                  : message?.isUserChat
                                  ? message?.toUser?.firstName +
                                    " " +
                                    message?.toUser?.lastName
                                  : message?.toWorkstationId?.name
                              }}
                            </h6>
                          </div>
                        </div>
                        <div class="chatbox__right">
                          <span v-if="!isProjectChatLock(message)">{{
                            getTimeElapsedString(
                              message?.userMessages?.dateCreated
                            )
                          }}</span>

                          <div v-if="isProjectChatLock(message)">
                            <v-tooltip
                              max-width="242px"
                              activator="parent"
                              location="top"
                              class="estimate_tooltip"
                            >
                              Project Chat is locked
                            </v-tooltip>

                            <v-icon icon="mdi mdi-lock" color="#0C0F4A">
                            </v-icon>
                          </div>

                          <div
                            class="option__selector"
                            v-if="
                              message?.isGroup
                                ? message?.fromWorkstationId?.id ===
                                    activeUserWorkstation?.id &&
                                  message?.fromUser?.id === user.id
                                  ? true
                                  : false
                                : true
                            "
                          >
                            <v-icon>
                              <img
                                v-if="!isProjectChatLock(message)"
                                :src="require('@/assets/icons/dots.svg')"
                                alt=""
                                loading="lazy"
                                @click.stop="dropdownAction(idx)"
                              />
                            </v-icon>
                            <div
                              class="delete__option"
                              v-if="showDeleteDropdown[idx]"
                            >
                              <v-icon>
                                <img
                                  :src="require('@/assets/icons/delete.svg')"
                                  alt=""
                                />
                              </v-icon>
                              <span @click.stop="deleteChat(message)"
                                >Delete</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="chatbox__bottom__chats__text"
                        v-if="!isProjectChatLock(message)"
                      >
                        <p>
                          {{
                            message?.userMessages?.content
                              ? message?.userMessages?.content
                              : message?.userMessages?.content !== ""
                              ? "Start New Conversion "
                              : "File "
                          }}
                        </p>

                        <span
                          class="chatbox__notification"
                          v-if="message?.unreadMessagesCount"
                        >
                          {{
                            message?.unreadMessagesCount
                              ? message?.unreadMessagesCount
                              : 0
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="filteredMessages.length === 0">No users found</div>
                </div>
                <div v-else class="empty__chats">
                  <div class="empty__chats__message">
                    <v-icon>
                      <img src="../../../assets/icons/comment.svg" alt="" />
                    </v-icon>
                    <p>Your chats will appear here</p>
                  </div>
                </div>
              </div>
              <!-- search groups -->

              <div class="chatbox__edit__message" v-if="isCreateChat">
                <div v-if="!isCreateNewGroupChat">
                  <div class="chatbox__top" @click="onClickGroupChat()">
                    <div class="chatbox__left">
                      <div class="chatbox__img">
                        <v-icon icon="mdi-account-group"></v-icon>
                      </div>
                      <div class="chatbox__heading">
                        <h5>Create a New Group</h5>
                      </div>
                    </div>
                    <div class="chatbox__right">
                      <v-icon icon="mdi-chevron-right"></v-icon>
                    </div>
                  </div>
                </div>

                <div v-if="!isCreateNewGroupChat" class="chat_groups_list">
                  <v-tabs v-model="tab" align-tabs="center" stacked>
                    <v-tab :value="tabs[0]" height="auto">
                      <span>members {{ `(${usersList.length})` }} </span>
                    </v-tab>
                    <v-tab :value="tabs[1]" height="auto">
                      <span
                        >workStation {{ `(${workStationList.length}) ` }}</span
                      >
                    </v-tab>
                  </v-tabs>
                  <v-tabs-window v-if="tab === tabs[0]">
                    <div class="chat_groups_list_tab_content">
                      <div
                        class="chat_groups_list_item"
                        v-for="user in usersList"
                        :key="user.id"
                        @click="createMessageChatBox(user)"
                      >
                        <div class="create_group_wrapper">
                          <div class="create_group_image">
                            <img
                              loading="lazy"
                              :src="
                                getWorkStationImagePublicUrl(
                                  user.receiverWorkstation.user?.profileImage
                                )
                                  ? getWorkStationImagePublicUrl(
                                      user.receiverWorkstation.user
                                        ?.profileImage
                                    )
                                  : require('@/assets/images/user-placeholder.png')
                              "
                              alt="user_img"
                            />
                          </div>
                          <span></span>
                          <div class="create_group_name">
                            {{
                              user?.toUser?.firstName +
                              " " +
                              user?.toUser?.lastName
                            }}
                          </div>
                        </div>
                        <div class="group_status">
                          <v-icon icon="mdi-chevron-right"></v-icon>
                        </div>
                      </div>
                      <div
                        v-if="usersList.length === 0"
                        class="chat_groups_list_empty"
                      >
                        No users found
                      </div>
                    </div>
                  </v-tabs-window>

                  <v-tabs-window v-if="tab === tabs[1]">
                    <div class="chat_groups_list_tab_content">
                      <div
                        class="chat_groups_list_item"
                        v-for="user in workStationList"
                        :key="user.id"
                        @click="createMessageChatBox(user)"
                      >
                        <div class="create_group_wrapper">
                          <div class="create_group_image">
                            <img
                              loading="lazy"
                              :src="
                                getWorkStationImagePublicUrl(
                                  user.receiverWorkstation?.profileImage
                                )
                                  ? getWorkStationImagePublicUrl(
                                      user.receiverWorkstation?.profileImage
                                    )
                                  : require('@/assets/images/user-placeholder.png')
                              "
                              alt="workStation_img"
                            />
                          </div>
                          <span></span>
                          <div class="create_group_name">
                            {{ user?.receiverWorkstation?.name }}
                          </div>
                        </div>
                        <div class="group_status">
                          <v-icon icon="mdi-chevron-right"></v-icon>
                        </div>
                      </div>
                      <div
                        v-if="workStationList.length === 0"
                        class="chat_groups_list_empty"
                      >
                        No workstation found
                      </div>
                    </div>
                  </v-tabs-window>
                </div>

                <NewGroupChatComponent
                  v-if="isCreateNewGroupChat"
                  :groupNetWorkContactList="usersNetworkList"
                  :projectId="projectId"
                  :userImagesMap="userImagesMap"
                  @saveNewUserGroup="saveNewUserGroup"
                />
              </div>
            </div>
          </div>
          <div :class="[isLoading ? 'loader__wrapper' : '']">
            <CommonLoader v-if="isOpenChatBox" :loading="isLoading" />
          </div>
        </template>
      </v-card>
    </div>
    <div class="open__chat">
      <MessageChatComponent
        v-for="data in filterChatRoom"
        :key="data.userMessageRoomDetails.id"
        :projectId="projectId"
        :userMessageRoomDetails="data.userMessageRoomDetails"
        :userAllMessages="data.userMessages"
        :receiverUserWorkstation="data.receiverUserWorkstation"
        :senderUserWorkstation="data.senderUserWorkstation"
        :userImagesMap="userImagesMap"
        :groupNetWorkContactList="usersNetworkList"
        @closeMessageChatBox="closeMessageChatBox"
        @saveNewGroupMembers="saveNewGroupMembers"
        @onClickVideoCallButton="onClickVideoCallButton"
        @getUserWorkStationProfileImage="getUserWorkStationProfileImage"
      />
      <div v-if="isKrystalChatActive">
        <KrystalChatComponent @closeMessageChatBox="closeKrystalChat" />
      </div>
    </div>
    <!-- // krystal chat  -->

    <div class="chat__overlay" :class="{ overlay: isOpenChatBox }"></div>
    <VideoComponent
      v-show="videoConsultationStatus"
      :roomDetails="roomDetails"
      :isDirectJoinVideoCall="false"
      ref="videoComponent"
      @leaveCall="leaveCall"
    />
  </div>
</template>

<script lang="ts">
import { ref, computed, onBeforeUnmount, inject, watch } from "vue";
import MessageChatComponent from "@/core/components/MessageChatComponent.vue";
import TradeNetworkService from "@/core/services/trade-network.service";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import NewGroupChatComponent from "@/core/components/NewGroupChatComponent.vue";
import chatService from "@/core/services/chat.service";
import { CHATMESSAGE } from "@/store/modules/chat-message";
import { getImageApiUrl, profileImagesData } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import CommonLoader from "@/core/components/CommonLoader.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { UserMessagesRoomStatusEnum } from "@/core/enums/chatsEnums";
import DailyIframe from "@daily-co/daily-js";
import VideoComponent from "@/core/components/VideoComponent.vue";
import { CallTypesEnums } from "@/core/enums/VideoEnum";
import KrystalChatComponent from "../KrystalChatComponent.vue";
import moment from "moment";
import { MEMBER_STORE } from "@/store/modules/manage-member";

export default {
  name: "GlobalChatsComponent",
  components: {
    MessageChatComponent,
    NewGroupChatComponent,
    CommonLoader,
    UserProfileLogo,
    VideoComponent,
    KrystalChatComponent,
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
    const chatRooms = ref([]) as any;
    const isActive = ref(false);
    const isCreateChat = ref(false);
    const searchChats = ref("");
    const searchNetworkUsers = ref("");
    const isOpenChatBox = ref(false);
    const isCreateNewGroupChat = ref(false);
    const usersNetworkList = ref([]) as any;
    // const isAppIknowa = computed(() => {
    //   return window.location.hostname.startsWith("app.iknowa");
    // });

    const userImagesMap = ref({}) as any;
    const isLoading = ref(false);

    const videoConsultationStatus = ref(false);
    const roomDetails = ref({}) as any;
    const videoComponent = ref(null) as any;
    const inviteUsers = ref([]);
    const toggleChatBox = () => {
      isOpenChatBox.value = !isOpenChatBox.value;
      isCreateNewGroupChat.value = false;
      isCreateChat.value = false;
    };
    const tab = ref("members");
    const tabs = ref(["members", "workStations"]);

    const onCreateNewChat = async () => {
      isCreateChat.value = true;

      try {
        inviteUsers.value = await getTeamMember();
        await getUserWorkStationUserList();
      } catch (error) {
        console.log(error);
      }
    };
    const filterChatRoom = computed(() => {
      return chatRooms.value.slice(0, 3);
    });
    const keyExists = (key: any) => {
      return key in userImagesMap.value;
    };

    const addUserImage = (userProfileImg: any, imageUrl: string) => {
      if (!keyExists(userProfileImg)) {
        userImagesMap.value[userProfileImg] = imageUrl;
        return true;
      } else {
        return false;
      }
    };

    const filteredMessages = computed(() => {
      const searchTerm = searchChats.value.trim().toLowerCase();
      if (!searchTerm || searchTerm.length < 1) {
        return selectedUserMessages.value;
      } else {
        return selectedUserMessages.value.filter((message: any) => {
          if (message.isGroup === 1) {
            return message.groupName.toLowerCase().includes(searchTerm);
          } else {
            return (
              message.toUserWorkStationId?.name
                ?.toLowerCase()
                .includes(searchTerm) ||
              message.toUser?.firstName?.toLowerCase().includes(searchTerm) ||
              message.toUser?.lastName?.toLowerCase().includes(searchTerm)
            );
          }
        });
      }
    });
    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );

    //global chat data start
    const selectedUserMessages: any = ref([]) as any;
    const projectId = ref(null) as any;
    const getUserRoomsList = async () => {
      try {
        isLoading.value = true;
        // let userMessageRoomsData =
        //   (await chatService.getUserMessageRoomByProjectId(
        //     activeUserWorkstation.value?.id
        //   )) as any;
        let userMessageRoomsData = (await store.dispatch(
          `${CHATMESSAGE}/getUserRoomMessages`,
          activeUserWorkstation.value.id
        )) as any;
        if (userMessageRoomsData) {
          //user Image
          if (
            !keyExists(user.value?.userPublicProfile.profileImage) &&
            userImage.value
          ) {
            userImagesMap.value[user.value.userPublicProfile?.profileImage] =
              userImage.value;
          }
          //activeWorkStation Image
          if (
            !keyExists(activeUserWorkstation.value.profileImage) &&
            userWorkStationImage.value
          ) {
            userImagesMap.value[activeUserWorkstation.value.profileImage] =
              userWorkStationImage.value;
          }
          userMessageRoomsData.map((userRoom: any) => {
            if (userRoom.toWorkstationId?.profileImage)
              getUserWorkStationProfileImage(
                userRoom.toWorkstationId?.profileImage
              );
            if (userRoom.toUser?.profileImage)
              getUserWorkStationProfileImage(userRoom.toUser?.profileImage);

            if (userRoom.isGroup) {
              getUserWorkStationProfileImage(userRoom.groupAvatar);
              userRoom.userMessageGroups.map((groupMember: any) => {
                if (groupMember.userWorkStation?.profileImage)
                  getUserWorkStationProfileImage(
                    groupMember.userWorkStation?.profileImage
                  );
              });
            }
          });

          selectedUserMessages.value = getChatMessagesRooms.value;

          if (isProjectOwner.value) {
            /*project show only for property owner */
            selectedUserMessages.value = selectedUserMessages.value.filter(
              (message: any) => {
                if (message?.project?.id) {
                  if (message.status !== UserMessagesRoomStatusEnum.PENDING) {
                    return true;
                  }
                } else {
                  return true;
                }
              }
            );
          }
          // return responseData;
          return userMessageRoomsData;
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading.value = false;
      }
    };

    const saveNewUserGroup = async (formData: any) => {
      try {
        isCreateNewGroupChat.value = false;
        isCreateChat.value = false;
        await chatService.createUserGroupMessageRoom(formData);
        //Todo fix for it
        await init();
      } catch (error) {
        console.log(error);
      }
    };

    const saveNewGroupMembers = async (formData: any) => {
      try {
        await chatService.addUserGroupMessageRoom(formData);
        await init();
      } catch (error) {
        console.log(error);
      }
    };
    //global chat data end
    const createMessageChatBox = async (data: any) => {
      const receiverUserId = data.receiverWorkstation?.user?.id;
      let formData = {
        fromUser: user.value.id,
        toUser: data?.toUserId,
        isUserChat: data.isUserChat ? 1 : 0,
      } as any;

      if (!data.isUserChat) {
        formData = {
          ...formData,
          toUser: receiverUserId,
          toWorkstationId: data.receiverWorkstation.id,
        };
      }

      if (user.value.id && receiverUserId && data.receiverWorkstation?.id) {
        const isUserChatExists = selectedUserMessages.value?.find(
          (msg: any) => {
            if (msg.isUserChat) {
              return (
                msg.toWorkstationId?.id === data.receiverWorkstation.id &&
                msg.fromWorkstationId?.id === data.receiverWorkstation.id &&
                msg.toUser.id === data.toUser.id
              );
            }
            return (
              msg.toWorkstationId?.id == data.receiverWorkstation.id &&
              !msg.isGroup
            );
          }
        );
        //await init call restrict to
        if (!isUserChatExists) {
          try {
            const response = (await chatService.createUserMessageRoom(
              formData
            )) as any;
            if (response && activeUserWorkstation.value) {
              //Todo fixes
              const userRoom = {
                ...response,
                toUser: {
                  id: data.receiverWorkstation?.user?.id,
                  firstName: data.receiverWorkstation?.user?.firstName,
                  lastName: data.receiverWorkstation?.user?.lastName,
                  userBusinessDetails:
                    data.receiverWorkstation?.user?.userBusinessDetails,
                  userPublicProfile:
                    data.receiverWorkstation?.user?.userPublicProfile,
                  profileImage:
                    data.receiverWorkstation?.user?.userPublicProfile
                      ?.profileImage,
                },
                toWorkstationId: {
                  id: data.receiverWorkstation?.id,
                  name: data.receiverWorkstation?.name,
                  profileImage: data.receiverWorkstation?.profileImage,
                },
                unreadMessagesCount: 0,
                userMessageGroups: [],
                userMessages: null,
                userMessageAttachments: [],

                userWorkstation: {
                  id: data.receiverWorkstation?.id,
                  name: data.receiverWorkstation?.name,
                  profileImage: data.receiverWorkstation?.profileImage,
                },
              };
              selectedUserMessages.value.push(userRoom);

              store.commit(
                `${CHATMESSAGE}/setUserRoomMessages`,
                selectedUserMessages.value
              );
              const userMessageRoomDetails = {
                id: response?.id,
                isGroup: response?.isGroup,
                groupName: response?.groupName,
                groupAvatar: response?.groupAvatar,
                groupAvatarUrl: response?.groupAvatarUrl,
                parentId: response?.parentId,
                isUserChat: response?.isUserChat,
              };
              const userDetails = {
                userMessageRoomDetails: userMessageRoomDetails,
                receiverUserWorkstation: {
                  id: data?.receiverWorkstation?.id,
                  name: data?.receiverWorkstation?.name,
                  profileImage: data?.receiverWorkstation?.profileImage,
                  user: {
                    id: data?.receiverWorkstation.user?.id,
                    firstName: data?.receiverWorkstation.user?.firstName,
                    lastName: data?.receiverWorkstation.user?.lastName,
                    profileImage: data?.receiverWorkstation?.user?.profileImage,
                  },
                  isUserChat: data?.isUserChat,
                },
                senderUserWorkstation: {
                  id: activeUserWorkstation.value.id,
                  name: activeUserWorkstation.value.name,
                  profileImage: activeUserWorkstation.value.profileImage,
                  user: {
                    id: user.value.id,
                    firstName: user.value.firstName,
                    lastName: user.value.lastName,
                  },
                },
                userMessages: [],
              };
              chatRooms.value.unshift(userDetails);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          openMessageChatBox(isUserChatExists);
        }
        isCreateNewGroupChat.value = false;
        isCreateChat.value = false;
      }
    };
    const closeMessageChatBox = (id: any, isCloseChatBox = false) => {
      chatRooms.value = chatRooms.value.filter(
        (msg: any) => msg.userMessageRoomDetails.id !== id
      );
      if (isCloseChatBox) {
        toggleChatBox();
      }
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const getChatMessagesRooms = computed(
      () => store.getters[`${CHATMESSAGE}/getChatMessagesRooms`]
    );

    const getTeamMember = async () => {
      try {
        const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
          userId: user.value?.id,
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };
    const getUserWorkStationUserList = async () => {
      try {
        let networkList =
          (await TradeNetworkService.getAllContractorWithOutCategory(
            activeUserWorkstation.value?.id
          )) as any;
        networkList = networkList
          .map((networkMember: any) => {
            return {
              ...networkMember,
              isUserChat: false,
              receiverWorkstation: {
                ...networkMember.receiverWorkstation,
              },
            };
          })
          .filter((member: any) => {
            //invited users Launch workStation filter login user workStations
            if (activeUserWorkstation.value.isInvited) {
              return member.receiverWorkstation.user.id !== user.value.id;
            }
            return true;
          });

        // invite Users
        let filterUsers = [];
        if (inviteUsers.value.length) {
          filterUsers = inviteUsers.value
            .filter(
              (userData: any) =>
                user.value?.id !== userData?.toUserId &&
                userData.invitationStatus === "accepted"
            )
            .map((data: any) => {
              return {
                ...data,
                isUserChat: true,
                receiverWorkstation: {
                  id: data.userWorkstation.id,
                  name: data.userWorkstation.name,
                  isAvailable: data.userWorkstation.isAvailable,
                  profileImage: data.userWorkstation.profileImage,
                  imgUrl: null,
                  user: {
                    id: data.toUser?.id,
                    firstName: data.toUser?.firstName,
                    lastName: data.toUser?.lastName,
                    userBusinessDetails: data.toUser?.userBusinessDetails,
                    userPublicProfile: data.toUser?.userPublicProfile,
                    profileImage: data.toUser?.userPublicProfile?.profileImage,
                    imgUrl: null,
                  },
                },
              };
            });
        }

        usersNetworkList.value = [...networkList, ...filterUsers];

        //get Image
        for (const netWorkData of usersNetworkList.value) {
          if (netWorkData.receiverWorkstation?.profileImage)
            getUserWorkStationProfileImage(
              netWorkData.receiverWorkstation.profileImage
            );
          if (netWorkData.receiverWorkstation.user?.profileImage)
            getUserWorkStationProfileImage(
              netWorkData.receiverWorkstation.user?.profileImage
            );
        }
        return usersNetworkList.value;
      } catch (error) {
        console.log(error);
      }
    };
    const onClickProjectChat = async () => {
      try {
        await getUserWorkStationUserList();
      } catch (error) {
        console.log("error", error);
      }
    };
    const onClickGroupChat = async () => {
      try {
        const response = await getUserWorkStationUserList();
        if (response) {
          isCreateNewGroupChat.value = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const openMessageChatBox = async (message: any) => {
      try {
        if (isProjectChatLock(message)) return;

        let messageRoomId = message?.id;
        const isOpenMessageBox = chatRooms.value?.find(
          (msg: any) => msg?.userMessageRoomDetails?.id === messageRoomId
        );

        if (!isOpenMessageBox && messageRoomId) {
          const userMessageRoomDetails = {
            id: message?.id,
            isGroup: message?.isGroup,
            groupName: message?.groupName,
            groupAvatar: message?.groupAvatar,
            groupAvatarUrl: message?.groupAvatarUrl,
            parentId: message?.parentId,
            project: message?.project,
            projectStatus: message?.status,
            unReadMessageCount: message?.unreadMessagesCount,
            isUserChat: message?.isUserChat,
          };
          chatRooms.value.unshift({
            userMessageRoomDetails: userMessageRoomDetails,
            receiverUserWorkstation: {
              ...message?.toWorkstationId,
              user: message?.toUser,
              isUserChat: message?.isUserChat,
            },
            senderUserWorkstation: {
              id: activeUserWorkstation.value.id,
              name: activeUserWorkstation.value.name,
              profileImage: userWorkStationImage.value,
              user: {
                id: user.value.id,
                firstName: user.value.firstName,
                lastName: user.value.lastName,
              },
            },
            userMessages: [],
          });
        } else {
          const index = chatRooms.value.findIndex(
            (room: any) => room?.userMessageRoomDetails?.id === messageRoomId
          );
          if (index > 0) {
            chatRooms.value.splice(index, 1);
            chatRooms.value.unshift(isOpenMessageBox);
          }
        }

        const filterMessageCount = filteredMessages.value?.find(
          (room: any) => room?.id === messageRoomId
        );

        if (filterMessageCount) filterMessageCount.unreadMessagesCount = 0;
      } catch (error) {
        console.log(error);
      }

      if (window.innerWidth <= 720) {
        isOpenChatBox.value = !isOpenChatBox.value;
        isCreateNewGroupChat.value = false;
        isCreateChat.value = false;
      }
    };
    const getTimeElapsedString = (timestamp: any) => {
      const previous = new Date(timestamp) as any;
      const current = new Date() as any;

      const elapsed = current - previous;

      const seconds = Math.floor(elapsed / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      if (weeks > 0) {
        return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "Just now";
      }
    };

    const subscribeRoomUpdates = async () => {
      const roomChannel = pusher?.subscribe(
        `new-room-message-${activeUserWorkstation.value.id}`
      );
      roomChannel
        ?.unbind("new.room.message")
        ?.bind("new.room.message", async (userRoom: any) => {
          if (userRoom) {
            const roomExists = selectedUserMessages.value.some(
              (el: any) => el.id == userRoom.id
            );
            if (roomExists) {
              selectedUserMessages.value = [...selectedUserMessages.value].map(
                (el: any) => {
                  if (el.id == userRoom.id) {
                    return { ...el, userMessages: userRoom.userMessages };
                  } else {
                    return el;
                  }
                }
              );
            } else {
              if (userRoom.isGroup)
                getUserWorkStationProfileImage(userRoom?.groupAvatar);
              else {
                if (userRoom.toWorkstationId)
                  getUserWorkStationProfileImage(
                    userRoom?.toWorkstationId.profileImage
                  );
                if (userRoom.toUser)
                  getUserWorkStationProfileImage(userRoom?.toUser.profileImage);
              }

              selectedUserMessages.value.push(userRoom);
              store.commit(
                `${CHATMESSAGE}/setUserRoomMessages`,
                selectedUserMessages.value
              );
            }
          }
        });
      subscribeChannelList.value.push(roomChannel);
    };
    const init = async () => {
      if (activeUserWorkstation.value) {
        const userMessageRooms = await getUserRoomsList();
        userMessageRooms?.map((data: any) => {
          let roomId = data?.id;
          if (data?.isGroup && data?.parentId) {
            roomId = data?.parentId;
          }
          if (roomId) subscribeUserMessageRoom(roomId);
        });
        subscribeRoomUpdates();
      }
    };
    const isProjectOwner = computed(() => {
      if (user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER) {
        return true;
      }
      return false;
    });
    //
    //
    watch(
      () => isProjectOwner.value,
      async (newValue: any, oldValue: any) => {
        if (newValue !== oldValue && isProjectOwner.value) {
          await init();
          chatRooms.value = [];
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => activeUserWorkstation.value?.id,
      async (newValue: any, oldValue: any) => {
        if (newValue !== oldValue) {
          await init();

          await store.dispatch(`${CHATMESSAGE}/getKrystalConversation`);
          //start direct with group getNetworkContactList issue
          inviteUsers.value = await getTeamMember();
          await getUserWorkStationUserList();
          chatRooms.value = [];
        }
      },
      { immediate: true, deep: true }
    );

    const getProjectChatRequests = computed(
      () => store.getters[`${CHATMESSAGE}/getProjectChatRequests`]
    );

    watch(
      () => getProjectChatRequests.value,
      async (newValue: any, oldValue: any) => {
        if (
          newValue !== oldValue &&
          getProjectChatRequests.value &&
          getProjectChatRequests.value.length
        ) {
          await init();
          chatRooms.value = [];
        }
      },
      { immediate: true, deep: true }
    );
    const getNetworkDirectMessage = computed(
      () => store.getters[`${CHATMESSAGE}/getNetworkDirectMessage`]
    );
    const getCurrentMessageType = computed(
      () => store.getters[`${CHATMESSAGE}/getCurrentMessageType`]
    );
    watch(
      () => getNetworkDirectMessage.value,
      async (value: any) => {
        if (value) {
          await init();
          const activeMessageRoom = selectedUserMessages.value.find(
            (data: any) => data.id === value.id
          );

          isOpenChatBox.value = true;
          openMessageChatBox(activeMessageRoom);
        }
      },
      { immediate: true, deep: true }
    );
    const pusher = inject("pusher") as any;
    const subscribeChannelList = ref([]) as any;

    const addNewUserMessagesToRoom = (
      userMessageRoomId: any,
      userNewMessage: any
    ) => {
      const findUserMessages = chatRooms.value.find((data: any) => {
        return (
          data.userMessageRoomDetails.id === userMessageRoomId ||
          data.userMessageRoomDetails.parentId === userMessageRoomId
        );
      });

      if (findUserMessages) {
        findUserMessages?.userMessages.unshift(userNewMessage);
      }
    };
    const appendLatestMessages = (
      userMessageRoomId: number,
      userNewMessage: any
    ) => {
      const findUserMessageRoom = selectedUserMessages.value.find(
        (data: any) => data.id === userMessageRoomId
      );
      if (findUserMessageRoom) {
        const isOpenChats = chatRooms.value.find(
          (room: any) => room?.userMessageRoomDetails?.id === userMessageRoomId
        );
        if (!isOpenChats) {
          findUserMessageRoom.unreadMessagesCount++;
        }

        findUserMessageRoom.userMessages = userNewMessage;
      }
    };
    const subscribeUserMessageRoom = (roomId: { roomId: number }) => {
      const roomChannel = pusher?.subscribe(`new-messages-${roomId}`);
      roomChannel
        ?.unbind("new.message")
        ?.bind("new.message", (userNewMessage: any) => {
          let userMessageRoomId = userNewMessage?.userMessageRoom?.id;
          appendLatestMessages(userMessageRoomId, userNewMessage);

          if (userNewMessage?.userMessageRoom?.isUserChat && userNewMessage) {
            if (user.value.id !== userNewMessage.fromUser.id)
              addNewUserMessagesToRoom(userMessageRoomId, userNewMessage);
          } else {
            if (
              activeUserWorkstation.value.id !==
                userNewMessage.fromWorkstationId.id ||
              // activeUserWorkstation.value.user.id !== user.value.id
              user.value.id !== userNewMessage.fromUser.id
            )
              addNewUserMessagesToRoom(userMessageRoomId, userNewMessage);
          }
        });

      roomChannel
        ?.unbind("delete.message.room")
        ?.bind("delete.message.room", async (userRoom: any) => {
          //Todo :fixes for usersGroups
          if (
            userRoom.fromWorkstationId?.id !== activeUserWorkstation.value.id ||
            userRoom.fromUser?.id !== user.value.id
          )
            chatRooms.value = chatRooms.value.filter(
              (msgRoom: any) =>
                msgRoom?.userMessageRoomDetails?.id !== userRoom.id
            );
          selectedUserMessages.value = selectedUserMessages.value.filter(
            (room: any) => room.id !== userRoom.id
          );

          store.commit(
            `${CHATMESSAGE}/setUserRoomMessages`,
            selectedUserMessages.value
          );
        });

      roomChannel
        ?.unbind("project.chat.status")
        ?.bind("project.chat.status", async (roomData: any) => {
          if (roomData) {
            if (roomData.isRemove)
              selectedUserMessages.value = selectedUserMessages.value.filter(
                (msgRoom: any) => msgRoom?.id !== roomData.roomId
              );
            await init();
          }
        });
      const attachmentChannel = pusher?.subscribe(
        `new-messages-attachment-${roomId}`
      );
      attachmentChannel
        ?.unbind("new.message-attachment")
        ?.bind("new.message-attachment", (msgAttachments: any) => {
          const userNewAttachmentMessage = {
            id: msgAttachments?.id,
            content: msgAttachments?.originalName,
            dateCreated: msgAttachments?.dateCreated,
          };
          appendLatestMessages(
            msgAttachments?.userMessageRoom?.id,
            userNewAttachmentMessage
          );
          if (
            msgAttachments &&
            activeUserWorkstation.value.id !==
              msgAttachments.createdByUserWorkStation
          )
            appendNewAttachmentToRoom(msgAttachments);
        });
      subscribeChannelList.value.push(roomChannel);
      subscribeChannelList.value.push(attachmentChannel);
    };

    const unbindSubscribedMessagesChannels = () => {
      if (subscribeChannelList.value.length) {
        subscribeChannelList.value.forEach((channel: any) => {
          // Unbind all events from channel
          channel.unbind();
          pusher?.unsubscribe(channel.name);
        });
        subscribeChannelList.value.length = [];
      }
    };

    const appendNewAttachmentToRoom = (attachmentsMessage: any) => {
      const findUserMessageRoom = chatRooms.value.find(
        (room: any) =>
          room.userMessageRoomDetails.id ===
          attachmentsMessage?.userMessageRoom.id
      );

      if (findUserMessageRoom) {
        const findMessage = findUserMessageRoom.userMessages.find(
          (msg: any) => msg.id === attachmentsMessage?.userMessageId?.id
        );
        if (findMessage) {
          findMessage.userMessageRoom.userMessageAttachments = [];
          findMessage.userMessageRoom.userMessageAttachments.push(
            attachmentsMessage
          );
        }
      }
    };

    const deleteChat = async (message: any) => {
      const id = message?.id;
      try {
        selectedUserMessages.value = [...selectedUserMessages.value].filter(
          (el: any) => el.id !== id
        );
        store.commit(
          `${CHATMESSAGE}/setUserRoomMessages`,
          selectedUserMessages.value
        );

        chatRooms.value = [...chatRooms.value].filter(
          (el: any) => el.userMessageRoomDetails.id !== id
        );
        if (message.isGroup && !message.parentId)
          await chatService.deleteUserMessages(id);
        else await chatService.deleteUserMessages(id);
      } catch (error) {
        console.log(error);
      }
    };

    const showDeleteDropdown = ref(
      Array(filteredMessages.value.length).fill(false)
    );

    //To show delet dropdown as per ID
    const dropdownAction = (idx: any) => {
      showDeleteDropdown.value.forEach((value, index) => {
        if (index !== idx) {
          showDeleteDropdown.value[index] = false;
        }
      });

      // Toggle the dropdown at idx
      showDeleteDropdown.value[idx] = !showDeleteDropdown.value[idx];
    };

    //To handle the back button on group chat layers
    const handleBackButton = () => {
      if (!isCreateNewGroupChat.value) {
        isCreateChat.value = !isCreateChat.value;
      }

      if (isCreateNewGroupChat.value) {
        isCreateNewGroupChat.value = !isCreateNewGroupChat.value;
      }
    };

    const handleMouseLeave = (idx: any) => {
      showDeleteDropdown.value[idx] = false;
    };

    onBeforeUnmount(() => {
      unbindSubscribedMessagesChannels();
    });

    const getUserWorkStationProfileImage = async (profileImage: any) => {
      if (!profileImage) return null;
      //check first in common map img exits to directly store to chat map
      if (profileImage in profileImagesData) {
        const imgUrl = profileImagesData[profileImage];
        addUserImage(profileImage, imgUrl);
        return;
      }

      //check profileImage in chat map
      if (!keyExists(profileImage)) {
        const imageUrl = getImageApiUrl(profileImage, true);
        try {
          const encoded: any = await $axios.get(imageUrl);
          addUserImage(profileImage, encoded.publicUrl);
          return encoded.publicUrl;
        } catch (error) {
          return null;
        }
      }
    };
    const getWorkStationImagePublicUrl = (profileImage: string | null) => {
      if (!profileImage) return require("@/assets/images/user-placeholder.png");

      const imgUrl = userImagesMap.value[profileImage];
      if (!imgUrl) return require("@/assets/images/user-placeholder.png");

      return imgUrl;
    };

    const filteredUsersNetworkList = computed(() => {
      const searchTerm = searchNetworkUsers?.value?.trim()?.toLowerCase();
      if (searchTerm && searchTerm !== "") {
        //search both with userWorkstation and user
        return usersNetworkList.value.filter(
          (user: any) =>
            user?.receiverWorkstation?.name
              ?.toLowerCase()
              .includes(searchTerm) ||
            user?.receiverWorkstation?.user?.firstName
              .toLowerCase()
              .includes(searchTerm) ||
            user?.receiverWorkstation?.user?.lastName
              .toLowerCase()
              .includes(searchTerm)
        );
      }
      return usersNetworkList.value;
    });

    const isProjectChatLock = (data: any) => {
      return data?.project &&
        data?.project?.id &&
        data.status === UserMessagesRoomStatusEnum.PENDING
        ? true
        : false;
    };
    const userOnlineWorkStation = computed(() => {
      const onlineWorkStation =
        store.getters[`${WORKSTATION}/getUserOnlineWorkStations`];
      const workStations = onlineWorkStation.map((data: any) => {
        return [...data.userWorkstations];
      });
      return workStations.flat();
    });
    const isUserOnline = (wsId: number) => {
      const isExist = userOnlineWorkStation.value?.find(
        (data: any) => wsId === data.id && data.status === "active"
      );
      return isExist ? true : false;
    };
    const totalUnreadMessageCount = computed(() => {
      // if (!isOpenChatBox.value) {
      let countMsg = 0;
      filteredMessages.value.forEach((msg: any) => {
        if (msg?.unreadMessagesCount) {
          countMsg++;
        }
      });
      return countMsg;
      // }
    });
    const onClickVideoCallButton = (
      msgId: number,
      senderUserWorkstationId: number,
      receiverUserWorkstationId: number,
      roomId: number
    ) => {
      closeMessageChatBox(msgId, true);
      if (roomId) {
        roomDetails.value[
          "title"
        ] = `group-call-${senderUserWorkstationId}-${roomId}`;
        roomDetails.value[`type`] = CallTypesEnums.GROUP_CALL;
      } else {
        roomDetails.value[
          "title"
        ] = `video-call-${senderUserWorkstationId}-${receiverUserWorkstationId}`;
        roomDetails.value[`type`] = CallTypesEnums.VIDEO_CALL;
      }
      videoConsultationStatus.value = true;
      videoComponent.value.onClickVideoCallButton();
    };

    const leaveCall = () => {
      videoConsultationStatus.value = false;
    };

    const isKrystalChatActive = ref(false);
    const openKrystalChat = () => {
      isKrystalChatActive.value = true;
    };
    const closeKrystalChat = () => {
      isKrystalChatActive.value = false;
    };

    const getKrystalConversationLastAnswer: any = computed(
      () => store.getters[`${CHATMESSAGE}/getKrystalConversationLastAnswer`]
    );
    const usersList = computed(() => {
      return filteredUsersNetworkList.value.filter(
        (member: any) => member.isUserChat
      );
    });
    const workStationList = computed(() => {
      return filteredUsersNetworkList.value.filter(
        (member: any) => !member.isUserChat
      );
    });

    return {
      isProjectOwner,
      user,
      chatRooms,
      isActive,
      isCreateChat,
      searchChats,
      isOpenChatBox,
      isCreateNewGroupChat,
      onCreateNewChat,
      toggleChatBox,
      createMessageChatBox,
      closeMessageChatBox,
      filterChatRoom,
      usersNetworkList,
      onClickProjectChat,
      openMessageChatBox,
      getTimeElapsedString,
      getUserRoomsList,
      onClickGroupChat,
      saveNewUserGroup,
      selectedUserMessages,
      projectId,
      getCurrentMessageType,
      deleteChat,
      filteredMessages,
      activeUserWorkstation,
      getUserWorkStationProfileImage,
      userWorkStationImage,
      showDeleteDropdown,
      dropdownAction,
      handleBackButton,
      handleMouseLeave,
      userImagesMap,
      saveNewGroupMembers,
      searchNetworkUsers,
      filteredUsersNetworkList,
      isProjectChatLock,
      isUserOnline,
      totalUnreadMessageCount,
      videoConsultationStatus,
      roomDetails,
      onClickVideoCallButton,
      leaveCall,
      videoComponent,
      isLoading,
      openKrystalChat,
      isKrystalChatActive,
      closeKrystalChat,
      getKrystalConversationLastAnswer,
      moment,
      userImage,
      getWorkStationImagePublicUrl,
      tab,
      usersList,
      workStationList,
      // isAppIknowa,
      tabs,
    };
  },
};
</script>

<style lang="scss" scoped>
.mainchatbox__wrapper {
  position: relative;
  display: flex;
  // position: fixed;
  // right: 330px;
  // bottom: 0;
  // width: 320px;
  max-width: 100%;
  z-index: 999;
  @include respond(s720) {
    width: 100%;
    right: 0;
  }
  .open__chat {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    flex-direction: row-reverse;
    position: fixed;
    right: 428px;
    bottom: 11px;
    @media (max-width: 1279px) {
      right: 327px;
      bottom: 72px;
    }

    @include respond(s720) {
      display: block;
      width: 100%;
      right: 0;
      left: 0;
    }
  }

  .chatbox__close {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(30%, -40%);
    z-index: 99;
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px #00000014;

    @include respond(s720) {
      display: none;
    }
  }
  .messagechat__wrapper {
    @media screen and (max-width: 1279px) {
      width: 300px;
    }

    @include respond(s720) {
      width: 100%;
    }
  }

  .chatbox__wrapper {
    position: fixed;
    right: 12px;
    bottom: 12px;
    width: 400px;
    height: 56px;
    max-width: 100%;
    transition: 0.5s all ease;

    @media screen and (max-width: 1279px) {
      width: 300px;
      bottom: 72px;
    }

    @include respond(s720) {
      width: 100%;
      right: 0;
    }

    .chatbox__inner {
      border-radius: 8px;
      padding: 0;
      box-shadow: 2px 2px 8px 0px #00000052;
      height: 100%;

      // @media screen and (max-width: 1279px) {
      //   // bottom: 72px;
      //   padding: 12px 24px 0;
      // }
      cursor: pointer;
      :deep .v-card-text {
        padding: 0;
        .chatbox__top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 20px;
          background-color: #f8f8f8;
          // border-bottom: 1px solid rgba($blueDark, 0.18);

          .chatbox__left {
            display: flex;
            gap: 8px;
            align-items: center;
            position: relative;
            .chatbox__img {
              width: 24px;
              height: 24px;
              position: relative;
              border-radius: 100%;
              overflow: hidden;
              .online {
                width: 12px;
                height: 12px;
                position: absolute;
                right: 6px;
                top: 0px;
                border-radius: 100%;
                background-color: #3bc740;
                border: 1px solid rgba($white, 1);
              }
              .offline {
                width: 12px;
                height: 12px;
                position: absolute;
                right: 6px;
                top: 0px;
                border-radius: 100%;
                background-color: rgba($errorRed, 1);
                border: 1px solid rgba($white, 1);
              }

              img {
                width: 100%;
                max-width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .msg__notification {
              width: 8px;
              height: 8px;
              background-color: #1fb27a;
              border: 1px solid #fff;
              border-radius: 100%;
              position: absolute;
              left: 15px;
              top: 0;
            }

            .chatbox__heading {
              display: flex;
              gap: 8px;
              align-items: flex-start;
              h5 {
                @include fluidFont(20, 18, 1);
                font-weight: 700;
                letter-spacing: 0.25px;
                color: rgba($blueDark, 1);
                margin: 0;
              }
              span {
                font-size: 12px;
                background-color: #264fd5;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .chatbox__right {
            font-size: 22px;
          }
        }
        .back__section {
          .chatbox__left {
            .msg__notification {
              left: 45px;
            }
          }
        }
        .chatbox__section {
          height: 0;
          transition: 0.5s all ease;

          .chatbox__selection_inner {
            padding: 12px 16px;
            height: 602px;
            transition: 0.5s all ease;
            box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);

            @include respond(s720) {
              height: 400px;
            }
          }
          .chatbox__message {
            // height: calc(100% - 30px);
            // overflow: hidden;
            // padding: 12px 16px;
            .chatbox__center {
              padding: 0 0 12px;
              display: flex;
              gap: 16px;
              align-items: center;
              margin-bottom: 16px;
              border-bottom: 1px solid rgba($blueDark, 0.18);
              .chatbox__search {
                .v-input__control {
                  .v-field {
                    border-radius: 9px;
                    .v-field__input {
                      padding: 0;
                      min-height: unset;
                      @include fluidFont(12, 12, 1);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                    }
                    .v-field__field {
                      label {
                        @include fluidFont(12, 12, 1);
                        color: rgba(142, 142, 147, 1);
                        margin-left: 0;
                      }
                    }
                  }
                  .v-field--prepended {
                    padding: 0;
                    border-color: rgba(0, 0, 0, 0.04);
                    display: flex;
                    align-items: center;
                    height: 38px;
                    padding: 8px 16px;
                    gap: 8px;
                    border-radius: 9px;
                  }
                }
              }
              .chatbox__edit {
                width: 40px;
                height: 40px;
                background: #ffa500;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 19px;
                  height: 19px;
                  filter: invert(100%) sepia(12%) saturate(7500%)
                    hue-rotate(214deg) brightness(113%) contrast(112%);
                }
              }
            }
            .empty__chats {
              // display: flex;
              // height: calc(100% - 80px);
              // margin: auto;
              // align-items: center;
              // justify-content: center;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              @include respond(s720) {
                top: 66%;
              }

              .empty__chats__message {
                .v-icon {
                  img {
                    opacity: 0.5;
                  }
                }
                p {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 400;
                  color: rgba($blueDark, 0.5);
                  margin-top: 8px;
                }
              }
            }
            .chatbox__list {
              height: 400px;
              overflow: auto;
              margin-top: 12px;

              @include respond(s720) {
                height: 220px;
              }

              &.without-krystal {
                height: 500px;

                @include respond(s720) {
                  height: 300px;
                }
              }

              .chatbox__bottom {
                .chatbox__bottom__chats {
                  padding: 12px 0;
                  border-top: 0.66px dashed #8687a5;
                  .chatbox__bottom__chats__list {
                    display: flex;
                    gap: 8px;
                    align-items: flex-start;
                    padding-top: 4px;

                    &.is-locked {
                      align-items: center;
                    }

                    .chatbox__left {
                      width: 36px;
                      max-width: 100%;
                      position: relative;
                      .chatbox__img {
                        width: 36px;
                        height: 36px;
                        border-radius: 100%;
                        overflow: hidden;
                        .online {
                          width: 10px;
                          height: 10px;
                          position: absolute;
                          right: 0;
                          top: -3px;
                          border-radius: 100%;
                          background-color: #3bc740;
                          border: 1px solid rgba($white, 1);
                        }
                        .offline {
                          width: 10px;
                          height: 10px;
                          position: absolute;
                          right: 0;
                          top: -3px;
                          border-radius: 100%;
                          background-color: rgba($errorRed, 1);
                          border: 1px solid rgba($white, 1);
                        }

                        img {
                          width: 100%;
                          max-width: 100%;
                          height: 100%;
                          object-fit: cover;
                        }
                      }
                    }
                    .chatbox__center {
                      padding: 0;
                      display: block;
                      text-align: left;
                      border: none;
                      margin-bottom: 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      .chatbox__heading {
                        h6 {
                          @include fluidFont(16, 16, 20px);
                          letter-spacing: 0.25px;
                          font-weight: 400;
                          color: $blueDark;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        }

                        .chatbox__title {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          h6 {
                            @include fluidFont(15, 12, 1);
                            font-weight: 700;
                            letter-spacing: 0.25px;
                            color: $blueDark;
                            max-width: 180px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                          }
                          .v-icon {
                            @include fluidFont(13, 12, 1);
                          }
                        }
                      }
                    }
                    .chatbox__right {
                      margin: 0 0 0 auto;
                      display: flex;
                      align-items: center;
                      flex-shrink: 0;
                      span {
                        @include fluidFont(14, 12, 1);
                        font-weight: 400;
                        letter-spacing: 0.25px;
                        color: rgba($blueDark, 0.54);
                      }
                      .option__selector {
                        position: relative;
                        display: none;
                        margin-left: 8px;

                        .v-icon {
                          img {
                            opacity: 0.5;
                          }
                        }
                        .delete__option {
                          display: flex;
                          align-items: center;
                          gap: 8px;
                          box-shadow: 0px 0px 8px 0px rgba($blueDark, 0.08);
                          padding: 12px;
                          border-radius: 6px;
                          position: absolute;
                          top: 30px;
                          right: 0;
                          background-color: rgba($white, 1);

                          span {
                            @include fluidFont(14, 14, 1);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                          }
                        }
                      }
                    }
                    // .message_delete {
                    //   v-icon {
                    //     @include fluidFont(16, 16, 1);
                    //     color: rgba($blueDark, 1);
                    //   }
                    // }
                  }
                }

                .chatbox__bottom__chats__text {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  justify-content: space-between;
                  max-width: calc(100% - 45px);
                  margin-left: 43px;
                  margin-top: -10px;

                  p {
                    text-align: left;
                    @include fluidFont(14, 14, 20px);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    max-width: 100%;
                    width: calc(100% - 40px);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                  }
                  .chatbox__notification {
                    font-size: 12px;
                    background-color: rgba($PrimaryBlue, 1);
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    //display: none;
                  }
                }
              }
            }
          }

          /* create message */

          .chatbox__edit__message {
            .chatbox__create__message {
              padding: 12px 0;
              border-bottom: 1px solid rgba(0, 0, 0, 0.18);
              .v-field__input {
                min-height: 44px;
              }
            }
            .chatbox__top {
              padding: 10px;
              background-color: #ffa500;
              border-radius: 6px;

              @include respond(s720) {
                padding: 6px 10px;
              }

              .chatbox__img {
                width: 30px;
                height: 30px;
                position: relative;
                border-radius: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                color: #ffa500;
              }

              .chatbox__heading h5 {
                font-size: 14px !important;
                line-height: 25px;
                color: #fff;
              }

              .chatbox__right {
                transform: rotate(0);
                color: #fff;
              }
            }
            .chat_groups_list {
              margin-top: 16px;

              .chat_groups_list_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                position: relative;
                .create_group_wrapper {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  position: relative;
                  width: 100%;
                  max-width: 100%;
                  .create_group_image {
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  span {
                    width: 8px;
                    height: 8px;
                    background-color: #3bc740;
                    border-radius: 100%;
                    border: 1px solid rgba($white, 1);
                    display: inline-block;
                    position: absolute;
                    left: 28px;
                    top: 0;
                  }
                  .create_group_name {
                    @include fluidFont(16, 16, 20px);
                    font-weight: 400;
                    letter-spacing: 0.25px;
                    color: rgba($blueDark, 1);
                    text-align: left;
                  }
                }
                .group_status {
                  width: 24px;
                  height: 24px;
                  border-radius: 100%;
                  background-color: #f3f3f6;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: background-color 0.3s ease;

                  .mdi-chevron-right {
                    color: rgba($blueDark, 1);
                  }
                  .locked {
                    .locked_status {
                      background-color: rgba($blueDark, 1);
                      padding: 5px 10px;
                      border-radius: 4px;
                      width: auto;
                      position: absolute;
                      right: 0;
                      top: -10px;
                      display: none;
                      p {
                        color: rgba($white, 1);
                        @include fluidFont(10, 10, 1.2);
                        white-space: nowrap;
                      }
                    }
                  }
                  :hover {
                    .locked_status {
                      display: block;
                    }
                  }
                }
              }
              .chat_groups_list_empty {
                height: 430px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                @media screen and (max-width: 1279px) {
                  height: 100%;
                  margin-top: 40px;
                }
              }
            }
          }
        }
      }
    }
  }

  :deep(.v-slide-group) {
    border-bottom: 1px solid #0000001a;
    height: auto;

    .v-slide-group__content {
      flex: auto;
    }

    .v-tab.v-tab.v-btn {
      min-width: 1px;
      height: auto;
      min-height: 1px;
      padding: 16px 24px;
      text-transform: capitalize;
      font-weight: 600;
      @include fluidFont(16, 14, 20px);
      letter-spacing: -0.41px;
      color: rgba(12, 15, 74, 0.6);
      margin: 0 !important;

      @include respond(s720) {
        padding: 10px 12px;
      }

      &.v-slide-group-item--active {
        color: rgba(12, 15, 74, 1);
      }

      .v-tab__slider {
        background: #4f55f0;
      }
    }
  }

  .chat_groups_list_tab_content {
    margin-top: 20px;
    height: 365px;
    overflow: auto;

    @include respond(s720) {
      margin-top: 0;
      height: 195px;
    }
  }

  .chatbox_active {
    height: 660px;
    width: 400px;

    @media screen and (max-width: 1279px) {
      width: 300px;
    }

    @include respond(s720) {
      height: 450px;
      width: 100%;
    }
  }

  .chatbox_active .chatbox__top .chatbox__right {
    transform: rotate(180deg);
  }
  .chatbox__inner.chatbox_active .chatbox__section {
    height: calc(100dvh - 350px) !important;
    transition: 0.5s all ease;
    overflow-y: auto;
  }

  .chatbox__inner.chatbox_active {
    .chatbox__top {
      .chatbox__right {
        transform: rotate(180deg);
        .v-icon {
          color: rgba($blueDark, 0.2);
        }
      }
    }
  }
  .chatbox__inner.chatbox_active {
    .chatbox__edit__message {
      height: 100%;
      .chatbox__right {
        transform: none;
        .v-icon {
          color: rgba($blueDark, 0.2);
        }
      }
    }
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(197, 210, 242, 0.5);
    left: 0;
    top: 0;
    z-index: -1;

    &::before {
      content: "";
      position: fixed;
      width: 100%;
      height: calc(100% - 72px);
      background-color: rgba(197, 210, 242, 0.5);
      left: 0;
      top: 0;
      display: none;
    }

    @media (max-width: 1180px) {
      background-color: transparent;

      &::before {
        display: block;
      }
    }
  }
  .chatbox__bottom__chats:hover
    .chatbox__bottom__chats__list
    .chatbox__right
    .option__selector {
    display: block !important;
  }
}
.create_group__search {
  border-bottom: 1px solid rgba($blueDark, 0.18);
  padding: 0 0 16px;
  margin-bottom: 16px;
  :deep(.v-input__control) {
    .v-field {
      border-radius: 9px;
      .v-field__input {
        padding: 0;
        min-height: unset;
        @include fluidFont(12, 12, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
      ::placeholder {
        @include fluidFont(12, 12, 1);
        font-weight: 400;
        color: rgba($blueDark, 0.5);
      }
      .v-field__field {
        label {
          @include fluidFont(12, 12, 1);
          color: rgba(142, 142, 147, 1);
          margin-left: 0;
        }
      }
    }
    .v-field--prepended {
      padding: 0;
      border-color: rgba(0, 0, 0, 0.04);
      display: flex;
      align-items: center;
      height: 38px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 9px;
    }
  }
}
.chatbox__wrapper.chatbox_active .empty__chats__message {
  display: block;
}
.chatbox__wrapper .empty__chats__message {
  display: none;
}
.krystal_chat {
  background-color: rgba(247, 221, 247, 0.41);
  height: auto !important;
  padding: 20px;
  border-radius: 8px;

  @media screen and (max-width: 1279px) {
    padding: 12px;
  }

  .chatbox__bottom {
    .chatbox__bottom__chats {
      padding: 0 !important;
      border: none !important;
    }

    .chatbox__left {
      width: 36px !important;
    }

    .chatbox__left .chatbox__img {
      width: 52px !important;
      height: 52px !important;
      margin: -9px 0 0 -8px;
    }

    .chatbox__heading .chatbox__title .v-icon {
      line-height: 0 !important;
      font-size: 19px !important;
    }

    .chatbox__bottom__chats__list {
      align-items: flex-start !important;
    }

    .chatbox__heading .chatbox__title h6 {
      font-size: 16px !important;
    }

    .chatbox__bottom__chats__text {
      margin-top: -16px !important;
      margin-left: 44px !important;
      max-width: calc(100% - 42px) !important;

      p {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
}
.chatbox__wrapper .loader__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
