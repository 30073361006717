<template>
  <CollapsibleWidgetSlot title="Recommendation">
    <template #content>
      <section class="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border">
        <template v-if="recommendations && recommendations.length">
          <div
            class="recommendation__card"
            v-for="(recommendation, index) in recommendations"
            :key="index"
          >
            <div class="recommendation__card__header">
              <div class="property__text__box">
                <v-icon icon="mdi-home"></v-icon>PROPERTY
                <img src="@/assets/icons/info-icon-transparent.svg" />
              </div>
              <div class="priority__text__box">
                <v-icon icon="mdi-information"></v-icon>High Priority
              </div>
            </div>
            <div class="recommendation__card__main">
              <div class="recommendation__card__main__title">
                {{ recommendation?.improvement_descr_text }}
              </div>

              <div class="recommendation__card__main__subtitle">
                {{ recommendation?.improvement_summary_text }}
              </div>
            </div>
            <div class="recommendation__card__footer">
              <!-- <div class="chat__box">
                <img src="@/assets/icons/sidebar-icons/chat.svg" />
              </div> -->
              <v-btn
                variant="text"
                @click="createProject(recommendation, index)"
                :loading="loading && activeIndex === index"
                >Post This Project <v-icon icon="mdi-chevron-right"
              /></v-btn>
            </div>
          </div>
        </template>
        <div
          v-else
          class="tw-w-full tw-h-[150px] tw-flex tw-items-center tw-justify-center"
        >
          <Text variant="p">No Recommendations found</Text>
        </div>
      </section>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { PROJECT_STORE } from "@/store/modules/project";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";

import { useRouter } from "vue-router";
import { PROJECT_DETAILS_ROUTE } from "@/modules/project/routes";

const router = useRouter();
const store = useStore();

const recommendations = ref([]);
const loading = ref(false);
const activeIndex = ref(null);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const getRecommendations = () => {
  const details = propertyDetails.value;

  const data = details?.localData;
  if (!data) return;

  if (data.epcDomestic) {
    recommendations.value = data.epcDomestic.recommendations;
  } else if (data.epcDisplay) {
    recommendations.value = data.epcDisplay.recommendations;
  } else if (data.epcNonDomestic) {
    recommendations.value = data.epcNonDomestic.recommendations;
  }
};

onMounted(() => {
  const details = propertyDetails.value;

  if (details) {
    getRecommendations();
  }
});

const createProject = async (recommendation, index) => {
  try {
    loading.value = true;
    activeIndex.value = index;
    const propertiesData = [
      {
        propertyId: propertyDetails.value?.localData.id,
      },
    ];
    const createProjectResponce = await store.dispatch(
      `${PROJECT_STORE}/createProject`,
      {
        propertiesData: propertiesData,
        projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
        type: "standard",
        subType: "flexible",
        description: recommendation.improvement_summary_text,
        name: recommendation.improvement_descr_text,
        attachments: [],
        siteVisitStatus: false,
        isAddSpecialismFromGemini: false,
        projectFrom: "recommendation",
      }
    );

    router.push({
      name: PROJECT_DETAILS_ROUTE,
      params: { refId: createProjectResponce.refId },
    });
  } catch (error) {
    console.log("error while creating project", error);
  } finally {
    loading.value = false;
  }
};
</script>
<style lang="scss" scoped>
.recommendation__card {
  background-color: rgba($cardBorder, 1);
  padding: 10px;
  border-radius: 4px;
  .recommendation__card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 0 0 9px 0;
    .property__text__box {
      background-color: rgba($orange, 1);
      color: rgba($white, 1);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: inline-flex;
      align-items: center;
      @include fluidFont(12, 12, 1);
      .v-icon {
        color: rgba($white, 1);
        @include fluidFont(14, 14, 1);
      }
      img {
        width: 15px;
        height: 15px;
        filter: invert(100%) sepia(0%) saturate(7439%) hue-rotate(30deg)
          brightness(106%) contrast(114%);
      }
    }
    .priority__text__box {
      background-color: rgba($errorRed, 1);
      color: rgba($white, 1);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: inline-flex;
      align-items: center;
      @include fluidFont(12, 12, 1);
      .v-icon {
        color: rgba($white, 1);
        @include fluidFont(14, 14, 1);
      }
    }
  }
  .recommendation__card__main {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 0 0 9px 0;
    margin-top: 5px;
    .recommendation__card__main__title {
      @include fluidFont(12, 12, 2);
      font-weight: $fontSemiBold;
      letter-spacing: 0.25px;
      color: rgba($blueDark, 1);
      text-align: left;
      line-height: $line24;
    }
    .recommendation__card__main__subtitle {
      @include fluidFont(12, 12, 2);
      font-weight: $fontMedium;
      letter-spacing: 0.25px;
      color: rgba($blueDark, 1);
      text-align: left;
      line-height: $line16;
    }
  }
  .recommendation__card__footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .chat__box {
      background-color: rgba($buttonText, 0.5);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: flex;
      width: 30px;
      img {
        @include fluidFont(14, 14, 1);
      }
    }

    .v-btn {
      color: #ffa500;
      margin-left: auto;
      display: flex;
      padding: 0;
      opacity: 1;
      font-weight: 600;
      @include fluidFont(12, 12, 20px);
      height: auto;
      min-height: 1px;
      text-transform: none;
      letter-spacing: 0.13px;

      :deep(.v-btn__overlay),
      :deep(.v-btn__underlay) {
        display: none !important;
      }
    }
  }
}
</style>
